import React, {Fragment, useState, useEffect} from 'react';
import {UploaderFile} from "../interfaces";

function MobileImageActionsModal({showModal,files, setMobileImageActionsActiveIndex,imageIndex}: any) {
    const [allowClose, setAllowClose] = useState(false);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [itemImage, setItemImage] = useState('');
    const [imageListIndex, setImageListIndex] = useState(0);

    useEffect(() => {
        let {fileS3Link, imagePreviewUrl} = files.find((file: UploaderFile) => file.index === imageIndex);
        setImageListIndex(files.findIndex((file: UploaderFile) => file.index === imageIndex) + 1)
        if (fileS3Link && !imageUploaded) setImageUploaded(true);
        if (imagePreviewUrl) setItemImage(imagePreviewUrl);
    }, [files, imageIndex, imageUploaded]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAllowClose(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const actionsClickListener = (type: String) => {
        const element = document.querySelector(`[data-image_index="${imageIndex}"]`)
        if (!element) return;
       switch (type) {
           case 'view':
               // @ts-ignore
               element.querySelector('.view-image-modal-button').click();
               break;
           case 'openS3':
               // @ts-ignore
               element.querySelector('.s3-image-button').click();
               break;
           case 'lens':
               // @ts-ignore
               element.querySelector('.google-lens-button').click();
               break;
           case 'remove':
               // @ts-ignore
               element.querySelector('.remove-image-button').click();
               break;
       }
        document.body.classList.remove('no-scroll');
        showModal(false)
    }

    const handleClose = (ignoreCloseTimeout = false) => {
        if(ignoreCloseTimeout || allowClose) {
            showModal(false);
            document.body.classList.remove('no-scroll');
        }
    }

    const goToImage = (prev = false) => {
        let newImageIndex = imageListIndex - 1;
        if (prev) newImageIndex--
        else newImageIndex++
        if (newImageIndex < 0 || newImageIndex > files.length - 1) return
        setMobileImageActionsActiveIndex(newImageIndex)
    }

    return (
        <Fragment>
        <div className='mobile-image-actions-modal-overlay' onTouchStart={() => handleClose(false)} onClick={() => handleClose(false)}>
        </div>
            <div className="mobile-image-actions-modal">
                <div className="mobile-image-actions-modal-header">
                    <div onClick={() => goToImage(true)} className={imageListIndex <= 1 ? 'mobile-image-actions-image-left disabled' : 'mobile-image-actions-image-left'}></div>
                    <div className='mobile-image-actions-image' style={{backgroundImage: `url(${itemImage})`}}/>
                    <div onClick={() => goToImage(false)} className={imageListIndex === files.length ? 'mobile-image-actions-image-right disabled' : 'mobile-image-actions-image-right'}></div>
                    <div className='mobile-image-actions-image-counter'>{imageListIndex} of {files.length}</div>
                </div>
                <div className="mobile-image-actions-modal-actions">
                    <div onClick={() => actionsClickListener('view')}
                         className="mobile-image-actions-modal-actions-item">
                        <div className="mobile-image-action-icon-edit"></div>
                        Edit Image
                    </div>
                    <div onClick={() => actionsClickListener('openS3')}
                         className={imageUploaded ? "mobile-image-actions-modal-actions-item" : "mobile-image-actions-modal-actions-item disabled"}>
                        <div className="mobile-image-action-icon-view"></div>
                        View image
                    </div>
                    <div onClick={() => actionsClickListener('lens')}
                         className={imageUploaded ? "mobile-image-actions-modal-actions-item" : "mobile-image-actions-modal-actions-item disabled"}>
                        <div className="mobile-image-action-icon-search"></div>
                       Google Lens
                    </div>
                    <div onClick={() => actionsClickListener('remove')}
                         className="mobile-image-actions-modal-actions-item">
                        <div className="mobile-image-action-icon-remove"></div>

                        Delete Image
                    </div>
                    <div onClick={() => handleClose(true)}
                         className='mobile-image-actions-modal-actions-item'>
                        Cancel
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default MobileImageActionsModal;