import React, { useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import styles from './ColorPicker.module.scss';

interface IColorPickerProps {
	defaultColor: string;
	handleChangeColorPickerColor: (color: string) => void;
	isMobile?:any;
}

const ColorPicker = (props: IColorPickerProps) => {
	const { defaultColor, handleChangeColorPickerColor, isMobile } = props;
	const colorPickerRef = useRef<HTMLDivElement | null>(null);

	const [isVisible, setIsVisible] = useState(false);
	const [selectedColor, setSelectedColor] = useState(defaultColor || '#000');

	const toggleColorPicker = () => {
		setIsVisible(!isVisible);
	};

	const handleColorChange = (color: string) => {
		setSelectedColor(color);
		handleChangeColorPickerColor(color);
	};

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
			 colorPickerRef.current &&
			 !colorPickerRef.current.contains(event.target as Node)
			) {
				setIsVisible(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [colorPickerRef]);

	return (
	 <div className={styles.colorPicker}>
		 <div>
			 <div className={styles.colorPickerTitle}>
				 <div
				  onClick={toggleColorPicker}
				  style={{
					  width: '30px',
					  height: '30px',
					  backgroundColor: selectedColor,
					  cursor: 'pointer',
					  borderRadius: '50%',
				  }}
				 ></div>
			 </div>

			 {isVisible && (
			  <div ref={colorPickerRef} style={isMobile ? {width: '200px', margin: 'auto', position:"absolute", left:0, right:'-50px', top:'20px'} : { position: 'absolute', zIndex: 2}}>
				  <HexColorPicker
				   color={selectedColor}
				   onChange={handleColorChange}
				  />
			  </div>
			 )}
		 </div>
	 </div>
	);
};

export default ColorPicker;