import React, {useCallback, useState} from 'react';
import {PieChart, Pie, Cell, Sector, Legend} from 'recharts';
import accounting from 'accounting';

const renderActiveShape = (props: any) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
	 <g>
		 <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
			 {payload.name}
		 </text>
		 <Sector
		  style={{cursor: 'pointer'}}
		  cx={cx}
		  cy={cy}
		  innerRadius={innerRadius}
		  outerRadius={outerRadius}
		  startAngle={startAngle}
		  endAngle={endAngle}
		  fill={fill}
		 />
		 <Sector
		  cx={cx}
		  cy={cy}
		  startAngle={startAngle}
		  endAngle={endAngle}
		  innerRadius={outerRadius + 6}
		  outerRadius={outerRadius + 10}
		  fill={fill}
		 />
		 <path
		  d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
		  stroke={fill}
		  fill="none"
		 />
		 <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
		 <text
		  x={ex + (cos >= 0 ? 1 : -1) * 12}
		  y={ey}
		  textAnchor={textAnchor}
		  fill="#333"
		 >
			 {accounting.formatMoney(value, '$', 2)}
		 </text>
		 <text
		  x={ex + (cos >= 0 ? 1 : -1) * 12}
		  y={ey}
		  dy={18}
		  textAnchor={textAnchor}
		  fill="#999"
		 >
			 {`(${(percent * 100).toFixed(2)}%) of sales`}
		 </text>
	 </g>
	);
};

const CustomLegend = ({payload, activeIndex, setActiveIndex}: any) => {
	const screenWidth = window.innerWidth ;
	return (
	 <ul>
		 {payload.map((entry: any, index: any) => (
		  <li
		   onMouseEnter={() => setActiveIndex(index)}
		   key={`legend-${index}`}
		   style={{
			   display: 'flex',
			   alignItems: 'center',
			   marginBottom:screenWidth > 1440 ? '8px' : '6px',
			   cursor: 'pointer',
		   }}
		  >
			  <svg width={screenWidth > 1440 ? 20 : 16} height={screenWidth > 1440 ? 20 : 16} style={{borderRadius: '2px'}}>
				  <rect width={screenWidth > 1440 ? 20 : 16} height={screenWidth > 1440 ? 20 : 16} fill={entry.color}/>
			  </svg>
			  <span
			   style={{
				   marginLeft: screenWidth > 1440 ? '8px' : '6px',
				   display: 'flex',
				   justifyContent: 'center',
				   alignItems: 'center',
				   gap: screenWidth > 1440 ? '10px' : '8px',
			   }}
			  >
            <p>{entry.name}</p>
            <p style={{color: '#4DB89E', fontWeight: 600}}>
              {accounting.formatMoney(entry?.value, '$', 2)}
            </p>
          </span>
		  </li>
		 ))}
	 </ul>
	);
};

interface ITwoLevelPieChart {
	graphData: any;
	dataForLegend: any;
}

const TwoLevelPieChart = (props: ITwoLevelPieChart) => {
	const {graphData, dataForLegend} = props;

	const [activeIndex, setActiveIndex] = useState(0);
	const screenWidth = window.innerWidth;


	const onPieEnter = useCallback(
	 (_: any, index: any) => {
		 setActiveIndex(index);
	 },
	 [setActiveIndex]
	);

	return (
	 <div className={"twoLevelPieChart"}>
		 <div

		  style={{
			  background: '#fff',
			  width: screenWidth > 1440 ? '900px' : '720px',
			  borderRadius: screenWidth > 1440 ? 8 : 6,
			  border: '1px solid #CBCBCB',
			  marginBottom: screenWidth > 1440 ? 10 : 8,
			  position: 'relative',
		  }}
		 >

             {graphData?.length ? (
              <PieChart width={screenWidth > 1440 ? 870 : 696} height={screenWidth > 1440 ? 450 : 360}>
                  <Pie
                   activeIndex={activeIndex}
                   activeShape={renderActiveShape}
                   data={graphData}
                   cx={screenWidth > 1440 ? 320 : 256}
                   cy={screenWidth > 1440 ? 230 : 184}
                   innerRadius={screenWidth > 1440 ? 80 : 64}
                   outerRadius={screenWidth > 1440 ? 120 : 96}
                   fill="#8884d8"
                   dataKey="value"
                   onMouseEnter={onPieEnter}
                  >
                      {graphData.map((entry: any, index: any) => (
                       <Cell key={`cell-${index}`} fill={entry.color}/>
                      ))}
                  </Pie>
                  <Legend
                   width={screenWidth > 1440 ? 240 : 192}
                   layout="vertical"
                   align="right"
                   verticalAlign="middle"
                   payload={dataForLegend.map((entry: any) => ({
                       id: entry.name,
                       type: 'rect',
                       name: entry.name,
                       value: `$${entry.value}`,
                       color: entry.color,
                   }))}
                   wrapperStyle={{whiteSpace: 'nowrap', height:screenWidth > 1440 ? '300' : '240', top:screenWidth > 1440 ? '10%' : '8%'}}
                   content={
                       <CustomLegend
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                       />
                   }
                  />
              </PieChart>
             ) : (
              <div
               style={{
                   width: screenWidth > 1440 ? 900 : 720,
                   height: screenWidth > 1440 ? 450 : 360,
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                   fontSize: screenWidth > 1440 ? '20px' : '16px',
               }}
              >
                  No results
              </div>
             )}
		 </div>
	 </div>
	);
};

export default TwoLevelPieChart;
