import React, {useState} from "react";
import styles from './TemplatesHeader.module.scss';
import {CustomizeIcon} from "../../../assets/icons";
import PerPageSelect from "../../shared/PerPageSelect";
import {instanceAxios} from "../../../services";
import TemplatesPagination from "../TablePagination";
import Tippy from "@tippyjs/react";
import './templatesHeader.css';

export const TemplatesHeader = ({hasSimple, handleCheckboxChange,  isDefaultTemplate, metadata, filterBy, handleGetDataPerPage, setIsCanceled, handleSaveListings, retryActive, setRetryActive, pageCount, saveActive, setTableData, setSaveActive, handleChangeModal, isBulkEdit, setIsBulkEdit, setMetadata, pagiInputValue, setPagiInputValue, setPageCount, setLoader}:any) =>{

	const handleChangeBulkEdit = () => {
		setRetryActive(false);
		setSaveActive(false);
		setIsCanceled(false);
		setIsBulkEdit(!isBulkEdit);
	}
	const handleCancel = () => {
		setIsCanceled(true);
		setIsBulkEdit(!isBulkEdit);
	}



	return (
	 <div className={styles.templatesHeader} id="templatesHeader">
		 <p className={styles.templatesTitle}>Templates</p>

		 <div className={styles.templatesHeaderRightPart}>
			 <div className={styles.defaultTemplateCheckbox}>
				 <label>
					 <p>Default Template</p>
					 <input
					  className="selectInputDraftSold"
					  type="checkbox"
					  checked={isDefaultTemplate}
					  onChange={handleCheckboxChange}
					 />
				 </label>
			 </div>
			 <TemplatesPagination
			  isBulkEdit={isBulkEdit}
			  pageCount={pageCount}
			  pagiInputValue={pagiInputValue}
			  setPagiInputValue={setPagiInputValue}
			 />
			 <div className={styles.perPageSelectBlock}>
				 <span>Per Page</span>
				 <PerPageSelect
				  handleGetDataPerPage={handleGetDataPerPage}
				  metadata={metadata}
				  type="Templates"
				 />
			 </div>
			 <div className={styles.customize} onClick={handleChangeModal}>
				 <CustomizeIcon className="settingsIconCustomize"/> Customize
			 </div>
			 {
				 hasSimple ?
				 <div>
					 <Tippy
					  interactive={true}
					  zIndex={999}
					  arrow={false}
					  trigger="focus click"
					  content={
						  <div className={styles.popoverContentDisabledActions}>
							  <p className={styles.desc}>
								  Bulk edit is available for Business, Pro and Pro Plus
								  users. Upgrade to access this feature and many more.
							  </p>
							  <a
							   target={'_blank'}
							   href={'https://listperfectly.com/pricing'}
							  >
								  {' '}
								  Upgrade Now{' '}
							  </a>
						  </div>
					  }
					 >
						 <button className={`myBtn ${styles.bulkBtnDisable}`}>
							 Bulk Edit
						 </button>
					 </Tippy>
				 </div> :
				  <>
					  {
						  !isBulkEdit ? (
						   <div className={styles.bulkBtnWrapper}>
							   <button
								className="myBtn bulkBtn"
								onClick={handleChangeBulkEdit}
							   >
								   Bulk Edit
							   </button>
						   </div>
						  ) : (
						   <div className={styles.saveAndCancel} id='saveAndCancel'>
							   <button
								onClick={() => handleSaveListings()}
								className={`myBtn saveBulkBtn ${!saveActive ? styles.saveDisabled : ''}`}
							   >
								   {retryActive ? 'Retry' : 'Save'}
							   </button>

							   <button
								className="myBtn dangerCancelBtn"
								onClick={handleCancel}
							   >
								   Cancel Edits
							   </button>
						   </div>
						  )
					  }
				  </>
			 }

		 </div>
	 </div>
	)
}