import React, {useEffect} from 'react';
import Tippy from '@tippyjs/react';
import styles from '../../Table.module.scss';
import { ReactComponent as EbayLogo } from '../../../../../../assets/icons/svg/ebayIconNew.svg';
import { ReactComponent as AmazonLogo } from '../../../../../../assets/icons/svg/AmazonIconNew.svg';
import { ReactComponent as GrailedLogo } from '../../../../../../assets/icons/svg/GreiledIconUpdated.svg';
import { ReactComponent as ShopifyLogo } from '../../../../../../assets/icons/svg/ShopifyUpdatedICon.svg';
import { ReactComponent as EtsyLogo } from '../../../../../../assets/icons/svg/EtsyIconUpdated.svg';
import { ReactComponent as DepopLogo } from '../../../../../../assets/icons/svg/DepopIconUpdated.svg';
import { ReactComponent as PoshmarkLogo } from '../../../../../../assets/icons/svg/poshmarkIconNew.svg';
import { ReactComponent as SoldLogo } from '../../../../../../assets/icons/svg/soldIIconNew.svg';
import { ReactComponent as KitidzenLogo } from '../../../../../../assets/icons/svg/KitidzenIconUpdated.svg';
import { ReactComponent as FacebookLogo } from '../../../../../../assets/icons/svg/FacebookIconUpdated.svg';
import { ReactComponent as ListingPartyLogo } from '../../../../../../assets/icons/svg/ListinPartyIconUpdated.svg';
import { ReactComponent as VestiaireLogo } from '../../../../../../assets/icons/svg/VestiaireIconUpdated.svg';
import { ReactComponent as InstagramLogo } from '../../../../../../assets/icons/svg/InstagramIconUpdated.svg';
import { ReactComponent as MercaryLogo } from '../../../../../../assets/icons/svg/MercaryIconNew.svg';

// import MyToolTip from '../MyToolTip';

// import yellowBadge from '../../../../../../assets/icons/svg/yellowBadge.svg'; //vremenno skrito

interface IplatformBlockItem {
  platformName: string;
  sync?: any;
  href: string;
  platformIcon?: any;
  isLink?: boolean;
  fromPlatform?: boolean;
  handleTooltipClickPlatforms?: any;
  tooltipsPlatformActive?: any;
  visible?: any;
  IsSold?: any;
  type?: string;
  syncIssue?:any;
  syncIssueClick?:any;
  hasTitle?:any;
}

const PlatformBlockItem: React.FC<IplatformBlockItem> = (props) => {
  const {
    platformName,
    sync,
    href,
    platformIcon,
    isLink,
    fromPlatform,
    handleTooltipClickPlatforms,
    tooltipsPlatformActive,
    visible,
    IsSold,
    type,
    syncIssue,
    syncIssueClick,
    hasTitle
  } = props;

  useEffect(() => {
    const handleScroll = () => {
      if (handleTooltipClickPlatforms) {
        handleTooltipClickPlatforms(null); // скрывает Tippy при скролле
      }
    };

    const scrollTable:any = document.getElementById('catalogPageTable')

    scrollTable?.addEventListener('scroll', handleScroll);

    return () => {
      scrollTable?.removeEventListener('scroll', handleScroll);
    };
  }, [handleTooltipClickPlatforms]);

  const isSmallScreenLapTOp = window.innerWidth < 1440 ;

  return (
    <div className={styles.platformItemBlock}>
      <div style={{ position: 'relative', margin: hasTitle ? '3px 0' : '5px 5px 0 0'}}>
        {isLink && !syncIssue ? (
          <div>
            <a target="_blank" href={href} style={ hasTitle ? { userSelect: 'none', display:'flex', alignItems:'center', gap:'10px' } : { userSelect: 'none' }}>
              <div
                className="socialIcon"
                style={ hasTitle ? { height: isSmallScreenLapTOp ? '16px' : '20px', width: isSmallScreenLapTOp ? '16px' : '20px' , minWidth:isSmallScreenLapTOp ? '16px' : '20px' } : { height:isSmallScreenLapTOp ? "32px" : "40px" , width: isSmallScreenLapTOp ? "32px" : "40px" }}
              >
                {platformName === 'eBay' ? <EbayLogo /> : null}
                {platformName === 'Amazon' ? <AmazonLogo /> : null}
                {platformName === 'Grailed' ? <GrailedLogo /> : null}
                {platformName === 'Shopify' ? <ShopifyLogo /> : null}
                {platformName === 'Etsy' ? <EtsyLogo /> : null}
                {platformName === 'Depop' ? <DepopLogo /> : null}
                {platformName === 'Poshmark' ? <PoshmarkLogo /> : null}
                {platformName === 'SOLD' ? <SoldLogo /> : null}
                {platformName === 'Kidizen' ? <KitidzenLogo /> : null}
                {platformName === 'Facebook' ? <FacebookLogo /> : null}
                {platformName === 'listing_party' ? <ListingPartyLogo /> : null}
                {platformName === 'Vestiaire' ? <VestiaireLogo /> : null}
                {platformName === 'Instagram' ? <InstagramLogo /> : null}
                {platformName === 'Mercari' ? <MercaryLogo /> : null}
              </div>
              {
                  hasTitle && <div className={styles.platformTitleWithLink}> {platformName === 'listing_party' ? 'Listing Party' : platformName}</div>
              }

            </a>
          </div>
        ) : isLink && syncIssue ? (
         <div>
           <a target="_blank" href={href} style={hasTitle ? {userSelect: 'none', position: 'relative', display:"flex", alignItems:'center', gap: '10px'}  : {userSelect: 'none', position: 'relative'}}
              onClick={(e) => e.preventDefault()}>
             <div className={`syncIssueDot ${hasTitle ? 'syncIssueDotSmall' : ''}`} onClick={syncIssueClick}></div>
             <div
                 className="socialIcon"
                 style={hasTitle ? {height: isSmallScreenLapTOp ? "16px" : '20px', width: isSmallScreenLapTOp ? "16px" : "20px", minWidth:isSmallScreenLapTOp ? "16px" : "20px"} : {height: isSmallScreenLapTOp ? "32px" : "40px", width: isSmallScreenLapTOp ? "32px" : "40px"}}
             >
               {platformName === 'eBay' ? <EbayLogo/> : null}
               {platformName === 'Amazon' ? <AmazonLogo/> : null}
               {platformName === 'Grailed' ? <GrailedLogo/> : null}
               {platformName === 'Shopify' ? <ShopifyLogo/> : null}
               {platformName === 'Etsy' ? <EtsyLogo/> : null}
               {platformName === 'Depop' ? <DepopLogo/> : null}
               {platformName === 'Poshmark' ? <PoshmarkLogo/> : null}
               {platformName === 'SOLD' ? <SoldLogo/> : null}
               {platformName === 'Kidizen' ? <KitidzenLogo/> : null}
               {platformName === 'Facebook' ? <FacebookLogo/> : null}
               {platformName === 'listing_party' ? <ListingPartyLogo/> : null}
               {platformName === 'Vestiaire' ? <VestiaireLogo/> : null}
               {platformName === 'Instagram' ? <InstagramLogo/> : null}
               {platformName === 'Mercari' ? <MercaryLogo/> : null}
             </div>
             {
                 hasTitle && <div className={styles.platformTitleWithLink}> {platformName === 'listing_party' ? 'Listing Party' : platformName}</div>
             }
           </a>
         </div>
        ) : (
            <div style={ hasTitle ? {position: 'relative', display: "flex", alignItems: 'center', gap: '10px'} : {position: 'relative'}}>
            {type !== 'sales' &&
              platformName !== 'SOLD' &&
              platformName !== 'Tradesy' &&
              fromPlatform &&
              IsSold !== 'SOLD' && (
                <Tippy
                  interactive={true}
                  zIndex={9999}
                  arrow={false}
                  placement="top"
                  trigger="click"
                  appendTo={document.body}
                  onClickOutside={() => {
                    if (handleTooltipClickPlatforms) {
                      handleTooltipClickPlatforms(platformName);
                    }
                  }}
                  visible={visible}
                  content={
                    <span>
                      A platform status is selected, but no platform ID is saved
                      to the corresponding item number field in "inventory and
                      sales"
                    </span>
                  }
                >
                  <div
                    className={`issueDot ${hasTitle ? 'issueDotSmall' : ''}`}
                    onClick={() => {
                      if (handleTooltipClickPlatforms) {
                        handleTooltipClickPlatforms(platformName);
                      }
                    }}
                  ></div>
                </Tippy>
              )}
            <div
              style={hasTitle ? { userSelect: 'none', height: isSmallScreenLapTOp ? "16px" : "20px", width: isSmallScreenLapTOp ? "16px" : "20px", minWidth:isSmallScreenLapTOp ? "16px" : "20px" } : { userSelect: 'none', height: isSmallScreenLapTOp ? "32px" : "40px", width: isSmallScreenLapTOp ? "32px" : "40px" }}
              className="socialIcon"
            >
              {platformName === 'eBay' ? <EbayLogo /> : null}
              {platformName === 'Amazon' ? <AmazonLogo /> : null}
              {platformName === 'Grailed' ? <GrailedLogo /> : null}
              {platformName === 'Shopify' ? <ShopifyLogo /> : null}
              {platformName === 'Etsy' ? <EtsyLogo /> : null}
              {platformName === 'Depop' ? <DepopLogo /> : null}
              {platformName === 'Poshmark' ? <PoshmarkLogo /> : null}
              {platformName === 'SOLD' ? <SoldLogo /> : null}
              {platformName === 'Kidizen' ? <KitidzenLogo /> : null}
              {platformName === 'Facebook' ? <FacebookLogo /> : null}
              {platformName === 'listing_party' ? <ListingPartyLogo /> : null}
              {platformName === 'Vestiaire' ? <VestiaireLogo /> : null}
              {platformName === 'Instagram' ? <InstagramLogo /> : null}
              {platformName === 'Mercari' ? <MercaryLogo /> : null}
            </div>
              {
                  hasTitle && <div className={styles.platformTitleWithNoLink}> {platformName === 'listing_party' ? 'Listing Party' : platformName}</div>
              }

            </div>
        )}
      </div>

      {/* {isOpenToolTip && (
        <MyToolTip handleToggleTollTip={handleToggleTollTip} sync={sync} />
      )}
      * vremenno skrito */}
    </div>
  );
};

export default PlatformBlockItem;
