import React, { Suspense } from 'react';
import { router } from './routes';
import Loader from './components/Templates/Loader';
import { RouterProvider } from 'react-router-dom';

function App() {
  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </Suspense>
    </React.Fragment>
  );
}

export default App;
