import pMap from 'p-map';
import axios, { AxiosRequestConfig } from 'axios';

let token: string | undefined;

const tokenFromLocalStorage = localStorage.getItem('lp_authorization_token');
if (tokenFromLocalStorage) {
  token = tokenFromLocalStorage;
} else {
  const tokenFromCookies = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith('lp_authorization_token='));

  if (tokenFromCookies) {
    const tokenPrefix = 'lp_authorization_token=';

    token = tokenFromCookies.substring(tokenPrefix.length);
  } else {
    window.location.href = 'https://app.listperfectly.com/';
  }
}

const Giver = ({ token }: { token: string }) => {
  const axCfg: AxiosRequestConfig = {
    url: 'https://api.listperfectly.com/v1/listings/list',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };

  const getTotal = async (payload: any) => {
    payload.metadata = { offset: 0, limit: 1 };

    return await axios({ ...axCfg, data: payload })
      .then(({ data }) => {
        return data?.metadata?.total;
      })
      .catch(({ message }) => {
        console.log('[getTotal] err', message);
        return false;
      });
  };

  const getAllDataByPayload = async (
    initPayload: any,
    divider_number: number,
    max_concurrency: number,
    onProgress: (percent: number) => void
  ) => {
    if (!initPayload || typeof initPayload !== 'object') return false;

    const mapper = async (alimit_offset: number) => {
      const data = {
        ...initPayload,
        ...{ metadata: { offset: alimit_offset, limit: divider_number } },
      };

      return await axios({ ...axCfg, data })
        .then(({ data }) => {
          return data;
        })
        .catch(({ message }) => {
          console.log('[mapper] err', alimit_offset, message);
          return { err: message };
        })
        .finally(() => {
          onProgress(
            ((limits.indexOf(alimit_offset) + 1) / limits.length) * 100
          );
        });
    };

    const total = await getTotal(initPayload);
    const limits = Array(((total / divider_number) | 0) + 1)
      .fill(1)
      .map((_, k) => k * divider_number);
    const result = await pMap(limits, mapper, { concurrency: max_concurrency });

    return result.flatMap(({ data, err }) => data || err);
  };

  return { getTotal, getAllDataByPayload };
};

export const { getAllDataByPayload } = Giver({ token: token! });
