import Header from '../Header';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import jwt_decode from 'jwt-decode';
import _ from "lodash";
import {useLocation} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import {useIntercom} from 'react-use-intercom';

import getToken from '../../../utils/getToken';
import useAppPrefix from '../../../hooks/UseAppPrefix';
import {setHasBusiness, setHasSimple} from '../../../redux/slices/RoleSlice';
import {setAllowAllTemplatesAccess, setIsMobile} from '../../../redux/slices/NavigationSlice';
import {instanceAxios} from "../../../services";
import 'react-toastify/dist/ReactToastify.css'; // import first
import MobileMenuComponent from "../../MobileMenu"; // then this
import {
	setCustomMarketplaces,
	setCustomMarketsLoading,
	setFinishedGetCustomMarkets
} from "../../../redux/slices/customMarketplacesSlice";
import NotificationsModal from "../NotificationsModal";
import {
	AddListingQuickLink,
	AddTemplateQuickLink,
	CommunityQuickLink2,
	ContactUsQuickLink,
	CurrentIssuesQuickLink,
	ExpressListerQuickLink,
	ExtensionQuickLink,
	FaqQuickLink,
	HelpCenterQuickLink,
	LatestVersionQuickLink,
	ListingPartyEventsQuickLink,
	ListingPartyQuickLink, ListingSvg,
	LogOutQuickLink,
	MyAccountQuickLink,
	OldCatalogQuickLink,
	ReferralQuickLink,
	SalesAnalyticsQuickLink,
	ViewListingQuickLink,
	ViewTemplatesQuickLink,
	WebsiteQuickLink
} from "../../../assets/icons";
import toSnakeToTitle from "../../../utils/toSnakeToTitle";

interface LayoutProps {
	children: JSX.Element[] | JSX.Element;
	routerLink: string;
}

interface NavigationState {
	active: boolean;
	showIframe: boolean;
	isMobile: boolean;
}

interface RootState {
	navigation: NavigationState;
}

function Layout({children, routerLink}: LayoutProps) {
	const location = useLocation();
	const showEditPageModal = useSelector((state: any) => state.navigation.editModalActive);
	const showExpressModal = useSelector((state: any) => state.navigation.expressLister);
	const showListingViewModal = useSelector((state: any) => state.navigation.listingViewModalShow);
	const showEditTemplateModal = useSelector((state: any) => state.navigation.showEditTemplateModal);
	const navigation = useSelector((state: RootState) => state.navigation.active);
	const isMobile = useSelector((state: any) => state.navigation.isMobile);
	const allowAllTemplatesAccess = useSelector((state: any) => state.navigation.allowAllTemplatesAccess);
	const showIframe = useSelector(
	 (state: RootState) => state.navigation.showIframe
	);
	const {
		boot,
		show,
		hide,
		startChecklist
	} = useIntercom();

	const token: any = getToken();
	const linkPrefix = useAppPrefix();
	const showNotificationModal = useSelector(
	 (state: any) => state.navigation.showNotificationModal
	);
	const dispatch = useDispatch();
	const [accessHeader, setAccessHeader] = useState(false);
	const [configID,setConfigID] = useState<any>(null)
	const [quickLinks,setQuickLinks] = useState<any>([])
    const [isQuickLinksGet, setIsQuickLinkGet] = useState(false);
	const checkToken = () => {
		try {

			const decodedToken: any = jwt_decode(token);
			const currentTime = Date.now() / 1000;

			if (decodedToken.exp < currentTime) {
				window.location.href = 'https://app.listperfectly.com';
			}

			return decodedToken

		} catch (error) {
			window.location.href = 'https:/app.listperfectly.com'
		}

	}

	useEffect(() => {
		try {
			const decodedToken: any = checkToken()
			const roles = decodedToken?.data?.user?.roles;
			const is_role_allowed = roles.some((role: any) => {
				return (
				 role.toLowerCase() === 'corporate master' ||
				 role.toLowerCase() === 'corporate sub'
				);
			});
			const hasPro = roles
			.map((role: string) => role.toLowerCase())
			.includes('pro'.toLowerCase());
			const hasSimple = roles
			.map((role: string) => role.toLowerCase())
			.includes('simple'.toLowerCase());
			if (hasSimple) {
				dispatch(setHasSimple(true));
			}
			const hasBusiness = roles
			.map((role: string) => role.toLowerCase())
			.includes('business'.toLowerCase());
			if (hasBusiness) {
				dispatch(setHasBusiness(true));
			}
			if (!hasPro && !is_role_allowed && !hasBusiness && !hasSimple) {
				window.location.href = `https://${linkPrefix?.prefix}.listperfectly.com/mainmenu/`;
			}
		} catch (error) {
			console.error(error)
		}

	}, []);

	useLayoutEffect(() => {
		// Function to check if it's a mobile device
		const checkIsMobile = () => {
			const mobileWidthThreshold = 769; // Example threshold for mobile screens
			const screenWidth =
			 window.innerWidth ||
			 document.documentElement.clientWidth ||
			 document.body.clientWidth;
			setAccessHeader(!(screenWidth < mobileWidthThreshold));
			dispatch(setIsMobile(screenWidth < mobileWidthThreshold));
		};

		// Initial check
		checkIsMobile();

		// Add a listener to re-check if the window size changes
		window.addEventListener('resize', checkIsMobile);

		// Clean up the listener when the component unmounts
		return () => {
			window.removeEventListener('resize', checkIsMobile);
		};
	}, []); // Empty dependency array means it runs once after the initial render

	const usersSelf = async () => {
		try {
			const payload = {s: '1'};
			const response = await instanceAxios.post('users/self', payload)
			if (response.status === 200) {
				return response.data.data
			}
			return null
		} catch (error) {

			console.error(error)
		}
	}

	useEffect(() => {
		const handleResize = () => {
			const intercomLauncher: any = document.querySelector('.intercom-launcher')

			if (window.innerWidth <= 768) {
				if (intercomLauncher) {
					intercomLauncher.style.display = 'none';

				}
			} else {
				if (intercomLauncher) {
					intercomLauncher.style.display = 'block';
				}
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		usersSelf().then((data) => {

			const body = document.body;
			const lp_chrome_extension_version = body.getAttribute('lp_custom_chrome_extension_version');
			const lp_active_chrome_extensions = body.getAttribute('lp_custom_chrome_extension_active_extensions');
			const allowAllTemplatesAccess = _.get(data, 'metadata.sub_account_settings.allow_all_templates_access');
			dispatch(setAllowAllTemplatesAccess(allowAllTemplatesAccess))
			const lp_user_id = _.get(data, 'id');
			const lp_user_email = _.get(data, 'email');
			const lp_user_registered = (new Date(_.get(data, 'created_at'))).getTime() / 1000;
			const lp_roles = (_.get(data, 'roles') || []).join(',');
			const user_hash = _.get(data, 'intercom_user_hash');
			const lp_user_name = `${_.get(data, 'metadata.first_name') || ''} ${_.get(data, 'metadata.last_name') || ''}`;
			const lp_affiliate_id = `${_.get(data, 'metadata.affiliate_id') || ''}`;
			const lp_affiliate_coupon_codes = `${_.get(data, 'metadata.affiliate_coupon_codes') || ''}`;
			const lp_user_agent = navigator.userAgent;
			let lp_schema = ""

			if (token) {
				let decodedToken = checkToken()
				lp_schema = _.get(decodedToken, 'iss') === 'https://app.listperfectly.com' ? 'app' : 'apptest';
			}

			const intercomUserData = {
				api_base: "https://api-iam.intercom.io",
				app_id: "s2df9k19",
				name: lp_user_name,
				user_id: `${lp_schema}_${lp_user_id}`,
				email: lp_user_email,
				lp_user_id,
				lp_schema,
				lp_user_agent,
				lp_roles,
				created_at: lp_user_registered,
				user_hash,
				lp_chrome_extension_version,
				lp_active_chrome_extensions,
				lp_affiliate_id,
				lp_affiliate_coupon_codes
			}

			if (data && user_hash) {
				const searchParams = new URLSearchParams(location.search);

				boot(
				 {
					 name: lp_user_name,
					 customAttributes: intercomUserData,
				 }
				)
				if (searchParams.get('checklist')) {
					const checkListId = Number((searchParams.get('checklist')))
					show()
					startChecklist(checkListId)

				} else {
					hide()
				}
			}
		})

	}, []);


	const getCustomMarketplaces = async () => {
		try {
			const decodedToken: any = jwt_decode(token);
			const roles = decodedToken?.data?.user?.roles;

			const hasSimple = roles
			.map((role: string) => role.toLowerCase())
			.includes('simple'.toLowerCase());
			if (hasSimple) {
				dispatch(setHasSimple(true));
			}
			const hasBusiness = roles
			.map((role: string) => role.toLowerCase())
			.includes('business'.toLowerCase());
			const {data} = await instanceAxios.post('/user-configs/list', {
				data: [
					{
						user_id: 'user_id',
						type: '',
						config: {},
						id: '',
					},
				],
			});
			if(data) {
				const websiteData = _.get(data, 'data[1]') ;
				let quickLinks = websiteData.config.quick_links || [];

				if (!quickLinks.length ) {

					const defaultQuickLinks = ['express_lister', 'view_listings']

					quickLinks = defaultQuickLinks;

				}
				if(Array.isArray(quickLinks)) {
					const linkMapping: { [key: string]: { href: string, svg: any } } = {
						'express_lister': { href: '/listings/express', svg: ExpressListerQuickLink },
						'add_listing': { href: '', svg: AddListingQuickLink },
						'view_listings': { href: '/listings', svg: ListingSvg },
						'old_catalog': { href: 'https://app.listperfectly.com/listed-items', svg: OldCatalogQuickLink },
						'add_template': { href: '', svg: AddTemplateQuickLink },
						'view_templates': { href: '', svg: ViewTemplatesQuickLink },
						'sales__analytics': { href: '/sales', svg: SalesAnalyticsQuickLink },
						'contact_us': { href: '', svg: ContactUsQuickLink },
						'help_center': { href: 'https://help.listperfectly.com/en', svg: HelpCenterQuickLink },
						'latest_version': { href: 'https://help.listperfectly.com/en/articles/8913965-install-list-perfectly', svg: LatestVersionQuickLink },
						'current_issues': { href: 'https://help.listperfectly.com/en/articles/9039723-current-technical-issue', svg: CurrentIssuesQuickLink },
						'faq': { href: 'https://listperfectly.com/faq', svg: FaqQuickLink },
						'listing_party': { href: 'https://listingparty.com', svg: ListingPartyQuickLink },
						'events': { href: 'https://listingparty.com/events', svg: ListingPartyEventsQuickLink },
						'referral': { href: 'https://app.listperfectly.com/referral-area/?tab=resources', svg: ReferralQuickLink },
						'community': { href: 'https://listperfectly.com/community', svg: CommunityQuickLink2 },
						'my_account': { href: 'https://app.listperfectly.com/account/?action=subscriptions', svg: MyAccountQuickLink },
						'help_requests': { href: 'https://app.listperfectly.com/my-requests', svg: HelpCenterQuickLink },
						'log_out': { href: 'https://app.listperfectly.com/wp-login.php?action=logout&redirect_to=%2Flogin&_wpnonce=09317016fc', svg: LogOutQuickLink },
						'extension': { href: '/settings', svg: ExtensionQuickLink },
						'website': { href: '/settings', svg: WebsiteQuickLink },
					};


					let transformedQuickLinks:any = quickLinks?.map((link: string, index: number) => {
						const title = link === 'faq' ? 'FAQ' : link === 'sales__analytics' ? 'Sales & Analytics' : toSnakeToTitle(link, 'to_title');
						const { href, svg } = linkMapping[link] || { href: '', svg: null };
						return linkMapping[link] ? {
							id: index + 1,
							href,
							title,
							svg,
							value: link
						} : null;
					}).filter(Boolean);
					setQuickLinks(transformedQuickLinks)
				} else {
					setQuickLinks([])
				}
				setConfigID(websiteData.id)
				let customMarketplaces = [
					{
						id: Date.now(),
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_1'),
							isChecked: false,
							code: ['891.1'],
							platformId: '892',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_1'
							)}`,
							code: ['891.1not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_1_color'),
						isCustom:true
					},
					{
						id: Date.now() + 1,
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_2'),
							isChecked: false,
							code: ['891.2'],
							platformId: '893',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_2'
							)}`,
							code: ['891.2not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_2_color'),
						isCustom:true
					},
					{
						id: Date.now() + 2,
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_3'),
							isChecked: false,
							code: ['891.3'],
							platformId: '894',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_3'
							)}`,
							code: ['891.3not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_3_color'),
						isCustom:true
					},
					{
						id: Date.now() + 3,
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_4'),
							isChecked: false,
							code: ['891.4'],
							platformId: '895',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_4'
							)}`,
							code: ['891.4not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_4_color'),
						isCustom:true
					},
					{
						id: Date.now() + 4,
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_5'),
							isChecked: false,
							code: ['891.5'],
							platformId: '896',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_5'
							)}`,
							code: ['891.5not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_5_color'),
						isCustom:true
					},
					{
						id: Date.now() + 5,
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_6'),
							isChecked: false,
							code: ['891.6'],
							platformId: '897',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_6'
							)}`,
							code: ['891.6not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_6_color'),
						isCustom:true
					},
					{
						id: Date.now() + 6,
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_7'),
							isChecked: false,
							code: ['891.7'],
							platformId: '898',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_7'
							)}`,
							code: ['891.7not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_7_color'),
						isCustom:true
					},
					{
						id: Date.now() + 7,
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_8'),
							isChecked: false,
							code: ['891.8'],
							platformId: '899',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_8'
							)}`,
							code: ['891.8not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_8_color'),
						isCustom:true
					},
					{
						id: Date.now() + 8,
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_9'),
							isChecked: false,
							code: ['891.9'],
							platformId: '900',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_9'
							)}`,
							code: ['891.9not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_9_color'),
						isCustom:true
					},
					{
						id: Date.now() + 9,
						platform: {
							title: _.get(websiteData, 'config.custom_marketplace_10'),
							isChecked: false,
							code: ['891.10'],
							platformId: '901',
						},
						notOnPlatform: {
							title: `Not on ${_.get(
							 websiteData,
							 'config.custom_marketplace_10'
							)}`,
							code: ['891.10not'],
							isChecked: false,
						},
						color:_.get(websiteData, 'config.custom_marketplace_10_color'),
						isCustom:true
					},

				].filter((item) => item.platform.title !== undefined);
				if ((hasBusiness) && customMarketplaces?.length > 5) {
					customMarketplaces = customMarketplaces?.slice(0, 5);
				}
				if(hasSimple) {
					customMarketplaces = []
				}
				setIsQuickLinkGet(true);
				dispatch(setCustomMarketplaces(customMarketplaces))
				dispatch(setCustomMarketsLoading(false))
			}


		}
		catch (error) {
			console.error(error);
			dispatch(setCustomMarketsLoading(false))

		}
		finally {
			dispatch(setFinishedGetCustomMarkets(true))
		}
	};

	useEffect(() => {
		try {
			const decodedToken: any = jwt_decode(token);
			const roles = decodedToken?.data?.user?.roles;
			const hasSubAcc = roles.some((role: any) => {
				return (role.toLowerCase() === 'corporate sub');
			});
			if(hasSubAcc && !allowAllTemplatesAccess && isQuickLinksGet) {
				const filteredLinks = quickLinks?.filter((item :any) => item.value !== "add_template");
				setQuickLinks(filteredLinks);
			}
		}
		catch (e:any){
           console.log(e, 'error')
		}
	}, [allowAllTemplatesAccess, isQuickLinksGet]);

	useEffect(() => {
		getCustomMarketplaces();
	}, []);

	const toastContainerStyle = {
		zIndex:9999,
		marginBottom: '40px', // Set the margin at the bottom
	};

	const toastOptions = {
		autoClose: 10000,
		hideProgressBar: false,
	};

	const leftMenuExpanded = Boolean(localStorage.getItem('leftMenuExpanded'))


	return (
	 <div
	  style={{gap: navigation ? '25px' : '15px'}}
	  className="globalContainer"
	 >
		 {(showIframe || showExpressModal || showEditPageModal || showEditTemplateModal || (showListingViewModal && !isMobile)) && <div className="iframeOverflay"></div>}
		 <div
		  style={{
			  width: navigation ? '210px' : '100px',
			  transition:'width 0.3s ease',
			  position: !isMobile &&!leftMenuExpanded ? 'fixed' : 'static',
			  left:0,
			  top:0,
			  zIndex:9992,

		  }}
		  className={ "listings-header-up"}
		 >
			 {accessHeader && <Header routerLink={routerLink} configID={configID} quickLinks={quickLinks}
									  setQuickLinks={setQuickLinks}
			 />}
		 </div>
		 <div
		  style={{
			  width: isMobile
			   ? '100%'
			   : navigation && leftMenuExpanded
				? 'calc(100% - 260px)'
				: 'calc(100% - 150px)',
			  transition:navigation ? '0.3s ease margin' : '',
			  marginLeft:!isMobile && !leftMenuExpanded ? 95 : ""
		  }}
		 >
			 <main>
				 <div className="page-container">
					 <ToastContainer style={toastContainerStyle} {...toastOptions}/>
					 {children}
				 </div>
			 </main>
		 </div>
		 {isMobile ? <MobileMenuComponent
		  routerLink={routerLink}
		  quickLinks={quickLinks}
		  setQuickLinks={setQuickLinks}
		  configID={configID}
		 /> : null}
		 {showNotificationModal && <NotificationsModal/>}
	 </div>
	);
}

export default Layout;
