import styles from './ListingTitleContainer.module.scss';
import Tippy from "@tippyjs/react";
import {PopoverIcon} from "../../../../assets/icons";
import {useState} from "react";
import {InputCount} from "../../InputCount";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import getNameGenerator from "../../../../hooks/useNameGenerator";
import {TextareaWithCount} from "../../TextAreaCount";

const ListingTitleContainer = ({showAllTitles, setShowAllTitles,showSuggestTitles, setShowSuggestTitles,  hasBusiness, hasSimple,formInfo, unescapedRowMetadata, setFormInfo, itemData, editIndex, setItemData, handleStandartInputChange}:any) => {

	const toggleShowAllTitles = () => {
		setShowAllTitles(!showAllTitles);
		if(showAllTitles){
			const updatedItem = [...itemData];
			updatedItem[editIndex] = {
				...updatedItem[editIndex],
				['showAllTitlesCheckbox']: null,
				changed: true,
			};
			setItemData(updatedItem);
			setFormInfo({
				...formInfo,
				['showAllTitlesCheckbox']: { text: null, changed: true },
			});
		}else{
			const updatedItem = [...itemData];
			updatedItem[editIndex] = {
				...updatedItem[editIndex],
				['showAllTitlesCheckbox']: "Show all Titles",
				changed: true,
			};
			setItemData(updatedItem);
			setFormInfo({
				...formInfo,
				['showAllTitlesCheckbox']: { text: "Show all Titles", changed: true },
			});
		}
	};
	const toggleSuggestTitles = () => {
		setShowSuggestTitles(!showSuggestTitles);
		if(showSuggestTitles){
			const updatedItem = [...itemData];
			updatedItem[editIndex] = {
				...updatedItem[editIndex],
				['suggestTitleCheckbox']: null,
				changed: true,
			};
			setItemData(updatedItem);
			setFormInfo({
				...formInfo,
				['suggestTitleCheckbox']: { text: null, changed: true },
			});
		}else{
			const updatedItem = [...itemData];
			updatedItem[editIndex] = {
				...updatedItem[editIndex],
				['suggestTitleCheckbox']: "Yes",
				changed: true,
			};
			setItemData(updatedItem);
			setFormInfo({
				...formInfo,
				['showAllTitlesCheckbox']: { text: "Yes", changed: true },
			});
		}
	};
	const { bdecode } = useBasisFuncs();

	const handleChangeTitleInput = (
	 e:
	  | React.ChangeEvent<HTMLInputElement>
	  | React.ChangeEvent<HTMLTextAreaElement>
	  | React.ChangeEvent<HTMLSelectElement>,
	 assistanceValue?: string,
	) => {
		const {name, value} = e.target;
		const updatedItem = [...itemData];
		const generatedName = getNameGenerator(name);
		updatedItem[editIndex] = {
			...updatedItem[editIndex],
			[generatedName]: assistanceValue ? assistanceValue : value,
			...((formInfo.newMercaryTitle.changed || (unescapedRowMetadata[789] &&  unescapedRowMetadata[789] !== unescapedRowMetadata[133])) &&
			formInfo.newMercaryTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[133]) && formInfo.newMercaryTitle.text)
			 ? {}
			 : { newMercaryTitle: assistanceValue ? assistanceValue : value }),
			...((formInfo.newPoshmarkTitle.changed || (unescapedRowMetadata[786] && unescapedRowMetadata[786] !== unescapedRowMetadata[133])) &&
			formInfo.newPoshmarkTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[133]) && formInfo.newPoshmarkTitle.text)
			 ? {}
			 : {
				 newPoshmarkTitle: assistanceValue ? assistanceValue : value,
			 }),
			...((formInfo.newGrailedTitle.changed || (unescapedRowMetadata[788] && unescapedRowMetadata[788] !== unescapedRowMetadata[133])) &&
			formInfo.newGrailedTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[133]) && formInfo.newGrailedTitle.text)
			 ? {}
			 : { newGrailedTitle: assistanceValue ? assistanceValue : value }),
			...((formInfo.newAmazonEbayTitle.changed || (unescapedRowMetadata[791] && unescapedRowMetadata[791] !== unescapedRowMetadata[133])) &&
			formInfo.newAmazonEbayTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[133]) && formInfo.newAmazonEbayTitle.text)
			 ? {}
			 : {
				 newAmazonEbayTitle: assistanceValue ? assistanceValue : value,
			 }),
			...((formInfo.newFacebookTitle.changed || (unescapedRowMetadata[869] && unescapedRowMetadata[869] !== unescapedRowMetadata[133])) &&
			formInfo.newFacebookTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[869]) && formInfo.newTitle.text)
			 ? {}
			 : {
				 newFacebookTitle: assistanceValue ? assistanceValue : value,
			 }),
			changed: true,
		};
		setItemData(updatedItem)
		setFormInfo({
			...formInfo,
			[generatedName]: {
				text: assistanceValue ? assistanceValue : value,
				changed: true,
			},
			...((formInfo.newMercaryTitle.changed || (unescapedRowMetadata[789] &&  unescapedRowMetadata[789] !== unescapedRowMetadata[133])) &&
			formInfo.newMercaryTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[133]) && formInfo.newMercaryTitle.text)
			 ? {}
			 : {
				 newMercaryTitle: {
					 text: assistanceValue ? assistanceValue : value,
					 changed: true,
				 },
			 }),
			...((formInfo.newPoshmarkTitle.changed || (unescapedRowMetadata[786] && unescapedRowMetadata[786] !== unescapedRowMetadata[133])) &&
			formInfo.newPoshmarkTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[133]) && formInfo.newPoshmarkTitle.text)
			 ? {}
			 : {
				 newPoshmarkTitle: {
					 text: assistanceValue ? assistanceValue : value,
					 changed: true,
				 },
			 }),
			...((formInfo.newGrailedTitle.changed || (unescapedRowMetadata[788] && unescapedRowMetadata[788] !== unescapedRowMetadata[133])) &&
			formInfo.newGrailedTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[133]) && formInfo.newGrailedTitle.text)
			 ? {}
			 : {
				 newGrailedTitle: {
					 text: assistanceValue ? assistanceValue : value,
					 changed: true,
				 },
			 }),
			...((formInfo.newAmazonEbayTitle.changed || (unescapedRowMetadata[791] && unescapedRowMetadata[791] !== unescapedRowMetadata[133])) &&
			formInfo.newAmazonEbayTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[133]) && formInfo.newAmazonEbayTitle.text)
			 ? {}
			 : {
				 newAmazonEbayTitle: {
					 text: assistanceValue ? assistanceValue : value,
					 changed: true,
				 },
			 }),
			...((formInfo.newFacebookTitle.changed || (unescapedRowMetadata[869] && unescapedRowMetadata[869] !== unescapedRowMetadata[133])) &&
			formInfo.newFacebookTitle.text !==
			((formInfo.newTitle.text || unescapedRowMetadata[869]) && formInfo.newFacebookTitle.text)
			 ? {}
			 : {
				 newFacebookTitle: {
					 text: assistanceValue ? assistanceValue : value,
					 changed: true,
				 },
			 }),
		});
	};

	return (
	 <div className={styles.listingTitleContainer}>
		 <div className={styles.TitlesContainer}>
			 <div className={styles.title}>
				 Listing Title
				 <Tippy
				  interactive={true}
				  zIndex={9991}
				  arrow={false}
				  trigger="click"
				  content={
					  <div className={`popover ${styles.popoverContent}`}>
						  <p className={styles.heading}>Title Character Count:</p>
						  <ul>
							  <li>
								  Etsy, Shopify & Kidizen: Title for these
								  marketplaces will be copied from main title field
								  and is limited to 140.
							  </li>
							  <li>Facebook: 99</li>
							  <li>eBay: 80</li>
							  <li>Grailed: 60</li>
							  <li>Poshmark: 80</li>
							  <li>Mercari: 80</li>
							  <li>Tradesy, Instagram & Depop: No title</li>
							  <div className={styles.line}></div>
							  <p>★ Crossposts to supported 'title' fields.</p>
						  </ul>
					  </div>
				  }
				 >
					 <PopoverIcon
					  className={styles.popoverIcon}
					  color={'#fff'}
					 ></PopoverIcon>
				 </Tippy>
			 </div>
			 <div className={styles.titlesMain}>
				 <InputCount
				  placeholder=""
				  name="input_133"
				  value={
					  formInfo.newTitle.changed
					   ? bdecode(formInfo.newTitle.text)
					   : unescapedRowMetadata[133]
						? bdecode(unescapedRowMetadata[133])
						: ''
				  }
				  handleChange={handleChangeTitleInput}
				  showCount={true}
				  limit={140}
				  height={40}
				 />
				 <div style={{ visibility: 'hidden', position: 'absolute' }}>
					 <InputCount
					  placeholder=""
					  name="input_727"
					  value={
						  formInfo?.newThumbnail?.changed
						   ? formInfo?.newThumbnail?.text
						   : unescapedRowMetadata[727]
					  }
					  handleChange={handleStandartInputChange}
					  limit={250}
					  height={40}
					 />
					 <InputCount
					  placeholder=""
					  name="input_728"
					  value={
						  formInfo?.newImagesArray?.changed
						   ? formInfo?.newImagesArray?.text
						   : unescapedRowMetadata[728]
					  }
					  handleChange={handleStandartInputChange}
					  limit={250}
					  height={40}
					 />
				 </div>
				 {
				  !hasSimple && <div className={styles.buttonsContainer}>
					  <button
					   className={`${styles.titlesButton} ${
						showAllTitles ? styles.btnActive : ''
					   }`}
					   onClick={toggleShowAllTitles}
					  >
						  Show All Titles
					  </button>
				  </div>
				 }

			 </div>
		 </div>
		 {showAllTitles && (
		  <div className={styles.showAllTitlesContainer}>
			  <div className={styles.showAllTitlesItem}>
				  <div className={styles.itemInner}>
					  <InputCount
					   placeholder=""
					   name="input_789"
					   value={
						   formInfo.newMercaryTitle.changed
							? bdecode(formInfo.newMercaryTitle.text)
							: unescapedRowMetadata[789]
							 ? bdecode(unescapedRowMetadata[789])
							 : unescapedRowMetadata[133]
							  ? bdecode(unescapedRowMetadata[133])
							  : ''
					   }
					   handleChange={handleStandartInputChange}
					   showCount={true}
					   limit={40}
					   height={40}
					  />
					  <p className={styles.heading}>MERCARI TITLE</p>
				  </div>
			  </div>{' '}
			  <div className={styles.showAllTitlesItem}>
				  <div className={styles.itemInner}>
					  <InputCount
					   placeholder=""
					   name="input_786"
					   value={
						   formInfo.newPoshmarkTitle.changed
							? bdecode(formInfo.newPoshmarkTitle.text)
							: unescapedRowMetadata[786]
							 ? bdecode(unescapedRowMetadata[786])
							 : unescapedRowMetadata[133]
							  ? bdecode(unescapedRowMetadata[133])
							  : ''
					   }
					   handleChange={handleStandartInputChange}
					   showCount={true}
					   limit={80}
					   height={40}
					  />
					  <p className={styles.heading}>POSHMARK TITLE</p>
				  </div>
			  </div>
			  <div className={styles.showAllTitlesItem}>
				  <div className={styles.itemInner}>
					  <InputCount
					   placeholder=""
					   name="input_788"
					   value={
						   formInfo.newGrailedTitle.changed
							? bdecode(formInfo.newGrailedTitle.text)
							: unescapedRowMetadata[788]
							 ? bdecode(unescapedRowMetadata[788])
							 : unescapedRowMetadata[133]
							  ? bdecode(unescapedRowMetadata[133])
							  : ''
					   }
					   handleChange={handleStandartInputChange}
					   showCount={true}
					   limit={60}
					   height={40}
					  />
					  <p className={styles.heading}>GRAILED TITLE</p>
				  </div>
			  </div>
			  <div className={styles.showAllTitlesItem}>
				  <div className={styles.itemInner}>
					  <InputCount
					   placeholder=""
					   name="input_791"
					   value={
						   formInfo.newAmazonEbayTitle.changed
							? bdecode(formInfo.newAmazonEbayTitle.text)
							: unescapedRowMetadata[791]
							 ? bdecode(unescapedRowMetadata[791])
							 : unescapedRowMetadata[133]
							  ? bdecode(unescapedRowMetadata[133])
							  : ''
					   }
					   handleChange={handleStandartInputChange}
					   showCount={true}
					   limit={80}
					   height={40}
					  />
					  <p className={styles.heading}>EBAY / AMAZON TITLE</p>
				  </div>
			  </div>
			  <div className={styles.showAllTitlesItem}>
				  <div className={styles.itemInner}>
					  <InputCount
					   placeholder=""
					   name="input_869"
					   value={
						   formInfo.newFacebookTitle.changed
							? bdecode(formInfo.newFacebookTitle.text)
							: unescapedRowMetadata[869]
							 ? bdecode(unescapedRowMetadata[869])
							 : unescapedRowMetadata[133]
							  ? bdecode(unescapedRowMetadata[133])
							  : ''
					   }
					   handleChange={handleStandartInputChange}
					   showCount={true}
					   limit={99}
					   height={40}
					  />
					  <p className={styles.heading}>FACEBOOK TITLE</p>
				  </div>
			  </div>
		  </div>
		 )}
	 </div>
	);
};

export default ListingTitleContainer;