import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import TableResizeColumn from "./TableResizeColumn";
import styles from "./TemplatesTable.module.scss";
import {MoonLoader} from "react-spinners";
import TableRow from "./TableRow";
import {LeftArrow, RightArrow} from "../../../assets/icons";
import {useSelector} from "react-redux";
const minCellWidth = 100;
const maxCeilWidth = 500;

const TemplatesTable = ({ defaultTemplateId, setDefaultTemplateId, setFormChanged, handleChangeDirection, autoSaveLoader, directions, searchResult, columns, loader, isBulkEdit, tableData, setSaveActive, handleSearch, handleChangeCheckbox, handleBulkRestore, selectedListings, handleRowSelected, handleBulkDelete, listingsForDelete, setTableData}: any) => {
  const tableRefTemplates = useRef<HTMLTableElement>();
  const tableElementTemplate = useRef<any>(null);
  const [resizableElementWidth, setResizableElementWidth] = useState(0);
  const [hasScroll, setHasScroll] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const hasSimple = useSelector((state: any) => state.roles.hasSimple);
  const [distancefromRight, setDistanceFromRight] = useState<number>();
  const [tableWidth, setTableWidth] = useState(0);
  const mouseDown = (index: number) => {
    setActiveIndex(index);
  };

  const mouseMove = useCallback(
      (e: any) => {
        if (activeIndex !== null && tableElementTemplate.current) {
          const updatedColumnSizes = columns.map((col:any, i:any) => {
            if (i === activeIndex && col.ref.current) {
              const width =
                  e.clientX - col.ref.current.getBoundingClientRect().left;
              if (width >= minCellWidth && width < maxCeilWidth) {
                return { name: col.value, size: `${width}px` };
              }
            }
            return { name: col.value, size: `${col.ref.current?.offsetWidth}px` };
          });
          updatedColumnSizes.unshift({ name: 'default', size: '70px' });
          // Convert the array of objects to a JSON string
          const size = JSON.stringify(updatedColumnSizes);

          // Set the gridTemplateColumns property of tableElement
          const gridColumns = updatedColumnSizes.map((col:any) => col.size).join(' ');
          tableElementTemplate.current.style.gridTemplateColumns = gridColumns;

          // Save the JSON string in local storage
          localStorage.setItem('sizeTemplates', size);
        }
      },
      [activeIndex, columns, minCellWidth]
  );

  const updateArrowsVisibility = (table:any) => {
    const scrollLeft = table.scrollLeft;
    const maxScrollLeft = table.scrollWidth - table.clientWidth;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < maxScrollLeft);
  };

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  const handleHorizontalScroll = () => {
    const tableContainer = document.querySelector(
        '#templatesTable'
    ) as HTMLDivElement;
    const hasScrolll =
        tableContainer?.scrollWidth > tableContainer?.clientWidth;
    setHasScroll(hasScrolll);
  };

  useLayoutEffect(() => {
    const savedGridTemplateColumns = localStorage.getItem('sizeTemplates');
    const cols = localStorage.getItem('colsTemplates');
    try {
      if (savedGridTemplateColumns) {
        const parsedColumnSizes = JSON.parse(savedGridTemplateColumns);

        const columnSizeMap: any = {};

        parsedColumnSizes?.forEach((col: any) => {
          columnSizeMap[col.name] = col.size;
        });

        const checkerFunction = (value: string) => {
          switch (value) {
            case 'title':
              return '250px';
            case 'default_template':
              return '250px';
            case 'active':
              return '100px';
            case 'actions':
              return '100px';
            case 'updated':
              return '120px';
            case 'created':
              return '120px';
            case 'assignee':
              return '100px';
            default:
              return '100px'; // Default width
          }
        };

        const updatedColumnSizes = columns.map((col:any, i:any) => {
          const size = columnSizeMap[col.value] || checkerFunction(col.value);

          return {
            name: col.value,
            size,
          };
        });
        updatedColumnSizes.unshift({ name: 'default', size: '70px' });
        const size = JSON.stringify(updatedColumnSizes);
        const gridColumns = updatedColumnSizes.map((col:any) => col.size).join(' ');
        localStorage.setItem('sizeTemplates', size);
        if (tableElementTemplate.current) {
          tableElementTemplate.current.style.gridTemplateColumns = gridColumns;
        }
      } else {
        if (tableElementTemplate.current) {
          const columnWidthValues: any = columns.map((column:any) => {
            switch (column.value) {
              case 'title':
                return '250px';
              case 'default_template':
                return '250px';
              case 'active':
                return '100px';
              case 'actions':
                return '100px';
              case 'updated':
                return '120px';
              case 'created':
                return '120px';
              case 'assignee':
                return '100px';
              default:
                return '100px'; // Default width
            }
          });
          columnWidthValues.unshift('70px');
          const size = JSON.stringify(columnWidthValues);
          tableElementTemplate.current.style.gridTemplateColumns =
              columnWidthValues.join(' ');
          localStorage.setItem('sizeTemplates', size);
        }
      }
    } catch (error) {
      localStorage.removeItem('sizeTemplates');
      localStorage.removeItem('colsTemplates');
      window.location.reload();
    }
  }, [columns]);

  useEffect(() => {
    const adjustHeaderWidth = () => {
      const thElements = tableRefTemplates.current?.querySelectorAll('th');
      const tdElements = tableRefTemplates.current?.querySelectorAll('td');

      thElements?.forEach((th, index) => {
        const tdWidth = tdElements?.[index]?.offsetWidth;
        th.style.width = `${tdWidth}px`;
      });
    };

    const handleMouseDown = (e: any) => {
      let isResizing = true;
      let currentTh = e.currentTarget;
      let initialX = e.clientX;
      let initialThWidth = currentTh.offsetWidth;

      const handleMouseMove = (e: any) => {
        if (!isResizing) return;

        const offset = e.clientX - initialX;
        const newWidth = initialThWidth + offset;

        currentTh.style.width = `${newWidth}px`;
      };

      const handleMouseUp = () => {
        isResizing = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        document.body.style.userSelect = 'auto';
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      document.body.style.userSelect = 'none';
    };

    window.addEventListener('resize', adjustHeaderWidth);
    adjustHeaderWidth();

    const headers = tableRefTemplates.current?.querySelectorAll('th');
    headers?.forEach((th) => {
      th.addEventListener('mousedown', handleMouseDown);
    });

    return () => {
      window.removeEventListener('resize', adjustHeaderWidth);
      headers?.forEach((th) => {
        th.removeEventListener('mousedown', handleMouseDown);
      });
    };
  }, []);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  useEffect(() => {
    if (tableElementTemplate.current) {
      const distanceFromRight =
          (window.innerWidth - tableElementTemplate.current.getBoundingClientRect().right) + 15;
      setDistanceFromRight(distanceFromRight);
    }
  }, [mouseMove, mouseDown]);

  useEffect(() => {
    const tableContainer = document.querySelector(
        '.template-main-container .resizable-table'
    ) as HTMLDivElement;
    const scrollLeftButton = document.querySelector('.template-main-container #left') as HTMLDivElement;
    const scrollRightButton = document.querySelector(
        '.template-main-container #right'
    ) as HTMLDivElement;

    if (tableContainer && scrollLeftButton && scrollRightButton) {
      let scrollInterval: NodeJS.Timeout;

      const startScrollLeft = () => {
        scrollInterval = setInterval(() => {
          tableContainer.scrollLeft -= 20;
        }, 20);
      };

      const startScrollRight = () => {
        scrollInterval = setInterval(() => {
          tableContainer.scrollLeft += 20;
        }, 20);
      };

      const stopScroll = () => {
        clearInterval(scrollInterval);
      };

      scrollLeftButton.addEventListener('mouseenter', startScrollLeft);
      scrollRightButton.addEventListener('mouseenter', startScrollRight);
      scrollLeftButton.addEventListener('mouseleave', stopScroll);
      scrollRightButton.addEventListener('mouseleave', stopScroll);
      window.addEventListener('resize', handleHorizontalScroll);

      // Initial check for horizontal scroll
      handleHorizontalScroll();

      return () => {
        window.removeEventListener('resize', handleHorizontalScroll);
        scrollLeftButton.removeEventListener('mouseenter', startScrollLeft);
        scrollRightButton.removeEventListener('mouseenter', startScrollRight);
        scrollLeftButton.removeEventListener('mouseleave', stopScroll);
        scrollRightButton.removeEventListener('mouseleave', stopScroll);
      };
    }
  }, [columns, mouseMove, mouseUp]);

  useEffect(() => {
    const table = tableElementTemplate.current;
    if (table) {
      updateArrowsVisibility(table);
      table.addEventListener("scroll", () => {
        updateArrowsVisibility(table);
      });
    }
  }, [tableElementTemplate.current, mouseUp]);

  useEffect(() => {
    if (tableElementTemplate.current) {
      const computedStyle = window.getComputedStyle(tableElementTemplate.current);
      const gridTemplateColumns = computedStyle.getPropertyValue('grid-template-columns');
      const widths = gridTemplateColumns.split(' ').map(width => parseFloat(width));
      const sumOfWidths = widths.reduce((total, width) => total + width, 0);
      setTableWidth(sumOfWidths);
    }
  }, [tableElementTemplate.current, mouseUp]);

  // @ts-ignore
  return <div className={styles.tableContainer}>
    <div
        className={
          hasScroll && showLeftArrow && tableData?.length  ? styles.leftContainer : styles.hideLeftContainer
        }
        id="left"
    >
      <LeftArrow/>
    </div>
    <div
        className={
          hasScroll && showRightArrow && tableData?.length ? styles.rightContainer : styles.hideRightContainer
        }
        id="right"

        style={{right: distancefromRight}}
    >
      <RightArrow/>
    </div>
    <table
        id="templatesTable"
        className="resizable-table"
        ref={tableElementTemplate}
    >
      {autoSaveLoader && <div style={{
        position: 'absolute',
        width: tableWidth,
        backgroundColor: '#e0e0e0',
        height: '100%',
        top: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex:'9',
        opacity:'0.6'
      }}>
        <MoonLoader color="#1e3166" size={35} loading={true}/>
      </div>}
      <thead>
      <tr>
        <th
         className="sticky-element"
         style={{
           position: 'sticky',
           top: '0',
           background: '#F4F7FF',
         }}
        >
          <input
           type="checkbox"
           className={`${styles.myCheckbox} ${styles.defaultstickyCheckbox} ${selectedListings.length ? styles.stickyCheckbox : ''}`}
           name="allSelect"
           style={{
             visibility: hasSimple ? 'hidden' : 'visible',
           }}
           onChange={handleChangeCheckbox}
           checked={
            tableData?.length > 0 &&
            tableData?.filter(
             (tbData :any) => tbData?.isChecked !== true
            )?.length < 1
           }
          />
        </th>
        {columns?.map((col: any, i: any) => (
         <TableResizeColumn
          handleChangeDirection={handleChangeDirection}
          directions={directions}
          handleSearch={handleSearch}
          searchResult={searchResult}
          key={col.value}
          col={col}
          i={i}
          mouseDown={mouseDown}
          id={col.value}
          innerRef={col.ref}
          activeIndex={activeIndex}
         />
        ))}
      </tr>
      </thead>
      <tbody>
      {loader ? (
       <div className={styles.loaderTableCatalogOuter} >
         <div className={styles.loaderTableCatalog} style={{width:tableWidth}}>
           <MoonLoader color="#1e3166" size={35} loading={true}/>
         </div>
       </div>
      ) : tableData?.map((row: any, index: any) => (
       <TableRow
        setFormChanged={setFormChanged}
        defaultTemplateId={defaultTemplateId}
        setDefaultTemplateId={setDefaultTemplateId}
        setSaveActive={setSaveActive}
        handleBulkDelete={handleBulkDelete}
        setTableData={setTableData}
        listingsForDelete={listingsForDelete}
        handleChangeCheckbox={handleChangeCheckbox}
        key={row?.id}
        handleBulkRestore={handleBulkRestore}
        handleRowSelected={(e :any) =>
         handleRowSelected(e, row.id.toString(), row.isChecked)
        }
        selectedListings={selectedListings}
        tableData={tableData}
        row={row}
        columns={columns}
        index={index}
        resizableElementWidth={resizableElementWidth}
        isBulkEdit={isBulkEdit}
       />
      ))}
      </tbody>
      {(tableData?.length === 0 && !loader) && (
       <div
        style={{padding: '33px', width: tableWidth, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
         <p style={{fontWeight: 600, fontSize: 20}}>
           This search returned no results.
         </p>
       </div>
      )}
    </table>
  </div>
}

export default TemplatesTable;