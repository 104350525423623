import React, {
	useState,
	useEffect,
	useRef,
	useMemo,
	useCallback,
	useLayoutEffect,
} from 'react';

import jwt_decode from 'jwt-decode';
import {debounce} from 'lodash';
import _get from 'lodash/get';
import {Helmet} from "react-helmet";

import styles from './CatalogPage.module.scss';
import {toast} from 'react-toastify'; // then this

import Table from './components/Table';
import TablePagination from './components/Table/components/TablePagination';
import Search from './components/Search';
import TableSettings from './components/Table/components/TableSettings';
import {CustomizeIcon, GridView, MarketIcon} from '../../assets/icons';
import {TableView} from '../../assets/icons';
import {ListIcon} from '../../assets/icons';
import {getAllDataByPayload} from '../../services/listings/downloaderCsv';
import './CatalogPage.css';
import './Sync.css';
import {SwitchToListViewIcon} from '../../assets/icons';
import {
	CsvNavigationSVG,
	SVGIssuesIcon,
	SVGStatusIcon,
} from '../../assets/icons';

import {simpleBusinessModeTabs, tabs} from './data/tabs';

import {
	IFilterBy,
	IMetadata,
	IOptions,
	ITableData,
	ICatalogColumn,
	ITab,
	IPayload,
	ISortBy,
	ITabCounts2,
} from '../../interfaces';

import {instanceAxios} from '../../services';
import {formatWord} from '../../utils/formatWord';
import Loader from '../../components/Templates/Loader';

import PerPageSelect from '../shared/PerPageSelect';

import {MoonLoader} from 'react-spinners';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {
	setIsMobile,
	setRemoveQuestionModal,
	setReplaceCandidat,
	setResetCandidatLink,
	setResetPureCandidantLink,
	setToggleEditModal,
	setShowRedirectModal,
	iframeLoaderChanger,
	setRemoveShowIframe,
	setIframeLink,
	setSecureModalWhenChangePage,
	setShowGridMessage,
	setShowTabsMessage,
	setActiveSaveRetain,
	setShowIframe,
	setIframeName, setRemoveActionFromURL,
} from '../../redux/slices/NavigationSlice';
import {useLocation, useNavigate} from 'react-router-dom';
import getToken from '../../utils/getToken';
import {platforms} from './components/Table/components/ColumnSearch/data/platforms';
import NotificationsModal from '../../components/UI/NotificationsModal';
import RedirectModal from '../../components/UI/RedirectModal';
import MobileSlider from './components/MobileSlider';
import Select from 'react-select';
import _ from 'lodash';
import Iframe from '../../components/UI/Iframe';

import useDebounce from '../../hooks/UseDebounce';

import {transformCsvDataForListings} from '../../utils/transformCsvDataForListings';
import Tippy from '@tippyjs/react';
import {setRoles} from '../../redux/slices/RoleSlice';
import ToTopBtn from '../../components/UI/ToTopBtn';

import MobileSearch from './components/MobileSearch';
import SearchResult from './components/SearchResult';
import {
	setFilterBySort,
	setGridViewChecker,
	setIsCancel,
	setIsInputChanged,
	setIsSave
} from "../../redux/slices/CatalogSlice";
import PaginateMessageModal from "../../components/UI/PaginateMessageModal";
import EditModal from "../../components/EditModal";

import ConnectedMarketplacesModal from '../../components/ConnectedMarketplacesModal';
import ListingViewModal from "../../components/UI/ListingViewModal";
import CreateTemplate from "../../components/CreateTemplate";
interface NavigationState {
	active: boolean;
	editModalShow: boolean;
	showAddlistingIframe: boolean;
	showAddTemplateIframe: boolean;
	showAddlistingIframeQuickLink: boolean;
	zIndexGenerator: number[];
	isBulkEdit: boolean;
	saved: boolean;
	zIndex: number;
	showIframe: boolean;
	iframeLink: string;
	iframeLoad: boolean;
	showModalQuestion: boolean;
	candidatIframeLink: string;
	showNotificationModal: boolean;
	showRedirectModal: boolean;
	isMobile: boolean;
	showSecureModalWhenChangePage: boolean;
}

interface RootState {
	navigation: NavigationState;
}

// left while not tested in prod
// useLayoutEffect(() => {
// 	const cols = localStorage.getItem('cols');
// 	const savedGridTemplateColumns = localStorage.getItem('size');
//
// 	if (cols && savedGridTemplateColumns) {
// 		try {
// 			const parsedCols = JSON.parse(cols);
// 			const gridTemplate = JSON.parse(savedGridTemplateColumns);
//
// 			// Filter out the "default" heading from the gridTemplate
// 			const filteredGridTemplate = gridTemplate.filter(
// 			 (item: any) => item.name !== 'default'
// 			);
//
// 			// Extract the heading names from cols and filteredGridTemplate
// 			const colHeadings = parsedCols.map((col: any) =>
// 			 col.heading.toLowerCase()
// 			);
// 			const gridTemplateHeadings = filteredGridTemplate.map((item: any) =>
// 			 item.name.toLowerCase()
// 			);
//
// 			// Sort both arrays to ensure order doesn't affect the comparison
// 			colHeadings.sort();
// 			gridTemplateHeadings.sort();
//
// 			// Compare the two arrays to check if they have the same headings
// 			if (
// 			 JSON.stringify(colHeadings) !== JSON.stringify(gridTemplateHeadings)
// 			) {
// 				localStorage.removeItem('cols');
// 				localStorage.removeItem('size');
// 				//  window.location.reload();
// 			}
// 		} catch (error) {
// 			console.log('Error:', error);
// 		}
// 	}
// }, []);

const initialOptions = [
	{
		value: 'Empty IDs - Status selected, no ID saved',
		label: 'Empty IDs - Status selected, no ID saved',
		placeholder: 'issues_empty_ids',
	},
	{
		value: 'Marketplace status selected, no ACTIVE listing found',
		label: 'Marketplace status selected, no ACTIVE listing found',
		placeholder: 'issues_status_selected_no_active_listing_found',
	},
	{
		value: 'Marketplace status NOT selected, ACTIVE listing found',
		label: 'Marketplace status NOT selected, ACTIVE listing found',
		placeholder: 'issues_status_not_selected_active_listing_found',
	},
	{
		value: 'Sold status selected, ACTIVE listing found',
		label: 'Sold status selected, ACTIVE listing found',
		placeholder: 'issues_sold_status_active_listing_found',
	},
	{
		value: 'Marketplace status selected, NO listing found',
		label: 'Unable to check status OR listing not found',
		placeholder: 'issues_status_selected_no_listing_found',
	},
];

const initialOptionsForSimpleBusinessPro = [
	{
		value: 'Empty IDs - Status selected, no ID saved',
		label: 'Empty IDs - Status selected, no ID saved',
		placeholder: 'issues_empty_ids',
	},
];

const CatalogPage = () => {
	const [syncMessage, setSyncMessage] = useState('');
	const location = useLocation();
	const [bodyAtribute, setBodyAtribute] = useState(false);
	const queryParams = new URLSearchParams(location.search);
	const [connectedMarketplacesModal, setConnectedMarketplacesModal] = useState(false);
	const [getMarketsFromExtension, setGetMarketsFromExtension] = useState<any>(null);
	const [includesError, setIncludesError] = useState(false);
	const navigate = useNavigate();
	const isMobile = useSelector((state: RootState) => state.navigation.isMobile);
	const [hasMore, setHasMore] = useState(true);
	const [syncTime, setSyncTime] = useState(null);
	const [syncContent, setSyncContent] = useState(true);
	const draftRef = useRef();
	const issueRef = useRef();
	const cogsRef = useRef();
	const feesRef = useRef();
	const dateSoldRef = useRef();
	const soldPriceRef = useRef();
	const shippingCost = useRef();
	const profitRef = useRef();
	const [selectedListings, setSelectedListings] = useState<any>([]);
	const [selectedListingsData, setSelectedListingsData] = useState<any>([]);
	const iframeRef = useRef<HTMLIFrameElement | null>(null);
	const [updator, setUpdator] = useState(false);
	const [retryActive, setRetryActive] = useState(false);
	const [saveActive, setSaveActive] = useState(false);
	const editModalShow = useSelector(
	 (state: RootState) => state.navigation.editModalShow
	);
	const [listingsForDelete, setListingsForDelete] = useState<any>([])
	const showExpressModal = useSelector((state: any) => state.navigation.expressLister);
	const showSecureModal = useSelector(
	 (state: RootState) => state.navigation.showSecureModalWhenChangePage
	);
	const removeActionFromURL = useSelector(
	 (state: any) => state.navigation.removeActionFromURL
	);
	const showListingViewModal = useSelector((state: any) => state.navigation.listingViewModalShow);
	const [iframeUpdate, setIframeUpdate] = useState(false)
	const showIframe = useSelector(
	 (state: RootState) => state.navigation.showIframe
	);
	const [isLoadedIframe, setIsLoadedIframe] = useState(false);
	const [isReloadIframe, setIsReloadIframe] = useState(false);
	// save for edit modal
	// const showEditPageModal = useSelector((state: any) => state.navigation.editModalActive);
	const showModalQuestion = useSelector(
	 (state: RootState) => state.navigation.showModalQuestion
	);
	const filterSortChange = useSelector(
	 (state: any) => state.catalog.filterSortChange
	);
	// const saved = useSelector((state: RootState) => state.navigation.saved);

	const showRedirectModal = useSelector(
	 (state: RootState) => state.navigation.showRedirectModal
	);
	const showEditTemplateModal = useSelector((state: any) => state.navigation.showEditTemplateModal);
	const token: any = getToken();

	const candidatIframeLink = useSelector(
	 (state: RootState) => state.navigation.candidatIframeLink
	);
	const createdTippy = useRef(null);
	const platformsTippy = useRef(null);
	const decodedToken: any = jwt_decode(token);
	const roles = decodedToken?.data?.user?.roles;
	const showSync = roles.includes('Corporate Master');

	const is_role_allowed = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'corporate master' ||
		 role.toLowerCase() === 'corporate sub'
		);
	});

	const hasTicket = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'tickets'
		);
	});
	const hasBusiness = roles
	.map((role: string) => role.toLowerCase())
	.includes('Business'.toLowerCase());
	const hasSimple = roles
	.map((role: string) => role.toLowerCase())
	.includes('simple'.toLowerCase());
	const hasPro = roles
	.map((role: string) => role.toLowerCase())
	.includes('pro'.toLowerCase());
	const hasBetaTeamRole = roles.some((role: any) => {
		return (
		 role.toLowerCase() === 'beta team'
		);
	});
	const dispatch = useDispatch();
	const propertiesToRemove = [
		'active',
		'unlisted',
		'sold',
		'issues_empty_ids',
		'draft',
		'out_of_stock',
		'issues_status_selected_no_active_listing_found',
		'issues_status_not_selected_active_listing_found',
		'issues_sold_status_active_listing_found',
		'issues_status_selected_no_listing_found',
	];
	const [completedRequestsCount, setCompletedRequestsCount] = useState(0);
	const imageRef = useRef();
	const titleRef = useRef();
	const actionsRef = useRef();
	const skuRef = useRef();
	const priceRef = useRef();
	const platformsRef = useRef();
	const quantityRef = useRef();
	const salesRef = useRef();
	const updatedRef = useRef();
	const createdRef = useRef();
	const notesRef = useRef();
	const [columns, setColumns] = useState<ICatalogColumn[]>(
	 hasSimple
	  ? [
		  {
			  heading: 'Image',
			  value: 'image',
			  width: 110,
			  order: 1,
			  ref: imageRef,
		  },
		  {
			  heading: 'Platforms',
			  value: 'platforms',
			  width: 120,
			  order: 6,
			  ref: platformsRef,
		  },
		  {
			  heading: 'Title',
			  value: 'title',
			  width: 240,
			  order: 2,
			  ref: titleRef,
		  },
		  {
			  heading: 'Updated',
			  value: 'updated',
			  width: 130,
			  order: 10,
			  ref: updatedRef,
		  },
		  {
			  heading: 'Actions',
			  value: 'actions',
			  width: 140,
			  order: 3,
			  ref: actionsRef,
		  },
	  ]
	  : [
		  {
			  heading: 'Image',
			  value: 'image',
			  width: 110,
			  order: 1,
			  ref: imageRef,
		  },
		  {
			  heading: 'Title',
			  value: 'title',
			  width: 240,
			  order: 2,
			  ref: titleRef,
		  },
		  {
			  heading: 'Actions',
			  value: 'actions',
			  width: 140,
			  order: 3,
			  ref: actionsRef,
		  },
		  // ... other columns
		  {
			  heading: 'SKU',
			  value: 'SKU',
			  width: 240,
			  order: 4,
			  ref: skuRef,
		  },
		  {
			  heading: 'Price',
			  value: 'price',
			  width: 140,
			  order: 5,
			  ref: priceRef,
		  },
		  {
			  heading: 'Platforms',
			  value: 'platforms',
			  width: 120,
			  order: 6,
			  ref: platformsRef,
		  },
		  {
			  heading: 'Quantity',
			  value: 'quantity',
			  width: 90,
			  order: 9,
			  ref: quantityRef,
		  },
		  {
			  heading: 'Updated',
			  value: 'updated',
			  width: 130,
			  order: 10,
			  ref: updatedRef,
		  },
		  {
			  heading: 'Created',
			  value: 'created',
			  width: 130,
			  order: 11,
			  ref: createdRef,
		  },
		  {
			  heading: 'Notes',
			  value: 'notes',
			  width: 140,
			  order: 12,
			  ref: notesRef,
		  },
	  ]
	);

	const [tableData, setTableData] = useState<ITableData[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [metadata, setMetadata] = useState<IMetadata>({
		offset: 0,
		limit: Number(localStorage.getItem('CatalogPerPage')) || 10,
	});
	const filterByParam = queryParams.get('filter_by');
	const actionInUrl = queryParams.get('action');

	const [checkbox1Checked, setCheckbox1Checked] = useState(
	 localStorage.getItem('checkbox1Checked') === 'false' ? true : false
	);
	const [checkbox2Checked, setCheckbox2Checked] = useState(
	 localStorage.getItem('checkbox2Checked') === 'false' ? true : false
	);

	const getTab = queryParams.get('tab');

	let activeTabName = '';
	if (getTab) {
		activeTabName = getTab;
	} else {
		activeTabName = 'view all';
	}
	const [tab, setTab] = useState<string>(activeTabName);

	let decodedFilterBy;
	if (filterByParam) {
		decodedFilterBy = JSON.parse(decodeURIComponent(filterByParam));
		if (tab === 'view all') {
			if (checkbox1Checked) {
				decodedFilterBy.draft = false;
			} else {
				delete decodedFilterBy.draft;
			}
			if (checkbox2Checked) {
				decodedFilterBy.sold = false;
			} else {
				delete decodedFilterBy.sold;
			}
		}
	}

	const [filterBy, setFilterBy] = useState<IFilterBy>(
	 decodedFilterBy ? decodedFilterBy : {}
	);

	const filterSort = queryParams.get('sort_by');
	let decodedSort;
	if (filterSort) {
		decodedSort = JSON.parse(decodeURIComponent(filterSort));
	} else {
		decodedSort = {};
	}
	const [sortBy, setSortBy] = useState<ISortBy>(decodedSort);
	const finishedToGetCustomMarkets = useSelector((state: any) => state.customMarketplaces.finishedGetCustomMarkets)
	const [payload, setPayload] = useState<IPayload>({payload: {'133': ''}});

	const localOption = is_role_allowed
	 ? {
		 include_thumbnail: true,
		 include_assignee: true,
	 }
	 : {include_thumbnail: true};

	const [options, setOptions] = useState<IOptions>(localOption);
	const selectorIssues =
	 (hasBusiness || hasSimple || hasPro) && !is_role_allowed
	  ? initialOptionsForSimpleBusinessPro
	  : initialOptions;
	const [updatedOptions, setUpdatedOptions] = useState(selectorIssues);
	const [pageCount, setPageCount] = useState<number>(0);

	const [hideElement, setHideElement] = useState(false);
	const [enabledScroll, setEnabledScroll] = useState(false);
	const [limit, setLimit] = useState<number>(10);
	const [total, setTotal] = useState<number | null>(null);

	const [tabLoading, setTabLoading] = useState<boolean>(false);

	// const [searchItem, setSearchItem] = useState<string>('');
	const [searchItem, setSearchItem] = useState<string>(filterBy && filterBy?.search_everything ? filterBy.search_everything : "");

	// const [pagiInputValue, setPagiInputValue] = useState<string | number>('1');
	const [pagiInputValue, setPagiInputValue] = useState<any>('1');

	const [isBulkEdit, setIsBulkEdit] = useState<boolean>(false);

	const [csvData, setCsvData] = useState<ITableData[]>([]);

	const [csvLoading, setCsvLoading] = useState<boolean>(false);

	const [bulkDeleteConfirmModalShow, setBulkDeleteConfirmModalShow] =
	 useState<boolean>(false);

	const [loader, setLoader] = useState<boolean>(false);
	const [platformsModalShow, setPlatformsModalShow] = useState(false);
	const [currentPaginateNumber, setCurrentPaginateNumber] = useState<number>(1);
	const [cachedTableData, setCachedTableData] = useState<any>(null);
    const [hasExtensionSupport, setHasExtensionSupport] = useState(false);
	const [hasExtensionInstall, setHasExtensionInstall] = useState(false);
	const globalCustomMarketplaces = useSelector((state: any) => state.customMarketplaces.customMarketplaces)
	const isCanceled = useSelector((state: any) => state.catalog.isCancel);
	const isSaved = useSelector((state: any) => state.catalog.isSave);
	const isInputChanged = useSelector((state: any) => state.catalog.inputChanged);
	const customStyles = {
		// Style the input element
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: '#fff',
			color: '#1E3166',
			borderColor: '#D5D5D5',
			padding: 1,
			borderRadius: screenWidth > 1440 ? '4px' : '3px',
			minWidth: '88px',
			cursor: 'pointer',
			width: screenWidth > 1440 ? '200px' : '160px',
			minHeight: screenWidth > 1440 ? '39px' : '32px',
			height: screenWidth > 1440 ? '39px' : '32px',
		}),
		valueContainer: (provided: any) => ({
			...provided,
			height: screenWidth > 1440 ? '39px' : '32px',
			padding: screenWidth > 1440 ? '0 6px' : '0 5px',
		}),

		input: (provided: any) => ({
			...provided,
			margin: '0px',
		}),
		indicatorSeparator: (state:any) => ({
			display: 'none',
		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			height: screenWidth > 1440 ? '39px' : '32px',
		}),
		menu: (provided: any, state: any) => ({
			...provided,
			width: '100%',
			minWidth: '88px',
			border: '1px solid #1E3166',
			zIndex: 99,
		}),

	};

	useEffect(() => {
		if (actionInUrl) {
			dispatch(setShowIframe());
			dispatch(setIframeName('add listing'));
			dispatch(iframeLoaderChanger(true));
			dispatch(
			 setIframeLink('listperfectly.com/listings/add')
			);
		}

	}, [])

	useEffect(() => {
		if (tab === 'view all') {
			if (checkbox1Checked) {
				setFilterBy({...filterBy, draft: false});
			} else {
				const updatedFilterBy = {...filterBy};
				delete updatedFilterBy.draft;
				setFilterBy(updatedFilterBy);
			}
			if (checkbox2Checked) {
				setFilterBy({...filterBy, sold: false});
			} else {
				const updatedFilterBy = {...filterBy};
				delete updatedFilterBy.sold;
				setFilterBy(updatedFilterBy);
			}
		}
	}, [checkbox1Checked, setCheckbox2Checked]);


	const handleCheckboxChange = (checkboxName: string) => {
		if (checkboxName === 'checkbox1') {
			if (!checkbox1Checked) {
				setFilterBy({...filterBy, draft: false});
			} else {
				const updatedFilterBy = {...filterBy};
				delete updatedFilterBy.draft;
				setFilterBy(updatedFilterBy);
			}
			localStorage.setItem('checkbox1Checked', String(checkbox1Checked));
			setCheckbox1Checked(!checkbox1Checked);
		} else if (checkboxName === 'checkbox2') {
			if (!checkbox2Checked) {
				setFilterBy({...filterBy, sold: false});
			} else {
				const updatedFilterBy = {...filterBy};
				delete updatedFilterBy.sold;
				setFilterBy(updatedFilterBy);
			}
			localStorage.setItem('checkbox2Checked', String(checkbox2Checked));
			setCheckbox2Checked(!checkbox2Checked);
		}
	};

	const [tabCounts2, setTabCounts2] = useState<ITabCounts2>({
		all: {
			count: 0,
			loader: true,
		},
		active: {
			count: 0,
			loader: true,
		},
		draft: {
			count: 0,
			loader: true,
		},
		unlisted: {
			count: 0,
			loader: true,
		},
		out_of_stock: {
			count: 0,
			loader: true,
		},
		sold: {
			count: 0,
			loader: true,
		},
		deleted: {
			count: 0,
			loader: true,
		},
	});

	const [inputValues, setInputValues] = useState<
	 Record<string, string | number>
	>({});

	let initialGridView = false;

	try {
		const storedGridView = localStorage.getItem('gridView');
		if (storedGridView !== null) {
			initialGridView = JSON.parse(storedGridView);
		}
	} catch (error) {
		console.error('error', error);
	}

	const [gridView, setGridView] = useState(initialGridView);

	const [lpPlatforms, setLpPlatforms] = useState<any>(platforms);

	const [activePlatforms, setActivePlatforms] = useState<string[]>([]);
	const [customMarketplaces, setCustomMarketplaces] = useState<any>([]);
	const [customsDone, setCustomsDone] = useState(true);
	const [platformChangesMade, setPlatformChangesMade] = useState(false);
	const [filterByArr, setFilterByArr] = useState<string[]>([]);
	const [activeTab, setActiveTab] = useState(activeTabName);
	const storedValue = localStorage.getItem('selectedIssue');
	let optionParsed;
	if (storedValue) {
		try {
			optionParsed = JSON.parse(storedValue);
			optionParsed.label = optionParsed?.label?.replace(/\(\d+\)/g, '').trim();
		} catch (e) {
			console.log('error');
		}
	}
	const [issuesValue, setIssuesValue] = useState(
	 optionParsed
	  ? optionParsed
	  : {
		  value: 'Empty IDs - Status selected, no ID saved',
		  label: 'Empty IDs - Status selected, no ID saved',
		  placeholder: 'issues_empty_ids',
	  }
	);
	const screenWidth = window.innerWidth;

	useEffect(() => {
		setCustomMarketplaces(globalCustomMarketplaces)
	}, [globalCustomMarketplaces])


	useEffect(() => {
		localStorage.setItem('selectedIssue', JSON.stringify(issuesValue));
	}, [issuesValue]);

	const [allTableData, setAllTableData] = useState<any>({
		active: {
			data: [],
			pageCount: null,
		},
		draft: {
			data: [],
			pageCount: null,
		},
		unlisted: {
			data: [],
			pageCount: null,
		},
		out_of_stock: {
			data: [],
			pageCount: null,
		},
		sold: {
			data: [],
			pageCount: null,
		},
		all: {
			data: [],
			pageCount: null,
		},
		issues: {
			data: [],
			pageCount: null,
		},
	});

	const csvDownloadRef = useRef<HTMLDivElement | null>(null);
	const [avoidIssuesRequests, setAvoidIssuesRequests] = useState(false);
	const sendMessageToIframe = () => {
		const iframe: any = document.getElementById('productIframe');

		iframe.contentWindow.postMessage(
		 {target: 'listing-assistant', data: 'show'},
		 '*'
		);
	};


	useEffect(() => {
			try {
				const queryParams = new URLSearchParams(location.search);
				const filterByParam = queryParams.get('filter_by');
				if (filterByParam) {
					decodedFilterBy = JSON.parse(decodeURIComponent(filterByParam));
					const platformNamesActive: string[] = [];
					const platformNamesNotActive: string[] = [];
					const platformNamesActiveNames: string[] = [];
					if(decodedFilterBy && decodedFilterBy?.platforms) {
						decodedFilterBy?.platforms.forEach((platform: string)=>{
							platformNamesActive.push(platform)
						})
					}
					if(decodedFilterBy && decodedFilterBy?.not_platforms) {
						decodedFilterBy?.not_platforms.forEach((platform: string)=>{
							platformNamesNotActive.push(platform)
						})
					}
					const updatedPlatforms = lpPlatforms?.map((item: any) => {
						if (platformNamesActive.includes(item.platform.code[0])) {
							return {
								...item,
								platform: {
									...item.platform,
									isChecked: true,
								},
								notOnPlatform: {
									...item.notOnPlatform,
									isChecked: false,
								},
							};
						}
						else if (platformNamesNotActive.includes(item.platform.code[0])) {
							return {
								...item,
								platform: {
									...item.platform,
									isChecked: false,
								},
								notOnPlatform: {
									...item.notOnPlatform,
									isChecked: true,
								},
							};
						}
						else {
							return {
								...item,
								platform: {
									...item.platform,
									isChecked: false,
								},
								notOnPlatform: {
									...item.notOnPlatform,
									isChecked: false,
								},
							};
						}
					});
					setLpPlatforms(updatedPlatforms);
					 updatedPlatforms.forEach((obj:any)=>{
						if (obj.platform && obj.platform.isChecked) {
							platformNamesActiveNames.push(obj.platform.title);
						}

						// Check if notOnPlatform object exists and its isChecked property is true
						if (obj.notOnPlatform && obj.notOnPlatform.isChecked) {
							platformNamesActiveNames.push(obj.notOnPlatform.title);
						}
					})
					setActivePlatforms(platformNamesActiveNames);
				}
			} catch (error) {
				console.error('Error parsing "filter_by" parameter:', error);
			}
	}, []);

	useEffect(() => {
		if(finishedToGetCustomMarkets && customsDone && customMarketplaces.length){
			try {
				setCustomsDone(false);
				const queryParams = new URLSearchParams(location.search);
				const filterByParam = queryParams.get('filter_by');
				if (filterByParam) {
					decodedFilterBy = JSON.parse(decodeURIComponent(filterByParam));
					const platformNamesActive: string[] = [];
					const platformNamesNotActive: string[] = [];
					const platformNamesActiveNames: string[] = [];
					if(decodedFilterBy && decodedFilterBy?.custom_marketplaces) {
						decodedFilterBy?.custom_marketplaces.forEach((platform: string)=> {
							platformNamesActive.push(platform)
						})
					}
					if(decodedFilterBy && decodedFilterBy?.not_custom_marketplaces) {
						decodedFilterBy?.not_custom_marketplaces.forEach((platform: string)=> {
							platformNamesNotActive.push(platform)
						})
					}
					const updatedPlatforms = customMarketplaces?.map((item: any) => {
						if (platformNamesActive.includes(item.platform.code[0])) {
							return {
								...item,
								platform: {
									...item.platform,
									isChecked: true,
								},
								notOnPlatform: {
									...item.notOnPlatform,
									isChecked: false,
								},
							};
						}
						else if (platformNamesNotActive.includes(item.platform.code[0])) {
							return {
								...item,
								platform: {
									...item.platform,
									isChecked: false,
								},
								notOnPlatform: {
									...item.notOnPlatform,
									isChecked: true,
								},
							};
						}
						else {
							return {
								...item,
								platform: {
									...item.platform,
									isChecked: false,
								},
								notOnPlatform: {
									...item.notOnPlatform,
									isChecked: false,
								},
							};
						}
					});
					setCustomMarketplaces(updatedPlatforms);

					updatedPlatforms.forEach((obj:any)=> {
						if (obj.platform && obj.platform.isChecked) {
							platformNamesActiveNames.push(obj.platform.title);
						}

						// Check if notOnPlatform object exists and its isChecked property is true
						if (obj.notOnPlatform && obj.notOnPlatform.isChecked) {
							platformNamesActiveNames.push(obj.notOnPlatform.title);
						}
					})
					setActivePlatforms((prevActivePlatforms) => {
						// Combine the previous active platforms with the new ones
						const updatedPlatforms = [...prevActivePlatforms, ...platformNamesActiveNames];
						// Optionally, remove duplicates if necessary
						const uniquePlatforms = [...new Set(updatedPlatforms)];
						return uniquePlatforms;
					});
				}
			} catch (error) {
				console.error('Error parsing "filter_by" parameter:', error);
			}
		}
	},[finishedToGetCustomMarkets, customsDone, customMarketplaces])

	const getData = async () => {
		let localFilterBy: any = filterBy;

		setLoader(true);
		localStorage.removeItem('currentPageNumber');
		if (activeTabName === 'issues') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			issuesChecker(localFilterBy);
		} else if (activeTabName === 'view all') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			if (checkbox1Checked) localFilterBy['draft'] = false;
			if (checkbox2Checked) localFilterBy['sold'] = false;
		} else if (activeTabName === 'active') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['active'] = true;
		} else if (activeTabName === 'draft') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['draft'] = true;
		} else if (activeTabName === 'sold') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['sold'] = true;
		} else if (activeTabName === 'sold') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['sold'] = true;
		} else if (activeTabName === 'unlisted') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['unlisted'] = true;
		} else if (activeTabName === 'out of stock') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['out_of_stock'] = true;
		}
		const tempMetadata = {offset: 0, limit: metadata.limit};

		try {
			if (searchItem.length <= 30) {
				const response = await instanceAxios.post('/listings/list', {
					metadata: tempMetadata,
					filter_by: localFilterBy,
					...(Object.values(sortBy).length > 0 &&
					 !Object.values(sortBy).includes('none') && {sort_by: sortBy}),
					options,
				});

				if (response.data) {
					setIsLoading(false);
					setLoader(false);

					setPageCount(
					 Math.ceil(
					  response.data.metadata.total /
					  Number(localStorage.getItem('CatalogPerPage') || limit)
					 )
					);
					if (tab === 'view all') {
						setTabCounts2((prevTabCounts2: any) => ({
							...prevTabCounts2,
							['view all']: {
								count: Math.ceil(response.data.metadata.total),
								loader: false,
								tabName: 'view all',
							},
						}));

						setAllTableData((prevTableData: any) => ({
							...prevTableData,

							['all']: {
								data: response.data.data,
								pageCount: Math.ceil(
								 response.data.metadata.total / (metadata.limit || limit)
								),
							},
						}));
					}
					if (tab === 'issues') {
						setTabCounts2((prevTabCounts2: any) => ({
							...prevTabCounts2,
							['issues']: {
								count: Math.ceil(response.data.metadata.total),
								loader: false,
								tabName: 'issues',
							},
						}));

						setAllTableData((prevTableData: any) => ({
							...prevTableData,

							['issues']: {
								data: response.data.data,
								pageCount: Math.ceil(
								 response.data.metadata.total / (metadata.limit || limit)
								),
							},
						}));
					}

					setTotal(response.data.metadata.total);
					setTableData(response.data.data);
				}
			} else {
				setIsLoading(false);
				setLoader(false);

			}
		} catch (error: any) {
			if (error?.response?.data?.name === 'UnauthorizedError') {
				window.location.href = 'https://listperfectly.com/';
			}
		}
	};

	useEffect(() => {
		try {
			// Step 1: Retrieve selectedIssue from localStorage
			const selectedIssueString = localStorage.getItem('selectedIssue');

			// Check if selectedIssueString is not null or undefined
			if (selectedIssueString) {
				// Step 2: Parse the JSON string
				const selectedIssue = JSON.stringify(selectedIssueString);

				if (!selectedIssue.includes('placeholder')) {
					localStorage.removeItem('selectedIssue');
				}
			}
		} catch (error) {
			console.error('Error handling localStorage:', error);
		}
	}, []);

	useEffect(() => {
		if (tab === 'issues' && !avoidIssuesRequests) {
			try {
				setAvoidIssuesRequests(true);
				const filters =
				 (hasSimple || hasPro || hasBusiness) && !is_role_allowed
				  ? [{issues_empty_ids: true}]
				  : [
					  {issues_empty_ids: true},
					  {issues_status_selected_no_active_listing_found: true},
					  {issues_status_not_selected_active_listing_found: true},
					  {issues_sold_status_active_listing_found: true},
					  {issues_status_selected_no_listing_found: true},
				  ];

				const requests = filters.map((filter) =>
				 instanceAxios.post('/listings/list', {
					 metadata,
					 filter_by: filter,
					 options,
				 })
				);

				Promise.all(requests)
				.then((responses: any) => {
					// Calculate totals from responses
					const totals = responses.map(
					 (response: any) => response.data.metadata.total
					);
					totals.map((total: any) =>
					 setCompletedRequestsCount((prevCount) => prevCount + total)
					);

					// Update the options with the totals
					const updatedOptions = initialOptions.map((option, index) => ({
						...option,
						label: `(${totals[index]}) ${option.label} `,
					}));

					responses.map((response: any) => {
						if (
						 Object.keys(response?.data?.filter_by)[0] ===
						 issuesValue?.placeholder
						) {
							setAllTableData((prevTableData: any) => ({
								...prevTableData,
								...(tab === 'issues' && {
									['issues']: {
										data: response.data.data,
										pageCount: Math.ceil(
										 response?.data?.metadata?.total /
										 (metadata.limit || limit)
										),
									},
								}),
							}));
							setTableData(response.data.data);
						}
					});

					if ((hasBusiness || hasSimple || hasPro) && !is_role_allowed) {
						setUpdatedOptions(updatedOptions.splice(0, 1));
					} else {
						setUpdatedOptions(updatedOptions);
					}
				})
				.catch((error) => {
					console.error('Error fetching data:', error);
				});
			} catch (error) {
				console.error('Promise.all error:', error);
			}
		}
	}, [tab]);

	const fetchMoreData = async () => {
		try {
			const response = await instanceAxios.post('/listings/list', {
				metadata: {
					offset: +currentPaginateNumber * 10,
					limit: 10,
				},
				filter_by: filterBy, // You may need to update this based on the next page's filter
				options,
			});

			if (response.data) {
				const newData = response.data.data;

				// If newData is empty, there's no more data to load
				if (newData.length === 0) {
					setHasMore(false);
				} else {
					// Update the current page number in localStorage
					// Append the new data to the existing data
					setCurrentPaginateNumber(currentPaginateNumber + 1);
					setTableData((prevData) => [...prevData, ...newData]);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleStartSync = async () => {
		try {
			// Send a request to the server using Axios with async/await
			const response = await instanceAxios.post(
			 'marketplaces/listings/sync/list'
			);
			// Assuming the response data is in the expected format
			const data = response.data.data;
			if (data[0]?.updated_at) {
				const time = data[0]?.updated_at;
				const options: any = {
					weekday: 'short',
					month: 'short',
					day: 'numeric',
					year: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
				};
				const inputDate = new Date(time);
				const newFormattedDate: any = inputDate.toLocaleString(
				 'en-US',
				 options
				);
				const formattedDateWithoutComma = newFormattedDate.replace(/,/g, '');
				const message = `The last issue finder was ${formattedDateWithoutComma}`;
				setSyncTime(formattedDateWithoutComma);
				setSyncMessage(message);
			}
		} catch (error: any) {
			// Handle error, update the syncMessage with the error message
			setSyncMessage(`Error: ${error.message}`);
		}
	};

	useEffect(() => {
		if (tab === 'issues') {
			const syncButton = document.getElementById('syncButton');

			if (syncButton) {
				setTimeout(() => {
					// Check if the button has content
					if (syncButton.innerHTML.trim() !== '') {
						if (syncContent) {
							setSyncContent(true);
						}
					} else {
						setSyncContent(false);
						handleStartSync();
					}
				}, 2000);
			}
		}
	}, [tab]);

	useEffect(() => {
		const handleSetExtensionVersion = (event: any) => {
			console.log(event, 'event');
		};

		window.addEventListener(
		 'LPCustomSetExtensionVersion',
		 handleSetExtensionVersion
		);

		return () => {
			window.removeEventListener(
			 'LPCustomSetExtensionVersion',
			 handleSetExtensionVersion
			);
		};
	}, []);
	const handleChangeCheckbox = useCallback(
	 (e: React.ChangeEvent<HTMLInputElement>) => {
		 const { name, checked } = e.target;
		 if (name === 'allSelect') {
			 let tempDatas = tableData.map((tbData) => {
				 return { ...tbData, isChecked: checked };
			 });
			 setTableData(tempDatas);
			 if (checked) {
				 const activeData = tempDatas.filter((tbData) => tbData.status === 'active');

				 setSelectedListings((prevSelected: any) => {
					 const updatedSet = new Set([...prevSelected, ...activeData.map(tbData => tbData.id)]);
					 return Array.from(updatedSet);
				 });

				 setSelectedListingsData((prevSelectedData: any) => {
					 const updatedData = [...prevSelectedData, ...activeData];
					 const dataMap = new Map(updatedData.map(data => [data.id, data]));
					 return Array.from(dataMap.values());
				 });
			 } else {
				 const idsToRemove: any = tempDatas.map((tbData) => tbData.id);

				 // setSelectedListings((prevSelected: any) => {
					//  return prevSelected.filter((id: any) => !idsToRemove.includes(id));
				 // });

				 // setSelectedListingsData((prevSelectedData: any) => {
					//  return prevSelectedData.filter((data: any) => !idsToRemove.includes(data.id));
				 // });
				 setSelectedListingsData([]);
				 setSelectedListings([]);


			 }
		 } else {

			 let tempDatas = tableData.map((tbData) =>
			  tbData.id == name ? { ...tbData, isChecked: checked } : tbData
			 );
			 setTableData(tempDatas);

			 const changedData = tempDatas.find((tbData) => tbData.id == name);
			 if (changedData) {
				 if (checked) {
					 setSelectedListings((prevSelected: any) => {
						 const updatedSet = new Set([...prevSelected, changedData.id]);
						 return Array.from(updatedSet);
					 });

					 setSelectedListingsData((prevSelectedData: any) => {
						 const updatedData = [...prevSelectedData, changedData];
						 const dataMap = new Map(updatedData.map(data => [data.id, data]));
						 return Array.from(dataMap.values());
					 });
				 } else {
					 setSelectedListings((prevSelected: any) => {
						 return prevSelected.filter((id: any) => id !== changedData.id);
					 });

					 setSelectedListingsData((prevSelectedData: any) => {
						 return prevSelectedData.filter((data: any) => data.id !== changedData.id);
					 });
				 }
			 }
		 }
	 },
	 [tableData, setTableData, setSelectedListings, setSelectedListingsData]
	);

    // need that code while extension will deployed
    const [AvoidInfiniteLoop, setAvoidInfiniteLoop] = useState(false);

	useEffect(() => {
		 if(tableData && !AvoidInfiniteLoop) {
			 const updatedTableData:any = tableData.map((item:any) => {

				 if (selectedListings.includes(item?.id)) {
					 let newItem = { ...item, isChecked: true };

					 if (listingsForDelete.includes(item?.id)) {
						 newItem = {...newItem, status: 'trash', isChecked: true};
					 }
					 return newItem;
				 }
				 else {
					 return item;
				 }
			 });
			 setAvoidInfiniteLoop(true);
			 setTableData(updatedTableData);
		 }
	}, [tableData]);

	useEffect(() => {
		if(AvoidInfiniteLoop && tableData) {
			setAvoidInfiniteLoop(false);
		}
	}, [tableData, pagiInputValue, pageCount, filterBy, sortBy]);


	// useEffect while extension not deployed
	// useEffect(() => {
	// 	 setSelectedListings([])
	// }, [pagiInputValue, pageCount, filterBy]);

	useEffect(() => {
		// Your URL
		const url = window.location.href;

		// Parse the URL to get the query parameters
		const queryParams: any = new URLSearchParams(url);

		try {
			// Get the "filter_by" parameter and decode it
			const filterParam = decodeURIComponent(queryParams.get('filter_by'));
			// Parse the "filter_by" JSON string into an object
			const filterObject = JSON.parse(filterParam);

			// Check if the "platforms" property exists
			if (filterObject && filterObject?.platforms) {
			}
			// else {
			//   // localStorage.removeItem('platforms');
			//   // localStorage.removeItem('platforms2');
			// }
		} catch (error) {
			console.error('Error parsing "filter_by" parameter:', error);
		}
	}, [filterBy]);

	const fetchPaginateDatas = useCallback(
	 async (currentPage: string) => {
		 try {
			 const tabs = [
				 'view all',
				 'active',
				 'draft',
				 'unlisted',
				 'out_of_stock',
				 'sold',
				 'issues_empty_ids',
				 'issues_status_selected_no_active_listing_found',
				 'issues_status_not_selected_active_listing_found',
				 'issues_sold_status_active_listing_found',
				 'issues_status_selected_no_listing_found',
			 ];

			 const tabsKeys = tabs.map((tabObject) => tabObject);
			 const filterByForRequest = Object.keys(filterBy).reduce(
			  (result, key) => {
				  if (!tabsKeys.includes(key)) {
					  result[key] = filterBy[key as keyof typeof filterBy];
				  }
				  return result;
			  },
			  {} as typeof filterBy
			 );

			 let updatedOptions = {...options};
			 const updatedParams = updatedOptions;
			 setLoader(true);
			 const filterByForRequestCheckBox: any = {};

			 if (checkbox1Checked) {
				 filterByForRequestCheckBox['draft'] = false;
			 }

			 if (checkbox2Checked) {
				 filterByForRequestCheckBox['sold'] = false;
			 }

			 const issuesFilterBy: any = {};

			 if (issuesValue.value === 'Empty IDs - Status selected, no ID saved') {
				 issuesFilterBy['issues_empty_ids'] = true;
			 } else if (
			  issuesValue.value ===
			  'Marketplace status selected, no ACTIVE listing found'
			 ) {
				 issuesFilterBy['issues_status_selected_no_active_listing_found'] =
				  true;
			 } else if (
			  issuesValue.value ===
			  'Marketplace status NOT selected, ACTIVE listing found'
			 ) {
				 issuesFilterBy['issues_status_not_selected_active_listing_found'] =
				  true;
			 } else if (
			  issuesValue.value === 'Sold status selected, ACTIVE listing found'
			 ) {
				 issuesFilterBy['issues_sold_status_active_listing_found'] = true;
			 } else if (
			  issuesValue.value === 'Marketplace status selected, NO listing found'
			 ) {
				 issuesFilterBy['issues_status_selected_no_listing_found'] = true;
			 }

			 const res = await instanceAxios.post('listings/list', {
				 metadata: {
					 offset: +currentPage * (metadata.limit || limit),

					 limit: metadata.limit || limit,
				 },
				 filter_by: {
					 ...filterByForRequest,
					 ...(activeTab !== 'view all' &&
					  activeTab !== 'issues' &&
					  activeTab !== 'out of stock' && {[activeTab]: true}),
					 ...(activeTab === 'view all' && filterByForRequestCheckBox),
					 ...(activeTab === 'issues' && issuesFilterBy),
					 ...(activeTab === 'out of stock' && {['out_of_stock']: true}),
				 },
				 ...(Object.values(sortBy).length > 0 &&
				  !Object.values(sortBy).includes('none') && {sort_by: sortBy}),

				 options: updatedParams,
			 });

			 if (res.status === 200) {
				 setLoader(false);

				 setMetadata({...metadata, offset: res.data.metadata.offset});
			 }
			 return res.data.data;
		 } catch (error) {
			 console.error(error);
		 }
	 },
	 [
		 filterBy,
		 options,
		 limit,
		 metadata,
		 sortBy,
		 activeTab,
		 checkbox1Checked,
		 checkbox2Checked,
	 ]
	);
	// old version
	// useEffect(() => {
	// 	const encodedFilterBy = encodeURIComponent(JSON.stringify(filterBy));
	// 	const encodedSortBy = encodeURIComponent(JSON.stringify(sortBy));
	// 	const encodedTab = encodeURIComponent(activeTab);
	//
	// 	const urlParams = new URLSearchParams(window.location.search);
	// 	const checklistParam = urlParams.get('checklist');
	//
	// 	let checklistUrlPart = '';
	// 	if (checklistParam !== null) {
	// 		checklistUrlPart = `&checklist=${checklistParam}`;
	// 	}
	//
	// 	const url = `/listings?filter_by=${encodedFilterBy}&sort_by=${encodedSortBy}&tab=${encodedTab}${checklistUrlPart}`;
	//
	// 	const redirectUrl = actionInUrl
	// 	 ? `${url}&action=new-listing`
	// 	 : url;
	//
	// 	navigate(redirectUrl);
	// }, [filterBy, sortBy, activeTab, activeTabName]);

	const updateUrl = (newParams : any, name?: string) => {
		const urlParams = new URLSearchParams(window.location.search);

		// Update or add new parameters
		Object.keys(newParams).forEach(key => {
			urlParams.set(key, newParams[key]);
		});

		// Preserve checklist if present
		const checklistParam = urlParams.get('checklist');
		if (checklistParam !== null) {
			urlParams.set('checklist', checklistParam);
		}
        if(name === 'action') {
			urlParams.delete('action');
		}
		const newUrl = `/listings?${urlParams.toString()}`;
		navigate(newUrl);
	};

	useEffect(() => {
		updateUrl({ filter_by: JSON.stringify(filterBy) });
	}, [filterBy]);

	useEffect(() => {
		updateUrl({ sort_by: JSON.stringify(sortBy) });
	}, [sortBy]);

	useEffect(() => {
		updateUrl({ tab: activeTab });
	}, [activeTab]);

	useEffect(() => {
		if(removeActionFromURL) {
			updateUrl({}, 'action')
			dispatch(setRemoveActionFromURL(false))
		}
	}, [removeActionFromURL]);


	const handleChangeByInput = async (num: string) => {
		let currentPage = +num - 1;

		localStorage.setItem('currentPageNumber', currentPage.toString());
		if (pagiInputValue !== '0' && pagiInputValue !== '') {
			const dataFromChangeInput = await fetchPaginateDatas(
			 currentPage.toString()
			);
			setTableData(dataFromChangeInput);
		}
	};

	const handleToggleModalShow = () => {
		dispatch(setToggleEditModal());
	};

	const handleFetchForSearch = useCallback(
	 debounce(async (filterValue: string) => {
		 if (filterValue && filterValue?.length > 30) return
		 localStorage.removeItem('currentPageNumber');
		 setLoader(true);

		 try {
			 const tabs =
			  hasSimple || hasBusiness
			   ? ['view all', 'sold', 'issues']
			   : [
				   'view all',
				   'active',
				   'draft',
				   'unlisted',
				   'out of stock',
				   'sold',
				   'issues',
			   ];

			 const updatedTabCounts2: any = {};
			 for (const key in tabCounts2) {
				 if (tabCounts2.hasOwnProperty(key)) {
					 updatedTabCounts2[key] = {
						 ...tabCounts2[key],
						 loader: true,
					 };
				 }
			 }
			 setTabCounts2(updatedTabCounts2);

			 const requests = tabs.map(async (tab) => {

				 const response = await sendRequest(tab, filterValue);

				 if (response) {
					 const total = myHelpConditionFunc(response, tab);

					 setTabCounts2((prevTabCounts2: any) => ({
						 ...prevTabCounts2,
						 [tab]: {
							 count: total,
							 loader: false,
							 tabName: tab,
						 },
					 }));
				 }

				 return response;
			 });
			 const responses = await Promise.all(requests);
			 setLoader(false);
		 } catch (error) {
			 console.error(error);
			 setLoader(false);
		 }
	 }, 1000),
	 [
		 tabCounts2,
		 metadata,
		 filterBy.active,
		 filterBy.draft,
		 filterBy.unlisted,
		 filterBy.out_of_stock,
		 filterBy.sold,
		 filterBy.issues_empty_ids,
		 filterBy.issues_status_selected_no_active_listing_found,
		 filterBy.issues_status_not_selected_active_listing_found,
		 filterBy.issues_sold_status_active_listing_found,
		 filterBy.issues_status_selected_no_listing_found,
		 filterBy.all,
		 sortBy,
		 tableData,
		 allTableData,
		 setAllTableData,
	 ]
	);

	useEffect(() => {
		if (!gridView && !isMobile) {
			document.body.classList.add('overflow-hidden');
		} else {
			document.body.classList.remove('overflow-hidden');
		}
		return () => {
			document.body.classList.remove('overflow-hidden');
		}
	}, [gridView, showIframe, isMobile]);

	useEffect(() => {
		if (gridView && showIframe) {
			document.body.classList.add('overflow-hidden');
		} else if (gridView && !showIframe) {
			document.body.classList.remove('overflow-hidden');
		}
		return () => {
			document.body.classList.remove('overflow-hidden');
		}
	}, [gridView, showIframe]);

	const myHelpConditionFunc = (response: any, tab: string) => {
		const {total} = response.metadata;

		const myActiveTab =
		 activeTab === 'out of stock'
		  ? 'out_of_stock'
		  : activeTab === 'issues'
		   ? 'issues_empty_ids'
		   : activeTab;

		const issuesDropdownItems = [
			'issues_sold_status_active_listing_found',
			'issues_status_not_selected_active_listing_found',
			'issues_status_selected_no_active_listing_found',
			'issues_empty_ids',
			'issues_status_selected_no_listing_found',
		];

		setAllTableData((prevTableData: any) => ({
			...prevTableData,

			...(tab !== 'view all' &&
			 tab !== 'out of stock' && {
				 [tab]: {
					 data: response.data,
					 pageCount: Math.ceil(total / (metadata.limit || limit)),
				 },
			 }),

			...(tab === 'view all' && {
				['all']: {
					data: response.data,
					pageCount: Math.ceil(total / (metadata.limit || limit)),
				},
			}),
			...(tab === 'out of stock' && {
				['out_of_stock']: {
					data: response.data,
					pageCount: Math.ceil(total / (metadata.limit || limit)),
				},
			}),
		}));

		const hasMatchingString = Object.keys(response.filter_by).some((value) => {
			return issuesDropdownItems.some((dpItem) => value.includes(dpItem));
		});

		if (tab === 'view all' && activeTab === 'view all') {
			setLoader(false);
			setTableData(response.data);
			setPageCount(Math.ceil(response.metadata.total / metadata.limit) || 0);
			setTotal(response.metadata.total);
			setPagiInputValue('1');
			setCurrentPaginateNumber(1);
		} else if (
		 response.filter_by.hasOwnProperty(myActiveTab) &&
		 response.filter_by['draft']
		) {
			setLoader(false);
			setTableData(response.data);
			setPageCount(Math.ceil(response.metadata.total / metadata.limit) || 0);
			setTotal(response.metadata.total);
			setPagiInputValue('1');

			setCurrentPaginateNumber(1);
		} else if (
		 response.filter_by.hasOwnProperty(myActiveTab) &&
		 tab !== 'view all'
		) {
			setLoader(false);
			setTableData(response.data);
			setPageCount(Math.ceil(response.metadata.total / metadata.limit) || 0);
			setTotal(response.metadata.total);
			setPagiInputValue('1');

			setCurrentPaginateNumber(1);
		} else if (
		 tab === 'issues' &&
		 hasMatchingString &&
		 activeTab === 'issues'
		) {
			setLoader(false);
			setTableData(response.data);
			setPageCount(Math.ceil(response.metadata.total / metadata.limit) || 0);
			setTotal(response.metadata.total);
			setPagiInputValue('1');

			setCurrentPaginateNumber(1);
		}

		return total;
	};

	async function sendRequest(tab: string, filterValue: string) {

		const tabs =
		 hasSimple || hasBusiness
		  ? ['view all', 'sold', 'issues']
		  : [
			  'view all',
			  'active',
			  'draft',
			  'unlisted',
			  'out_of_stock',
			  'sold',
			  'issues',
		  ];

		const tabsKeys = tabs.map((tabObject) => tabObject);
		const filterByForRequest = Object.keys(filterBy).reduce((result, key) => {
			if (
			 !tabsKeys.includes(key) &&
			 key !== 'search_everything' &&
			 key !== 'search_everything_type'
			) {
				result[key] = filterBy[key as keyof typeof filterBy];
			}
			return result;
		}, {} as typeof filterBy);

		const filterByForRequestCheckBox: any = {};

		if (checkbox1Checked) {
			filterByForRequestCheckBox['draft'] = false;
		}

		if (checkbox2Checked) {
			filterByForRequestCheckBox['sold'] = false;
		}

		const issuesFilterBy: any = {};

		if (issuesValue.value === 'Empty IDs - Status selected, no ID saved') {
			issuesFilterBy['issues_empty_ids'] = true;
		} else if (
		 issuesValue.value ===
		 'Marketplace status selected, no ACTIVE listing found'
		) {
			issuesFilterBy['issues_status_selected_no_active_listing_found'] = true;
		} else if (
		 issuesValue.value ===
		 'Marketplace status NOT selected, ACTIVE listing found'
		) {
			issuesFilterBy['issues_status_not_selected_active_listing_found'] = true;
		} else if (
		 issuesValue.value === 'Sold status selected, ACTIVE listing found'
		) {
			issuesFilterBy['issues_sold_status_active_listing_found'] = true;
		} else if (
		 issuesValue.value === 'Marketplace status selected, NO listing found'
		) {
			issuesFilterBy['issues_status_selected_no_listing_found'] = true;
		}
		const response = await instanceAxios.post('listings/list', {
			metadata,
			filter_by: {
				...(filterValue !== '' && {...filterByForRequest}),

				...(filterValue?.length > 0 && {
					search_everything: filterValue,
				}),

				...(tab !== 'view all' &&
				 tab !== 'out of stock' &&
				 tab !== 'issues' && {[tab]: true}),
				...(tab === 'out of stock' && {
					['out_of_stock']: true,
				}),
				...(tab === 'view all' && filterByForRequestCheckBox),
				...(tab === 'issues' && issuesFilterBy),
			},

			options,
			...(Object.values(sortBy).length > 0 &&
			 !Object.values(sortBy).includes('none') && {sort_by: sortBy}),
		});

		if (response.data.data) {
			return response.data;
		}

		return null;
	}

	const handleRowSelected = useCallback(
	 (e: any, id: string, checked:any): void => {
		 let tempDatas = tableData.map((tbData: ITableData) =>
		  tbData.id === +id ? {...tbData, isChecked: !tbData.isChecked} : tbData
		 );
		 const localIsChecked = Boolean(!checked);

		 setSelectedListings((prevSelectedListings:any) => {
			 if (localIsChecked) {
				 if (!prevSelectedListings.includes(+id)) {
					 return [...prevSelectedListings, +id];
				 }
			 } else {
				 return prevSelectedListings.filter((selectedId:any) => +selectedId !== +id);
			 }
			 return prevSelectedListings;
		 });

		 setSelectedListingsData((prevSelectedData: any) => {
			 const changedData = tableData.find((tbData) => tbData.id === +id);
			 if (localIsChecked) {
				 if (changedData && !prevSelectedData.some((data: any) => data.id === +id)) {
					 return [...prevSelectedData, changedData];
				 }
			 } else {
				 return prevSelectedData.filter((data: any) => data.id !== +id);
			 }
			 return prevSelectedData;
		 });


		 // Update the tableData state
		 setTableData(tempDatas);
	 },
	 [tableData, setTableData,setSelectedListings, setSelectedListingsData]
	);

	const handleGetColumns = () => {
		const tempColumnsString = localStorage.getItem('cols');
		const tempColumns = tempColumnsString
		 ? JSON.parse(tempColumnsString)
		 : null;
		const filteredData = tempColumns?.filter((item :any) => item?.heading !== undefined);
		setColumns(
		 filteredData?.length === 0 || filteredData === null ? columns : filteredData
		);
		// old version
		// setColumns(
		//  tempColumns?.length === 0 || tempColumns === null ? columns : tempColumns
		// );
	};

	const innerRef = useRef();

	const handleIssuesDropDownChanged = (e: any) => {
		setPagiInputValue('1');

		const modifiedObject: any = _.omit(
		 filterBy,

		 'issues_status_selected_no_active_listing_found',
		 'issues_status_not_selected_active_listing_found',
		 'issues_sold_status_active_listing_found',
		 'issues_empty_ids',

		 'issues_status_selected_no_listing_found'
		); // Removes all properties

		setIssuesValue(e);
		if (e.value === 'Empty IDs - Status selected, no ID saved') {
			setFilterBy({
				...modifiedObject,
				issues_empty_ids: true,
			});
		} else if (
		 e.value === 'Marketplace status selected, no ACTIVE listing found'
		) {
			setFilterBy({
				...modifiedObject,
				issues_status_selected_no_active_listing_found: true,
			});
		} else if (
		 e.value === 'Marketplace status NOT selected, ACTIVE listing found'
		) {
			setFilterBy({
				...modifiedObject,
				issues_status_not_selected_active_listing_found: true,
			});
		} else if (e.value === 'Sold status selected, ACTIVE listing found') {
			setFilterBy({
				...modifiedObject,
				issues_sold_status_active_listing_found: true,
			});
		} else if (e.value === 'Marketplace status selected, NO listing found') {
			setFilterBy({
				...modifiedObject,
				issues_status_selected_no_listing_found: true,
			});
		}
	};

	const handleEditColumns = (cols: string[]) => {
		const myNewCols = cols.map((item) => {
			return {
				heading:
				 item === 'sold_price'
				  ? formatWord(item)
				  : item === 'cogs' || item === 'upc'
				   ? item.toUpperCase()
				   : item,
				value: item,
				width: 140,
				order: 10,
				ref: innerRef,
			};
		});

		localStorage.setItem('cols', JSON.stringify(myNewCols));
		handleGetColumns();
		handleToggleModalShow();
		setUpdator((prev) => !prev);
	};
	const handleChangeBulkEdit = () => {
		dispatch(setFilterBySort(false));
		dispatch(setIsInputChanged(false));
		setRetryActive(false);
		setSaveActive(false);
		dispatch(setIsSave(false));
		dispatch(setIsCancel(false));
		setIsBulkEdit(!isBulkEdit);
	};
	const handleChangeBulkEditCancel = () => {
		dispatch(setFilterBySort(false));
		dispatch(setIsInputChanged(false));
		dispatch(setIsCancel(true));
		setSaveActive(false);
		setIsBulkEdit(!isBulkEdit);
	};

	const [downloadProgress, setDownloadProgress] = useState(0);
	const handleTogglePlatformsModalShow = () => {
		if (!platformChangesMade) {
			setPlatformsModalShow(
			 (prevPlatformsModalShow) => !prevPlatformsModalShow
			);
		} else {
			if (platformChangesMade) {
				const platformsTitles: string[] = [];
				const notPlatformsTitles: string[] = [];
				const notPlatformsForActivePlatforms: string[] = [];

				const customMarketplacesTitles: string[] = [];
				const notCustomMarketplacesTitles: string[] = [];
				const notCustomMarketplacesForActivePlatforms: string[] = [];



				lpPlatforms?.forEach((item: any) => {
					if (item.platform.isChecked) {
						platformsTitles.push(item.platform.title);
					}
					if (item.notOnPlatform.isChecked) {
						const title = item.notOnPlatform.title;
						const cleanedTitle = title
						.replace('Not on ', '')
						.replace('Not ', '');
						notPlatformsTitles.push(cleanedTitle);
						notPlatformsForActivePlatforms.push(title);
					}
				});

				customMarketplaces?.forEach((item: any) => {
					if (item.platform.isChecked) {
						customMarketplacesTitles.push(`Custom${item.platform.title}`);
					}
					if (item.notOnPlatform.isChecked) {
						const title = `Custom${item.notOnPlatform.title}`;
						const cleanedTitle = title
						.replace('Not on ', '')
						.replace('Not ', '');
						notCustomMarketplacesTitles.push(cleanedTitle);
						notCustomMarketplacesForActivePlatforms.push(title);
					}
				});

				handleGetColumnSearch({
					platforms: platformsTitles,
					not_platforms: notPlatformsTitles,
					custom_marketplaces: customMarketplacesTitles,
					not_custom_marketplaces: notCustomMarketplacesTitles,
				});
				const updatedCustomTitles = customMarketplacesTitles.map(title => title.slice(6));
				const updatedCustomNotOnTitles = notCustomMarketplacesForActivePlatforms.map(title => title.slice(6));

				setActivePlatforms([
					...platformsTitles,
					...notPlatformsForActivePlatforms,
					...updatedCustomTitles,
					...updatedCustomNotOnTitles,
				]);
			}

			setPlatformChangesMade(false); // Reset the changes made flag
			setPlatformsModalShow(false); // Close the modal
		}
	};

	const handleCsvClick = async () => {
		const current_payload = {
			metadata: {offset: 0, limit: 20},

			filter_by: filterBy,
		};

		const divider_number = 20;

		const max_concurrency = 5;

		try {
			setCsvLoading(true);

			const data = await getAllDataByPayload(
			 current_payload,
			 divider_number,
			 max_concurrency,
			 (percent) => setDownloadProgress(percent)
			);

			if (data) {
				const transformedCsvData = transformCsvDataForListings(data, columns);
				setCsvData(transformedCsvData);
				setCsvLoading(false);
			}
		} catch (error) {
			console.error(error);
			setCsvLoading(false);
		}

		setTimeout(() => {
			(csvDownloadRef.current as any).link.click();
			setDownloadProgress(0);
		}, 0);
	};

	const handleChangeActiveTab = (tab: string) => {
		setTab(tab);
	};

	useEffect(() => {
		const bodyElement = document.body;

		const handleAttributeChange = () => {
			const isAttributeTrue =
			 bodyElement.getAttribute('lp_custom_marketplaces_sync_v1') === 'true';
			setBodyAtribute(isAttributeTrue);
		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_marketplaces_sync_v1'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, []);


	const handleSaveBulkEdit = async () => {
		dispatch(setIsInputChanged(false));
		dispatch(setFilterBySort(false));
		if (!saveActive) {
			toast.warning(`Oops! It looks like no changes were made to your listing. Please edit something for the 'Save' button to become active.`, {
				position: 'bottom-right', // You can customize the notification position
			});
		} else {
			dispatch(setIsSave(true));
		}
		const mapKeyToId: any = {
			newTitle: '133',
			newSku: '1',
			newCondition: '15',
			newPrice: '22',
			newMaterial: '82',
			newBrand: '206',
			newColor: '209',
			newSize: '213',
			newKeywords: '453',
			newDescription: '505',
			newUpc: '704',
			newNotes: '726',
			newSold_price: '842',
			newCogs: '847',
			newFees: '844',
			newQuantity: '851',
			newSOLD: '539.1',
			newDateSold_date: '846',
			newEtsy: '539.3',
			newPoshmark: '539.4',
			neweBay: '539.2',
			newMercari: '539.5',
			newTradesy: '539.6',
			newAmazon: '539.7',
			newGrailed: '539.8',
			newFacebook: '539.9',
			newDepop: '539.11',
			newKidizen: '539.12',
			newShopify: '539.13',
			newInstagram: '539.14',
			newHeroine: '539.15',
			newListing_party: '539.16',
			newVestiaire: '539.17',
			newShippingCost: '852',
			newShippingWeightlbkg: '2',
			newShippingWeightozg: '3',
			newZip:'837',
			newShippingPackageWidth:'532',
			newShippingPackageHeight:'533',
			newShippingPackageLength:'531',
			newDraft: '859.1',
			newPlatformIdByeBay: '618',
			newPlatformIdByEtsy: '619',
			newPlatformIdByPoshmark: '620',
			newPlatformIdByMercari: '621',
			newPlatformIdByTradesy: '833',
			newPlatformIdByAmazon: '836',
			newPlatformIdByGrailed: '839',
			newPlatformIdByFacebook: '840',
			newPlatformIdByDepop: '841',
			newPlatformIdByKidizen: '853',
			newPlatformIdByShopify: '855',
			newPlatformIdByInstagram: '856',
			newPlatformIdByListingParty: '539.16',
			newPlatformIdByVestiaire: '885',
		};

		const updatedMapKeyToId = customMarketplaces.reduce(
		 (acc: any, item: any) => {
			 if (item.platform && item.platform.title) {
				 const key = `newCustom${item.platform.title}`;
				 acc[key] = item.platform.code[0];
			 }
			 return acc;
		 },
		 {...mapKeyToId}
		);


		const finallyMapKeyToId: Record<string, string> = customMarketplaces.reduce(
		 (acc: any, item: any, index: number) => {
			 if (item.platform && item.platform.title) {
				 const key = `newPlatformIdByCustom${item.platform.title.replace(
				  /[^a-zA-Z0-9' ]/g,
				  ''
				 )}`;

				 acc[key] =
				  index === 0
				   ? '892'
				   : index === 1
					? '893'
					: index === 2
					 ? '894'
					 : index === 3
					  ? '895'
					  : index === 4
					   ? '896'
					   :index === 5
						? '897'
						: index === 6
						 ? '898'
						 : index === 7
						  ? '899'
						  : index === 8
						   ? '900'
						   : index === 9
							? '901'
					   : (index + 891).toString();
			 }
			 return acc;
		 },
		 {...updatedMapKeyToId}
		);

		const platformsCode: string[] = [
			'539.3',
			'539.2',
			'539.3',
			'539.4',
			'539.5',
			'539.6',
			'539.7',
			'539.8',
			'539.9',
			'539.11',
			'539.12',
			'539.13',
			'539.14',
			'539.15',
			'539.16',
			'539.17',
			'891.1',
			'891.2',
			'891.3',
			'891.4',
			'891.5',
			'891.6',
			'891.7',
			'891.8',
			'891.9',
			'891.10'
		];

		const notChangedKeys = [
			'133',
			'1',
			'22',
			'726',
			'453',
			'704',
			'213',
			'842',
			'206',
			'847',
			'209',
			'15',
			'82',
			'844',
			'618',
			'619',
			'620',
			'621',
			'836',
			'839',
			'840',
			'841',
			'844',
			'852',
			'853',
			'855',
			'856',
			'885',
		];

		const platformCodeWithPlatformId = platforms.map((item: any) => {
			if (item !== undefined) {
				return {
					code: item.platform.code[0],
					platformId: item.platform.platformId,
				};
			}
		});

		let someChangedKeys = false;

		let newTabCounts2: any = {...tabCounts2};
		let filteredTableData = [...tableData];

		let moveToIssuesTab = false;

		let newAllTableData: any = {...allTableData};

		const updates: { key: keyof ITableData; index: number; value: any }[] = [];

		const customKeys = customMarketplaces.map(
		 (item: any) => `newCustom${item.platform.title}`
		);

		const customKeysId = customMarketplaces.map(
		 (item: any) => `newPlatformIdByCustom${item.platform.title}`
		);

		tableData?.forEach((item, index) => {
			if (item.changed) {
				const keys: (keyof ITableData)[] = [
					'newTitle',
					'newSku',
					'newCondition',
					'newPrice',
					'newMaterial',
					'newBrand',
					'newColor',
					'newSize',
					'newKeywords',
					'newDescription',
					'newUpc',
					'newNotes',
					'newSold_price',
					'newDateSold_date',
					'newCogs',
					'newFees',
					'newQuantity',
					'newSOLD',
					'newShippingCost',
				 	'newShippingWeightlbkg',
				 	'newShippingWeightozg',
				 	'newZip',
				 	'newShippingPackageWidth',
				 	'newShippingPackageHeight',
				 	'newShippingPackageLength',
					'newEtsy',
					'newPoshmark',
					'neweBay',
					'newMercari',
					'newTradesy',
					'newAmazon',
					'newGrailed',
					'newFacebook',
					'newDepop',
					'newKidizen',
					'newShopify',
					'newInstagram',
					'newHeroine',
					'newListing_party',
					'newVestiaire',
					'newDraft',
					'newPlatformIdByeBay',
					'newPlatformIdByEtsy',
					'newPlatformIdByPoshmark',

					'newPlatformIdByMercari',

					'newPlatformIdByTradesy',

					'newPlatformIdByAmazon',

					'newPlatformIdByGrailed',

					'newPlatformIdByFacebook',

					'newPlatformIdByDepop',

					'newPlatformIdByKidizen',

					'newPlatformIdByShopify',

					'newPlatformIdByInstagram',

					'newPlatformIdByHeroine',

					'newPlatformIdByListingParty',

					'newPlatformIdByVestiaire',
				];
				keys?.concat(customKeys, customKeysId)?.forEach((key) => {
					if (item[key] !== undefined) {
						const newKeywordsString = key === 'newKeywords' && item[key]!.map((item: any) => item?.value || item).join(', ');
						updates.push({
							key,
							index,
							value:
							 key === 'newPrice' ||
							 key === 'newSold_price' ||
							 key === 'newShippingCost' ||
							 key === 'newCogs' ||
							 key === 'newFees' || key ==='newShippingPackageWidth' || key === 'newShippingPackageHeight' || key === 'newShippingPackageLength'
							  ? +item[key]!
							  :
							  key === 'newQuantity'
							   ?
							   +item[key]!
							   : key === 'newKeywords'
								? newKeywordsString
								: item[key]!,
						});
					}
				});
			}
		});

		const groupedUpdates: { [id: number]: { [key: string]: any } } = {};

		function getDecimalDigitFromString(numberString: string) {
			const dotIndex = numberString.indexOf('.');

			if (dotIndex === -1) {
				return 0;
			}

			const decimalPart = numberString.slice(dotIndex + 1);

			return parseInt(decimalPart, 10).toString();
		}

		for (let update of updates) {
			const {index, key, value} = update;

			const id = tableData[index].id;
			if (!groupedUpdates[id]) {
				groupedUpdates[id] = {};
			}

			if (finallyMapKeyToId[key]!?.includes('891')) {
				if (value) {
					groupedUpdates[id][finallyMapKeyToId[key]!] =
					 getDecimalDigitFromString(finallyMapKeyToId[key]!);
				} else {
					groupedUpdates[id][finallyMapKeyToId[key]!] = null;
				}
			} else {
				groupedUpdates[id][finallyMapKeyToId[key]!] = value;
			}
		}


		for (let id in groupedUpdates) {
			const requestData = {
				filter_by: {id: +id},

				payload: groupedUpdates[id],
			};

			someChangedKeys = notChangedKeys.some(
			 (key) =>
			  Object.keys(groupedUpdates[id])[
			  Object.keys(groupedUpdates[id]).length - 1
			   ] === key
			);

			try {
				const response = await instanceAxios.put(
				 '/listings/metadata/update',
				 requestData
				);

				setRetryActive(false);
				setSaveActive(true);
				setTabCounts2(newTabCounts2);
				if (response.data.data) {
					const {metadata, id} = response.data.data;

					const myActiveTab =
					 activeTab === 'out of stock'
					  ? 'out_of_stock'
					  : activeTab === 'view all'
					   ? 'all'
					   : activeTab;

					let bulkListing = newAllTableData[myActiveTab].data.find(
					 (item: any) => item.id === id
					);

					bulkListing = {...bulkListing, ...response.data.data};

					for (let i = 0; i < platformCodeWithPlatformId.length; i++) {
						const {code, platformId}: any = platformCodeWithPlatformId[i];

						if (
						 metadata[539.1] !== 'SOLD' &&
						 metadata[code] &&
						 !metadata[platformId]
						) {
							moveToIssuesTab = true;
							break;
						}
					}

					filteredTableData = filteredTableData.filter(
					 (item) => item.id !== id
					);

					const fİndexAllTableData = allTableData[myActiveTab].data.findIndex(
					 (item: any) => item.id === id
					);

					if (fİndexAllTableData !== -1) {
						const updatedItem = {
							...newAllTableData[myActiveTab].data[fİndexAllTableData],
						};

						updatedItem.metadata = metadata;
						updatedItem.id = id;

						for (const newTabObj in newAllTableData) {
							if (
							 newAllTableData[newTabObj].data[fİndexAllTableData]?.id === id
							) {
								newAllTableData[newTabObj].data[fİndexAllTableData] =
								 updatedItem;
							}
						}
					}

					if (moveToIssuesTab) {
						someChangedKeys = true;
						if (activeTab !== 'issues') {
							newTabCounts2 = {
								...newTabCounts2,
								['issues']: {
									count: +(newTabCounts2['issues']?.count || 0) + 1,

									loader: false,
								},
							};

							const listingInIssuesTab = newAllTableData.issues.data.findIndex(
							 (item: any) => item.id === id
							);

							if (listingInIssuesTab === -1) {
								newAllTableData = {
									...newAllTableData,
									issues: {
										...newAllTableData.issues,
										data: [bulkListing, ...newAllTableData.issues.data],
									},
								};
							}
						}
					}

					if (!moveToIssuesTab) {
						someChangedKeys = true;

						const filteredForIssuesListing = newAllTableData.issues.data.filter(
						 (item: any) => item.id !== id
						);

						if (newTabCounts2.issues.count !== 0) {
							newTabCounts2 = {
								...newTabCounts2,
								['issues']: {
									count: +(newTabCounts2['issues']?.count || 0) - 1,

									loader: false,
								},
							};
						}

						newAllTableData = {
							...newAllTableData,
							issues: {
								...newAllTableData.issues,
								data: filteredForIssuesListing,
							},
						};

						if (myActiveTab === 'issues') {
							someChangedKeys = false;
						}
					}

					const containKeys = platformsCode.some(
					 (key) => typeof metadata[key] === 'string'
					);

					if (activeTab !== 'view all') {
						const updatedActiveTabData = newAllTableData[
						 myActiveTab
						 ].data.filter((item: any) => item.id !== id);

						if (
						 containKeys &&
						 (metadata[851] !== 0 || metadata[851] !== "0") &&
						 metadata[539.1] !== 'SOLD' &&
						 !metadata[859.1] &&
						 myActiveTab !== 'active' &&
						 myActiveTab !== 'issues'
						) {
							someChangedKeys = false;

							newTabCounts2 = {
								...newTabCounts2,
								['active']: {
									count: +(newTabCounts2['active']?.count || 0) + 1,

									loader: false,
								},
								[activeTab]: {
									count: +(newTabCounts2[activeTab]?.count || 0) - 1,
									loader: false,
								},
							};

							newAllTableData = {
								...newAllTableData,
								active: {
									...newAllTableData.active,
									data: [bulkListing, ...newAllTableData.active.data],
								},
								[myActiveTab]: {
									...newAllTableData[activeTab],
									data: updatedActiveTabData,
								},
							};
						}

						if (
						 !containKeys &&
						 (metadata[851] !== 0 || metadata[851] !== '0') &&
						 metadata[539.1] !== 'SOLD' &&
						 !metadata[859.1] &&
						 myActiveTab !== 'unlisted'
						) {
							someChangedKeys = false;

							newTabCounts2 = {
								...newTabCounts2,
								['unlisted']: {
									count: +(newTabCounts2['unlisted']?.count || 0) + 1,

									loader: false,
								},
								[activeTab]: {
									count: +(newTabCounts2[activeTab]?.count || 0) - 1,
									loader: false,
								},
							};

							newAllTableData = {
								...newAllTableData,
								unlisted: {
									...newAllTableData.unlisted,
									data: [bulkListing, ...newAllTableData.unlisted.data],
								},
								[myActiveTab]: {
									...newAllTableData[activeTab],
									data: updatedActiveTabData,
								},
							};
						}

						if (metadata[859.1] && myActiveTab !== 'draft') {
							someChangedKeys = false;

							newTabCounts2 = {
								...newTabCounts2,
								['draft']: {
									count: +(newTabCounts2['draft']?.count || 0) + 1,

									loader: false,
								},
								[activeTab]: {
									count: +(newTabCounts2[activeTab]?.count || 0) - 1,
									loader: false,
								},
							};

							newAllTableData = {
								...newAllTableData,
								draft: {
									...newAllTableData.draft,
									data: [bulkListing, ...newAllTableData.draft.data],
								},
								[myActiveTab]: {
									...newAllTableData[activeTab],
									data: updatedActiveTabData,
								},
							};
						}

						if (
						 metadata[539.1] === 'SOLD' &&
						 !metadata[859.1] &&
						 myActiveTab !== 'sold'
						) {
							someChangedKeys = false;

							newTabCounts2 = {
								...newTabCounts2,
								['sold']: {
									count: +(newTabCounts2['sold']?.count || 0) + 1,

									loader: false,
								},
								[activeTab]: {
									count: +(newTabCounts2[activeTab]?.count || 0) - 1,
									loader: false,
								},
							};
							newAllTableData = {
								...newAllTableData,
								sold: {
									...newAllTableData.sold,
									data: [bulkListing, ...newAllTableData.sold.data],
								},
								[myActiveTab]: {
									...newAllTableData[activeTab],
									data: updatedActiveTabData,
								},
							};
						}

						if (
						 (metadata[851] === 0 || metadata[851] === '0') &&
						 !metadata[859.1] &&
						 metadata[539.1] !== 'SOLD' &&
						 myActiveTab !== 'out_of_stock'
						) {
							someChangedKeys = false;

							newTabCounts2 = {
								...newTabCounts2,
								['out of stock']: {
									count: +(newTabCounts2['out of stock']?.count || 0) + 1,

									loader: false,
								},
								[activeTab]: {
									count: +(newTabCounts2[activeTab]?.count || 0) - 1,
									loader: false,
								},
							};

							newAllTableData = {
								...newAllTableData,
								['out_of_stock']: {
									...newAllTableData['out_of_stock'],
									data: [bulkListing, ...newAllTableData['out_of_stock'].data],
								},
								[myActiveTab]: {
									...newAllTableData[activeTab],
									data: updatedActiveTabData,
								},
							};
						}
					}

					if (activeTab === 'view all' && !metadata[859.1]) {
						newTabCounts2 = {
							...newTabCounts2,
							['draft']: {
								count: +(newTabCounts2['draft']?.count || 0) - 1,

								loader: false,
							},
						};
					}

					if (activeTab === 'view all' && metadata[859.1]) {
						newTabCounts2 = {
							...newTabCounts2,
							['draft']: {
								count: +(newTabCounts2['draft']?.count || 0) + 1,

								loader: false,
							},
						};
					}

					const updatedItems = [...tableData];
					const updatedItem = response.data.data;

					const itemIndex = updatedItems.findIndex(
					 (item) => item.id === updatedItem.id
					);

					if (itemIndex !== -1) {
						updatedItems[itemIndex].metadata = updatedItem.metadata;

						for (let key in groupedUpdates[id]) {
							(updatedItems[itemIndex] as any)[key] = groupedUpdates[id][key];
						}

						delete updatedItems[itemIndex].changed;
					}

					setTableData(updatedItems);

					handleChangeBulkEdit();
				}
				setAllTableData(newAllTableData);
				if (!someChangedKeys) {
					setTableData(filteredTableData);
				}
			} catch (error) {
				toast.error(`Oops! We encountered an error while attempting to save your changes. Please click the 'Retry' button to try again.`, {
					position: 'bottom-right', // You can customize the notification position
				});
				setRetryActive(true);
				setSaveActive(true);
				console.error(error);
			}
		}
	};

	// const handleToggleBulkDeleteShow = () => {
	// 	setBulkDeleteConfirmModalShow(!bulkDeleteConfirmModalShow);
	// };

	// const handleBulkDelete = async () => {
	// 	const requests: Promise<any>[] = [];
	// 	const updatedTableData = [...tableData]; // Создаем копию массива tableData
	//
	// 	for (let i = 0; i < updatedTableData.length; i++) {
	// 		const item = updatedTableData[i];
	// 		if (item.isChecked && item.status === 'active') {
	// 			const requestData = {
	// 				filter_by: {id: item.id},
	// 				payload: {status: 'trash'},
	// 			};
	//
	// 			const request = instanceAxios.put('/listings/update', requestData);
	// 			requests.push(request);
	//
	// 			updatedTableData[i] = {...item, status: 'trash'};
	// 		}
	// 	}
	//
	// 	try {
	// 		const responses = await Promise.all(requests);
	//
	// 		responses?.forEach((response) => {
	// 			if (response.data) {
	// 				setBulkDeleteConfirmModalShow(false);
	// 			}
	// 		});
	// 		setTableData(updatedTableData);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	const handleBulkDelete = async () => {
		const requests: Promise<any>[] = [];
		let updatedTableData = [...tableData];
		let deletedListings:any = []; // State to store deleted listings

		const filteredSelectedListings = selectedListings.filter((id: any) => !listingsForDelete.includes(id));

		// Filter out the selected listings from tableData and create update requests
		filteredSelectedListings.forEach((id:any) => {

			const requestData = {
				filter_by: {id: id},
				payload: {status: 'trash'},
			};
			const request = instanceAxios.put('/listings/update', requestData);
			requests.push(request);

			deletedListings.push(id);

		})
		try {
			await Promise.all(requests);
			// Update the local state and reset selectedListings
			setTableData(updatedTableData);
			setBulkDeleteConfirmModalShow(false);

			// Update deleted listings state
			setListingsForDelete((prevDeletedListings: any) => {
				const updatedDeletedListings = [...prevDeletedListings];

				// Add new items from selectedListings that are not already in prevDeletedListings
				selectedListings.forEach((id: number) => {
					if (!prevDeletedListings.includes(id)) {
						updatedDeletedListings.push(id);
					}
				});
				deletedListings = [];

				return updatedDeletedListings;
			});

		} catch (error) {
			console.error(error);
		}
	};

	const handleBulkRestore = async () => {
		const requests: Promise<any>[] = [];
		let updatedTableData = [...tableData];
		let restoredListings:any = []; // State to store restored listings

		listingsForDelete?.forEach((id:any)=>{
			const requestData = {
				filter_by: { id: id },
				payload: { status: 'active' },
			};
			const request = instanceAxios.put('/listings/update', requestData);
			requests.push(request);
			// Track restored items
			restoredListings.push(id);
		})

		try {
			const responses = await Promise.all(requests);

			responses?.forEach((response) => {
				if (response.data) {
					const filterId = response.data.filter_by.id;
					updatedTableData = updatedTableData.map((item) => {
						if (item.id === filterId) {
							return { ...item, status: 'active', isChecked: false };
						} else {
							return item;
						}
					});

					setTableData(updatedTableData);
				}
			});

			// Remove restored items from deletedListings state
			setListingsForDelete((prevDeletedListings: number[]) => {
				const updatedDeletedListings = prevDeletedListings.filter((prevItemId: any) => {
					return !restoredListings.includes(prevItemId);
				});
				return updatedDeletedListings;
			});

		} catch (error) {
			console.error(error);
		}
	};

	const issuesChecker = (localFilterBy: any) => {
		if (issuesValue.value === 'Empty IDs - Status selected, no ID saved') {
			return (localFilterBy['issues_empty_ids'] = true);
		} else if (
		 issuesValue.value ===
		 'Marketplace status selected, no ACTIVE listing found'
		) {
			return (localFilterBy['issues_status_selected_no_active_listing_found'] =
			 true);
		} else if (
		 issuesValue.value ===
		 'Marketplace status NOT selected, ACTIVE listing found'
		) {
			return (localFilterBy['issues_status_not_selected_active_listing_found'] =
			 true);
		} else if (
		 issuesValue.value === 'Sold status selected, ACTIVE listing found'
		) {
			return (localFilterBy['issues_sold_status_active_listing_found'] = true);
		} else if (
		 issuesValue.value === 'Marketplace status selected, NO listing found'
		) {
			return (localFilterBy['issues_status_selected_no_listing_found'] = true);
		}
	};

	const handleTabsClick = async (type: string) => {
		setHasMore(true);
		setActiveTab(type);
		setPagiInputValue('1');
		setCurrentPaginateNumber(1);
		let localFilterBy = filterBy;
		if (type === 'issues') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			issuesChecker(localFilterBy);
		} else if (type === 'view all') {
			delete localFilterBy.active;
			!checkbox2Checked && delete localFilterBy.sold;
			!checkbox1Checked && delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			if (checkbox1Checked) localFilterBy.draft = false;
			if (checkbox2Checked) localFilterBy.sold = false;
		} else if (type === 'active') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['active'] = true;
		} else if (type === 'draft') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['draft'] = true;
		} else if (type === 'sold') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['sold'] = true;
		} else if (type === 'unlisted') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['unlisted'] = true;
		} else if (type === 'out of stock') {
			delete localFilterBy.active;
			delete localFilterBy.sold;
			delete localFilterBy.draft;
			delete localFilterBy.unlisted;
			delete localFilterBy.out_of_stock;
			delete localFilterBy.issues_empty_ids;
			delete localFilterBy.issues_status_selected_no_active_listing_found;
			delete localFilterBy.issues_status_not_selected_active_listing_found;
			delete localFilterBy.issues_sold_status_active_listing_found;
			delete localFilterBy.issues_status_selected_no_listing_found;

			localFilterBy['out_of_stock'] = true;
		}
		setTab(type);
		setFilterBy(localFilterBy);

		let updatedOptions = {...options};
		localStorage.removeItem('currentPageNumber');
		if (type === 'out of stock') {
			type = 'out_of_stock';
		} else if (type === 'issues') {
			type = 'issues_empty_ids';
		}
		const keys = [
			1,
			15,
			22,
			82,
			133,
			206,
			209,
			213,
			453,
			505,
			704,
			726,
			842,
			847,
			851,
			'platforms',
			'not_platforms',
			'search_everything',
		];

		const myFilterBy = Object.keys(filterBy).filter(
		 (key) => keys.includes(Number(key)) || keys.includes(key.toString())
		);

		if (
		 allTableData &&
		 (Object.values(sortBy).length === 0 ||
		  Object.values(sortBy).includes('none')) &&
		 myFilterBy.length === 0
		) {
			if (type === 'active') {
				setPageCount(allTableData.active.pageCount);
				setTableData(allTableData.active.data);
			} else if (type === 'draft') {
				setPageCount(allTableData.draft.pageCount);

				setTableData(allTableData.draft.data);
			} else if (type === 'unlisted') {
				setPageCount(allTableData.unlisted.pageCount);

				setTableData(allTableData.unlisted.data);
			} else if (type === 'out_of_stock') {
				setPageCount(allTableData.out_of_stock.pageCount);

				setTableData(allTableData.out_of_stock.data);
			} else if (type === 'sold') {
				setPageCount(allTableData.sold.pageCount);

				setTableData(allTableData.sold.data);
			} else if (type === 'view all') {
				// getAllRequest(type);
				setPageCount(allTableData.all.pageCount);
				setTableData(allTableData.all.data);
			} else if (type === 'issues_empty_ids') {
				setPageCount(allTableData.issues.pageCount);
				setTableData(allTableData.issues.data);
			}
		} else {
			try {
				const tabsKeys = tabs.map((tabObject) => tabObject.tab);

				const filterByForRequest: any = Object.keys(filterBy).reduce(
				 (result, key) => {
					 if (
					  !tabsKeys.includes(key) &&
					  key !== 'out_of_stock' &&
					  key !== 'issues_empty_ids' &&
					  key !== 'issues_sold_status_active_listing_found' &&
					  key !== 'issues_status_not_selected_active_listing_found' &&
					  key !== 'issues_status_selected_no_active_listing_found' &&
					  key !== 'issues_status_selected_no_listing_found'
					 ) {
						 result[key] = filterBy[key as keyof typeof filterBy];
					 }
					 return result;
				 },
				 {} as typeof filterBy
				);

				setCurrentPaginateNumber(1);
				setTabLoading(true);
				setLoader(true);
				const filterByForRequestCheckBox: any = {};

				if (checkbox1Checked) {
					filterByForRequestCheckBox['draft'] = false;
				}

				if (checkbox2Checked) {
					filterByForRequestCheckBox['sold'] = false;
				}

				const issuesFilterBy: any = {};
				if (issuesValue.value === 'Empty IDs - Status selected, no ID saved') {
					issuesFilterBy['issues_empty_ids'] = true;
				} else if (
				 issuesValue.value ===
				 'Marketplace status selected, no ACTIVE listing found'
				) {
					issuesFilterBy['issues_status_selected_no_active_listing_found'] =
					 true;
				} else if (
				 issuesValue.value ===
				 'Marketplace status NOT selected, ACTIVE listing found'
				) {
					issuesFilterBy['issues_status_not_selected_active_listing_found'] =
					 true;
				} else if (
				 issuesValue.value === 'Sold status selected, ACTIVE listing found'
				) {
					issuesFilterBy['issues_sold_status_active_listing_found'] = true;
				} else if (
				 issuesValue.value === 'Marketplace status selected, NO listing found'
				) {
					issuesFilterBy['issues_status_selected_no_listing_found'] = true;
				}

				const response = await instanceAxios.post('/listings/list', {
					filter_by: {
						...filterByForRequest,
						...(type !== 'view all' &&
						 type !== 'issues_empty_ids' && {[type]: true}),

						...(type === 'view all' && filterByForRequestCheckBox),
						...(type === 'issues_empty_ids' && issuesFilterBy),
						...(filterBy.search_everything &&
						 filterBy.search_everything?.length > 0 && {
							 search_everything: filterBy.search_everything,
						 }),
					},

					...(Object.values(sortBy).length > 0 &&
					 !Object.values(sortBy).includes('none') && {sort_by: sortBy}),

					options: updatedOptions,

					metadata: {offset: 0, limit: metadata.limit || 10},
				});

				if (response.status === 200) {
					setLoader(false);
					setPageCount(
					 Math.ceil(response.data.metadata.total / (metadata.limit || limit))
					);

					setTabLoading(false);

					setTotal(response.data.metadata.total);
					setTableData(response.data.data);
					setFilterBy(response.data.filter_by);
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const deleteFunction = (cols: any, names: string[]) => {
		if (cols) {
			const storedColumns = JSON.parse(cols);
			names?.forEach((name) => {
				const draftExistsInStorage = storedColumns.some(
				 (column: any) => column.value === name
				);
				if (!draftExistsInStorage) {
					const draftIndex = storedColumns.findIndex(
					 (column: any) => column.value === name
					);
					if (draftIndex !== -1) {
						const updatedColumns = storedColumns.filter(
						 (_: any, index: any) => index !== draftIndex
						);
						setColumns(updatedColumns);
					}
				}
			});
		} else {
			const filteredColumns = columns.filter(
			 (column) => !names.includes(column.value)
			);
			setColumns(filteredColumns);
		}
	};

	useEffect(() => {
		handleGetColumns();
		if (activeTabName === 'draft') {
			const cols = localStorage.getItem('cols');
			if (cols) {
				const storedColumns = JSON.parse(cols);
				const draftExistsInStorage = storedColumns.some(
				 (column: any) => column.value === 'drafts'
				);
				if (draftExistsInStorage) {
					return;
				} else {
					const newColumns = {
						heading: 'Drafts',
						value: 'drafts',
						width: 130,
						order: 27,
						ref: draftRef,
					};

					const insertAtIndex = 2; // The index at which you want to insert the new column
					const draftExists = storedColumns.some(
					 (column: any) => column.value === newColumns.value
					);

					if (!draftExists) {
						let updatedColumns: any = [
							...storedColumns.slice(0, insertAtIndex),
							newColumns,
							...storedColumns.slice(insertAtIndex),
						];

						const draftExistsInStorage = storedColumns.some(
						 (column: any) => column.value === 'issues'
						);
						if (!draftExistsInStorage) {
							updatedColumns = updatedColumns.filter(
							 (column: any) => column.value !== 'issues'
							);
						}
						setColumns(updatedColumns);
					}
				}
			} else {
				const newColumns = {
					heading: 'Drafts',
					value: 'drafts',
					width: 130,
					order: 27,
					ref: draftRef,
				};

				const insertAtIndex = 2; // The index at which you want to insert the new column
				const draftExists = columns.some(
				 (column) => column.value === newColumns.value
				);

				if (!draftExists) {
					let updatedColumns: any = [
						...columns.slice(0, insertAtIndex),
						newColumns,
						...columns.slice(insertAtIndex),
					];
					updatedColumns = updatedColumns.filter(
					 (column: any) =>
					  column.value !== 'issues' &&
					  column.value !== 'fees' &&
					  column.value !== 'sold price' &&
					  column.value !== 'cogs' &&
					  column.value !== 'date sold' &&
					  column.value !== 'shipping cost' &&
					  column.value !== 'profit'
					);
					setColumns(updatedColumns);
				}
			}
		} else if (activeTabName === 'issues') {
			const cols = localStorage.getItem('cols');
			if (cols) {
				const storedColumns = JSON.parse(cols);
				const draftExistsInStorage = storedColumns.some(
				 (column: any) => column.value === 'issues'
				);

				if (draftExistsInStorage) {
					return;
				} else {
					const newColumns = {
						heading: 'Issues',
						value: 'issues',
						width: 130,
						order: 27,
						ref: issueRef,
					};
					const platformsColumnIndex = storedColumns.findIndex(
					 (column: any) => column.value === 'platforms'
					);

					const insertAtIndex =
					 platformsColumnIndex !== -1 ? platformsColumnIndex : 2; // The index at which you want to insert the new column
					const draftExists = storedColumns.some(
					 (column: any) => column.value === newColumns.value
					);

					if (!draftExists) {
						let updatedColumns: any = [
							...storedColumns.slice(0, insertAtIndex),
							newColumns,
							...storedColumns.slice(insertAtIndex),
						];

						const draftExistsInStorage = storedColumns.some(
						 (column: any) => column.value === 'drafts'
						);

						if (!draftExistsInStorage) {
							updatedColumns = updatedColumns.filter(
							 (column: any) => column.value !== 'drafts'
							);
						}

						setColumns(updatedColumns);
					}
				}
			} else {
				const newColumns = {
					heading: 'Issues',
					value: 'issues',
					width: 130,
					order: 27,
					ref: issueRef,
				};

				const draftExists = columns.some(
				 (column) => column.value === newColumns.value
				);
				const platformsColumnIndex = columns.findIndex(
				 (column) => column.value === 'platforms'
				);

				const insertAtIndex =
				 platformsColumnIndex !== -1 ? platformsColumnIndex : 2;

				if (!draftExists) {
					let updatedColumns: any = [
						...columns.slice(0, insertAtIndex),
						newColumns,
						...columns.slice(insertAtIndex),
					];

					updatedColumns = updatedColumns.filter(
					 (column: any) =>
					  column.value !== 'drafts' &&
					  column.value !== 'fees' &&
					  column.value !== 'sold price' &&
					  column.value !== 'cogs' &&
					  column.value !== 'date sold' &&
					  column.value !== 'shipping cost' &&
					  column.value !== 'profit'
					);

					setColumns(updatedColumns);
				}
			}
		} else if (activeTabName === 'sold') {
			const cols = localStorage.getItem('cols');
			if (cols) {
				const storedColumns = JSON.parse(cols);
				const requiredValues = [
					'cogs',
					'fees',
					'sold price',
					'date sold',
					'shipping cost',
					'profit',
				];
				const everyColumnsExistInStorage = storedColumns.every((column: any) =>
				 requiredValues.includes(column.value)
				);

				if (everyColumnsExistInStorage) {
					return;
				} else {
					const newColumnsToAdd = [
						{
							heading: 'COGS',
							value: 'cogs',
							width: 130,
							order: 27,
							ref: cogsRef,
						},

						{
							heading: 'Fees',
							value: 'fees',
							width: 130,
							order: 28,
							ref: feesRef,
						},
						{
							heading: 'Sold price',
							value: 'sold price',
							width: 130,
							order: 28,
							ref: soldPriceRef,
						},
						{
							heading: 'Date sold',
							value: 'date sold',
							width: 130,
							order: 28,
							ref: dateSoldRef,
						},
						{
							heading: 'Shipping Cost',
							value: 'shipping cost',
							width: 130,
							order: 28,
							ref: shippingCost,
						},
						{
							heading: 'Profit',
							value: 'profit',
							width: 130,
							order: 28,
							ref: profitRef,
						},

						// Add more objects for other columns if needed
					];

					let insertAtIndex = 2; // The index at which you want to insert the new column
					let updatedColumns = [...storedColumns]; // Create a copy of storedColumns
					const draftsColumnIndex = updatedColumns.findIndex(
					 (column) => column.value === 'drafts'
					);

					if (draftsColumnIndex === -1) {
						updatedColumns.splice(draftsColumnIndex, 0); // Remove 'drafts' column
					}

					// Check if 'issues' column exists in storedColumns
					const issuesColumnIndex = updatedColumns.findIndex(
					 (column) => column.value === 'issues'
					);

					if (issuesColumnIndex === -1) {
						updatedColumns.splice(issuesColumnIndex, 0); // Remove 'issues' column
					}

					if (!hasSimple) {
						for (const newColumn of newColumnsToAdd) {
							const existingColumn = storedColumns.find(
							 (column: any) => column.value === newColumn.value
							);

							if (!existingColumn) {
								updatedColumns.splice(insertAtIndex, 0, newColumn);
								insertAtIndex++; // Increment the insertion index for the next iteration
							}
						}
					}

					setColumns(updatedColumns);
				}
			} else {
				const newColumnsToAdd = [
					{
						heading: 'Profit',
						value: 'profit',
						width: 130,
						order: 28,
						ref: profitRef,
					},
					{
						heading: 'Fees',
						value: 'fees',
						width: 130,
						order: 28,
						ref: feesRef,
					},
					{
						heading: 'Sold price',
						value: 'sold price',
						width: 130,
						order: 28,
						ref: soldPriceRef,
					},

					{
						heading: 'Shipping Cost',
						value: 'shipping cost',
						width: 130,
						order: 28,
						ref: shippingCost,
					},
					{
						heading: 'COGS',
						value: 'cogs',
						width: 130,
						order: 27,
						ref: cogsRef,
					},
					{
						heading: 'Date sold',
						value: 'date sold',
						width: 130,
						order: 28,
						ref: dateSoldRef,
					},

					// Add more objects for other columns if needed
				];
				const insertAtIndex = 2; // The index at which you want to insert the new column

				let updatedColumns = [...columns]; // Clone the existing columns array

				// Iterate through newColumnsToAdd array
				if (!hasSimple) {
					for (const newColumn of newColumnsToAdd) {
						const columnExists = columns.some(
						 (column) => column.value === newColumn.value
						);

						if (!columnExists) {
							updatedColumns.splice(insertAtIndex, 0, newColumn);
						}
					}
				}

				updatedColumns = updatedColumns.filter(
				 (column: any) =>
				  column.value !== 'drafts' && column.value !== 'issues'
				);

				setColumns(updatedColumns);
			}
		} else {
			const cols = localStorage.getItem('cols');
			deleteFunction(cols, [
				'drafts',
				'issues',
				'fees',
				'cogs',
				'sold price',
				'date sold',
				'shipping cost',
				'profit',
			]);
		}
	}, [activeTabName]);


	const handleSortByClick = useCallback(
	 async (thValue: string, sortValue: 'asc' | 'desc' | 'none') => {
		 console.log(thValue,'thValue')
		 if (
		  thValue === 'platforms' ||
		  thValue === 'description' ||
		  thValue === 'sold price' ||
		  thValue === 'cogs' ||
		  thValue === 'assignee' ||
		  thValue === 'upc' ||
		  thValue === 'brand' ||
		  thValue === 'color' ||
		  thValue === 'size' ||
		  thValue === 'material' ||
		  thValue === 'condition' || thValue === 'zip'
		 ) {
			 return;
		 }

		 localStorage.removeItem('currentPageNumber');

		 let key: string = '';
		 if (thValue === 'SKU') {
			 key = '1';
		 } else if (thValue === 'price') {
			 key = '22';
		 } else if (thValue === 'image') {
			 key = '727';
		 } else if (thValue === 'quantity') {
			 key = '851';
		 } else if (thValue === 'updated') {
			 key = 'updated_at';
		 } else if (thValue === 'created') {
			 key = 'created_at';
		 } else if (thValue === 'title') {
			 key = '133';
		 } else if (thValue === 'notes') {
			 key = '726';
		 }

		 const sortPayload = {
			 ...(thValue !== '' && {[key]: sortValue}),
		 };

		 try {
			 setLoader(true);
			 const issuesFilterBy: any = {};

			 if (issuesValue.value === 'Empty IDs - Status selected, no ID saved') {
				 issuesFilterBy['issues_empty_ids'] = true;
			 } else if (
			  issuesValue.value ===
			  'Marketplace status selected, no ACTIVE listing found'
			 ) {
				 issuesFilterBy['issues_status_selected_no_active_listing_found'] =
				  true;
			 } else if (
			  issuesValue.value ===
			  'Marketplace status NOT selected, ACTIVE listing found'
			 ) {
				 issuesFilterBy['issues_status_not_selected_active_listing_found'] =
				  true;
			 } else if (
			  issuesValue.value === 'Sold status selected, ACTIVE listing found'
			 ) {
				 issuesFilterBy['issues_sold_status_active_listing_found'] = true;
			 } else if (
			  issuesValue.value === 'Marketplace status selected, NO listing found'
			 ) {
				 issuesFilterBy['issues_status_selected_no_listing_found'] = true;
			 }
			 const response = await instanceAxios.post('/listings/list', {
				 filter_by: {
					 ...filterBy,
					 ...(activeTab !== 'view all' &&
					  activeTab !== 'issues' &&
					  activeTab !== 'out of stock' && {[activeTab]: true}),
					 ...(activeTab === 'out of stock' && {['out_of_stock']: true}),
					 ...(activeTab === 'issues' && issuesFilterBy),
				 },
				 ...(sortValue !== 'none' && {sort_by: sortPayload}),
				 metadata: {offset: 0, limit: metadata.limit || 10},

				 options: options,
			 });

			 if (response.status === 200) {
				 setLoader(false);

				 setTableData(response.data.data);
				 setSortBy(sortPayload);
			 }
		 } catch (error) {
			 console.error(error);
		 }
	 },
	 [filterBy, options, metadata]
	);
	const handleShowConnectedMarketsModal = () => {
		setConnectedMarketplacesModal(true);
	};
	const handleGetColumnSearchDebounced = useCallback(
	 debounce(async (col: string, value: any) => {
		 setHasMore(true);
		 localStorage.removeItem('currentPageNumber');

		 let key: string = '';

		 switch (col) {
			 case 'SKU':
				 key = '1';
				 break;
			 case 'condition':
				 key = '15';
				 break;
			 case 'price':
				 key = '22';
				 break;
			 case 'material':
				 key = '82';
				 break;
			 case 'title':
				 key = '133';
				 break;
			 case 'brand':
				 key = '206';
				 break;
			 case 'color':
				 key = '209';
				 break;
			 case 'size':
				 key = '213';
				 break;
			 case 'keywords':
				 key = '453';
				 break;
			 case 'description':
				 key = '505';
				 break;
			 case 'upc':
				 key = '704';
				 break;
			 case 'notes':
				 key = '726';
				 break;
			 case 'sold_price':
				 key = '842';
				 break;
			 case 'cogs':
				 key = '847';
				 break;
			 case 'drafts':
				 key = '859.1';
				 break;
			 case 'quantity':
				 key = '851';
				 break;
			 case 'weight':
				 key = '';
				 break;
			 case 'shipping_dimensions':
				 key = '';
				 break;
			 default:
				 break;
		 }

		 if (col === 'createdStart' || col === 'createdEnd' || col === 'createdFullChanged') {
			 key = 'created_at';
		 }

		 let searchPayload:any = {};
		 const tabsKeys = tabs.map((tabObject) => tabObject.tab);
		 const filterByForRequest = Object.keys(filterBy).reduce((result, key) => {
			 if (!tabsKeys.includes(key)) {
				 result[key] = filterBy[key as keyof typeof filterBy];
			 }
			 return result;
		 }, {} as typeof filterBy);

		 let tempFilterBy = {...filterBy};

		 if (value !== '' && value !== null && value !== undefined) {
			 if (col === 'createdStart' || col === 'createdEnd') {
				 const key = col === 'createdStart' ? 'start' : 'end';
				 searchPayload = {
					 ...filterByForRequest,
					 created_at: {
						 ...filterByForRequest.created_at,
						 [key]: value
					 }
				 };
			 }
			 else if(col === 'createdFullChanged'){
				   searchPayload = {
					 ...filterByForRequest,
					 [key]: value
				    };

					if(value?.start === null && value?.end === null) {
						delete searchPayload[key];
						delete tempFilterBy[key];
					}
				    else if(value?.start === null && value?.end !== null) {
					    delete searchPayload?.created_at?.start
						delete tempFilterBy?.created_at?.start
				    }
				    else  if(value?.start !== null && value?.end === null) {
						delete searchPayload?.created_at?.end
						delete tempFilterBy?.created_at?.end
				   }

			 }
			 else {
				 searchPayload = {
					 ...filterByForRequest,
					 [key]: (col === 'price' || col === 'sold_price' || col === 'cogs' || col === 'quantity') ? +value : value
				 };
			 }
			 tempFilterBy = searchPayload;
		 }
		 else {
			 if (col === 'createdStart' || col === 'createdEnd' || col === 'createdFullChanged') {
				 if(filterByForRequest?.created_at){
					 const { start, end } = filterByForRequest?.created_at;
					 // Check if 'start' or 'end' is being removed
					 if (col === 'createdStart' && start !== null) {
						 searchPayload = { ...filterByForRequest };
						 searchPayload.created_at = { ...filterByForRequest?.created_at };
						 delete searchPayload?.created_at?.start;
						 delete tempFilterBy?.created_at?.start;
						 if(start === undefined || end === undefined){
							 delete searchPayload?.created_at;
							 delete tempFilterBy?.created_at;
						 }
					 } else if (col === 'createdEnd' && end !== null) {
						 searchPayload = { ...filterByForRequest };
						 searchPayload.created_at = { ...filterByForRequest?.created_at };
						 delete searchPayload?.created_at?.end;
						 delete tempFilterBy?.created_at?.end;
						 if(start === undefined || end === undefined) {
							 delete searchPayload?.created_at;
							 delete tempFilterBy?.created_at;
						 }
					 }
					 else if(col === 'createdFullChanged') {
						 searchPayload = { ...filterByForRequest };
						 delete searchPayload[key];
						 delete tempFilterBy[key];
					 }
				 }
			 } else {
				 searchPayload = { ...filterByForRequest };
				 delete searchPayload[key];
				 delete tempFilterBy[key];
			 }
		 }

		 const decodedFilterBy = _.mapValues(
		  tempFilterBy,
		  function (value: any, key: any) {
			  if (
			   key === '22' ||
			   key === '851' ||
			   key === '842' ||
			   key === '852' ||
			   key === '847' ||
			   key === '844' ||
			   key === 'draft' ||
			   key === 'sold'
			  ) {
				  return value;
			  }
			  return _.unescape(value);
		  }
		 );

		 setFilterBy({...decodedFilterBy, ...searchPayload});

		 setLoader(true);
		 try {
			 const tabs =
			  hasSimple || hasBusiness
			   ? ['view all', 'sold', 'issues']
			   : [
				   'view all',
				   'active',
				   'draft',
				   'unlisted',
				   'out of stock',
				   'sold',
				   'issues',
			   ];

			 const updatedTabCounts2: any = {};
			 for (const key in tabCounts2) {
				 if (tabCounts2.hasOwnProperty(key)) {
					 updatedTabCounts2[key] = {
						 ...tabCounts2[key],
						 loader: true,
					 };
				 }
			 }
			 setTabCounts2(updatedTabCounts2);

			 const requests = tabs.map(async (tab) => {
				 const response = await sendReguestColumnSearch(tab, searchPayload);

				 if (response) {
					 const total = myHelpConditionFunc(response, tab);

					 setTabCounts2((prevTabCounts2: any) => ({
						 ...prevTabCounts2,
						 [tab]: {
							 count: total,
							 loader: false,
							 tabName: tab,
						 },
					 }));
				 }
				 return response;
			 });

			 const responses = await Promise.all(requests);
			 setLoader(false);
		 } catch (error) {
			 setLoader(false);
		 }
	 }, 1000),
	 [filterBy, metadata, tabCounts2, activeTab, sortBy]
	);


	async function sendReguestColumnSearch(tab: string, searchPayload: any) {
		const tabs = [
			'view all',
			'active',
			'draft',
			'unlisted',
			'out_of_stock',
			'sold',
			'issues_empty_ids',
			'issues_status_selected_no_active_listing_found',
			'issues_status_not_selected_active_listing_found',
			'issues_sold_status_active_listing_found',
			'issues_status_selected_no_listing_found',
		];
		const filteredKeys = Object.keys(searchPayload).filter(
		 (item: string) => !tabs.includes(item)
		);

		const filteredObj: any = {};
		filteredKeys?.forEach((item) => {
			filteredObj[item] = searchPayload[item];
		});

		const filterByForRequestCheckBox: any = {};

		if (checkbox1Checked) {
			filterByForRequestCheckBox['draft'] = false;
		}

		if (checkbox2Checked) {
			filterByForRequestCheckBox['sold'] = false;
		}

		const issuesFilterBy: any = {};

		if (issuesValue.value === 'Empty IDs - Status selected, no ID saved') {
			issuesFilterBy['issues_empty_ids'] = true;
		} else if (
		 issuesValue.value ===
		 'Marketplace status selected, no ACTIVE listing found'
		) {
			issuesFilterBy['issues_status_selected_no_active_listing_found'] = true;
		} else if (
		 issuesValue.value ===
		 'Marketplace status NOT selected, ACTIVE listing found'
		) {
			issuesFilterBy['issues_status_not_selected_active_listing_found'] = true;
		} else if (
		 issuesValue.value === 'Sold status selected, ACTIVE listing found'
		) {
			issuesFilterBy['issues_sold_status_active_listing_found'] = true;
		} else if (
		 issuesValue.value === 'Marketplace status selected, NO listing found'
		) {
			issuesFilterBy['issues_status_selected_no_listing_found'] = true;
		}
		const response = await instanceAxios.post('listings/list', {
			metadata: {offset: 0, limit: metadata.limit || 10},
			filter_by: {
				...filteredObj,
				...(tab !== 'view all' &&
				 tab !== 'out of stock' &&
				 tab !== 'issues' && {[tab]: true}),
				...(tab === 'out of stock' && {
					['out_of_stock']: true,
				}),
				...(tab === 'view all' && filterByForRequestCheckBox),
				...(tab === 'issues' && issuesFilterBy),
			},

			options,
			...(Object.values(sortBy).length > 0 &&
			 !Object.values(sortBy).includes('none') && {sort_by: sortBy}),
		});

		if (response.data.data) {
			return response.data;
		}

		return null;
	}

	const handleInputChange = (col: string, value: any) => {
		if (col === 'title' || col === 'notes' || col === 'upc' || col === 'SKU') {
			if (typeof value === 'string') {
				if (col === 'SKU') {
					handleGetColumnSearchDebounced(col, value);
				}
				if (value.trim().length >= 2 || value.length === 0) {
					handleGetColumnSearchDebounced(col, value);
				}
			}
		} else {
			handleGetColumnSearchDebounced(col, value);
		}
		if (col !== 'quantity') {
			setInputValues((prev) => ({...prev, [col]: value}));
		} else {
			setInputValues((prev) => ({...prev, [col]: +value}));
		}

	};

	const handleGetColumnSearch = useCallback(
	 async (searchParams?: {
		 platforms?: string[];
		 not_platforms?: string[];
		 custom_marketplaces?: string[];
		 not_custom_marketplaces?: string[];
	 }) => {
		 const codeMapper: any = {
			 SOLD: '539.1',
			 eBay: '539.2',
			 Etsy: '539.3',
			 Poshmark: '539.4',
			 Mercari: '539.5',
			 Tradesy: '539.6',
			 Amazon: '539.7',
			 Grailed: '539.8',
			 Facebook: '539.9',
			 Depop: '539.11',
			 Kidizen: '539.12',
			 Shopify: '539.13',
			 Instagram: '539.14',
			 Heroine: '539.15',
			 Listing_party: '539.16',
			 Vestiaire: '539.17',
		 };
		 customMarketplaces.forEach((item: any) => {
			 const {title, code} = item.platform;
			 codeMapper[`Custom${title}`] = code[0];
		 });

		 let searchPayload: any = {...filterBy};
		 if (searchParams) {
			 for (let [col, value] of Object.entries(searchParams)) {
				 localStorage.removeItem('currentPageNumber');

				 if (value !== undefined && value?.length > 0) {
					 const platformCodes = value.map((platform) => {
						 platform = platform === 'Listing Party' ? 'Listing_party' : platform;

						 if (platform === '') return;
						 return codeMapper[platform];
					 });

					 searchPayload = {
						 ...searchPayload,
						 [col]: platformCodes,
					 };
				 } else {
					 delete searchPayload[col];
				 }
			 }
		 }

		 setLoader(true);

		 try {
			 const tabs = [
				 'view all',
				 'active',
				 'draft',
				 'unlisted',
				 'out of stock',
				 'sold',
				 'issues',
			 ];

			 const updatedTabCounts2: any = {};
			 for (const key in tabCounts2) {
				 if (tabCounts2.hasOwnProperty(key)) {
					 updatedTabCounts2[key] = {
						 ...tabCounts2[key],
						 loader: true,
					 };
				 }
			 }
			 setTabCounts2(updatedTabCounts2);

			 const requests = tabs.map(async (tab) => {
				 const response = await sendReguestColumnSearch(tab, searchPayload);

				 if (response) {
					 const {total} = response.metadata;

					 setTabCounts2((prevTabCounts2: any) => ({
						 ...prevTabCounts2,
						 [tab]: {
							 count: total,
							 loader: false,
							 tabName: tab,
						 },
					 }));

					 const issuesDropdownItems = [
						 'issues_sold_status_active_listing_found',
						 'issues_status_not_selected_active_listing_found',
						 'issues_status_selected_no_active_listing_found',
						 'issues_empty_ids',
						 'issues_status_selected_no_listing_found',
					 ];

					 const hasMatchingString = Object.keys(response.filter_by).some(
					  (value) => {
						  return issuesDropdownItems.some((dpItem) =>
						   value.includes(dpItem)
						  );
					  }
					 );

					 const myActiveTab =
					  activeTab === 'out of stock'
					   ? 'out_of_stock'
					   : activeTab === 'issues'
						? 'issues_empty_ids'
						: activeTab;

					 if (tab === 'view all' && activeTab === 'view all') {
						 setLoader(false);
						 setTableData(response.data);
						 setPageCount(
						  Math.ceil(response.metadata.total / (metadata.limit || limit))
						 );

						 setTotal(response.metadata.total);
						 setPagiInputValue('1');
						 setFilterBy(response.filter_by);

						 setCurrentPaginateNumber(1);
					 } else if (
					  tab === 'issues' &&
					  hasMatchingString &&
					  activeTab === 'issues'
					 ) {
						 setLoader(false);
						 setTableData(response.data);
						 setPageCount(
						  Math.ceil(response.metadata.total / (metadata.limit || limit))
						 );

						 setTotal(response.metadata.total);
						 setPagiInputValue('1');
						 setFilterBy(response.filter_by);

						 setCurrentPaginateNumber(1);
					 } else if (response.filter_by.hasOwnProperty(myActiveTab)) {
						 setLoader(false);
						 setTableData(response.data);
						 setPageCount(
						  Math.ceil(response.metadata.total / (metadata.limit || limit))
						 );

						 setTotal(response.metadata.total);
						 setPagiInputValue('1');
						 setFilterBy(response.filter_by);

						 setCurrentPaginateNumber(1);
					 }
				 }

				 return response;
			 });

			 const responses = await Promise.all(requests);
			 setLoader(false);
		 } catch (error) {
			 setLoader(false);
		 }
	 },
	 [filterBy, metadata, activeTab, sortBy, tabCounts2, sendReguestColumnSearch]
	);
	const sectionsCheker = [
		{
			value: 'Image',
			allowed: true,
		},
		{
			value: 'Keywords',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'UPC',
			allowed: hasSimple || hasBusiness ? false : true,
		},
		{
			value: 'Size',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Title',
			allowed: true,
		},
		{
			value: 'Brand',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Material',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Quantity',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Sales',
			allowed: (!hasPro && !is_role_allowed) ? false : true,
		},
		{
			value: 'Color',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Condition',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Price',
			allowed: true,
		},
		{
			value: 'Sold Price',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'COGS',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Shipping Cost',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Profit',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Fees',
			allowed: hasSimple ? false : true,
		},

		{
			value: 'Updated',
			allowed: true,
		},
		{
			value: 'Actions',
			allowed: true,
		},
		{
			value: 'Platforms',
			allowed: true,
		},
		{
			value: 'Created',
			allowed: true,
		},
		{
			value: 'Drafts',
			allowed: hasSimple || hasBusiness ? false : true,
		},
		{
			value: 'Date Sold',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Assignee',
			allowed:
			 hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? false : true,
		},
		{
			value: 'SKU',
			allowed: hasSimple ? false : true,
		},
		{
			value: 'Notes',
			allowed: true,
		},
		{
			value: 'Issues',
			allowed: true,
		},
		{
			value: 'Shipping Weight lb/kg',
			allowed:hasPro || (hasPro && is_role_allowed) ,
		},
		{
			value: 'Shipping Weight oz/g',
			allowed:hasPro || (hasPro && is_role_allowed) ,
		},
			{
			value: 'Shipping Package Length',
			allowed: hasPro || (hasPro && is_role_allowed),
		},
		{
			value: 'Shipping Package Width',
			allowed: hasPro || (hasPro && is_role_allowed),
		},
		{
			value: 'Shipping Package Height',
			allowed: hasPro || (hasPro && is_role_allowed),
		},
		{
			value: 'Zip',
			allowed: hasPro || (hasPro && is_role_allowed),
		},
	];

	useLayoutEffect(() => {
		try {
			const tempColumnsString = localStorage.getItem('cols');
			const tempColumns = tempColumnsString
			 ? JSON.parse(tempColumnsString)
			 : null;
			const filteredData = tempColumns?.filter((item:any)=> item?.heading !== undefined);
			if (filteredData) {
				const filteredStorage = filteredData?.filter((storageColumn: any) => {
					const matchingSection = sectionsCheker.find(
					 (section) =>
					  section.value.toLowerCase() === storageColumn.value.toLowerCase()
					);
					// If the corresponding section is found and allowed is true, keep the column
					return matchingSection && matchingSection.allowed;
				});

				localStorage.setItem('cols', JSON.stringify(filteredStorage));
			}
			// old version
			// if (tempColumns) {
			// 	const filteredStorage = tempColumns.filter((storageColumn: any) => {
			// 		const matchingSection = sectionsCheker.find(
			// 		 (section) =>
			// 		  section.value.toLowerCase() === storageColumn.value.toLowerCase()
			// 		);
			// 		// If the corresponding section is found and allowed is true, keep the column
			// 		return matchingSection && matchingSection.allowed;
			// 	});
			//
			// 	localStorage.setItem('cols', JSON.stringify(filteredStorage));
			// }
		} catch (e) {
			console.log(e, 'error');
		}
	}, []);

	async function sendReguestForSearchTags() {
		try {
			const tabsKeys = tabs.map((tabObject) => tabObject.tab);
			const filterByForRequest = Object.keys(filterBy).reduce((result, key) => {
				if (!tabsKeys.includes(key) && tabsKeys.includes('out_of_stock')) {
					result[key] = filterBy[key as keyof typeof filterBy];
				}
				return result;
			}, {} as typeof filterBy);
			const issuesFilterBy: any = {};

			if (issuesValue.value === 'Empty IDs - Status selected, no ID saved') {
				issuesFilterBy['issues_empty_ids'] = true;
			} else if (
			 issuesValue.value ===
			 'Marketplace status selected, no ACTIVE listing found'
			) {
				issuesFilterBy['issues_status_selected_no_active_listing_found'] = true;
			} else if (
			 issuesValue.value ===
			 'Marketplace status NOT selected, ACTIVE listing found'
			) {
				issuesFilterBy['issues_status_not_selected_active_listing_found'] =
				 true;
			} else if (
			 issuesValue.value === 'Sold status selected, ACTIVE listing found'
			) {
				issuesFilterBy['issues_sold_status_active_listing_found'] = true;
			} else if (
			 issuesValue.value === 'Marketplace status selected, NO listing found'
			) {
				issuesFilterBy['issues_status_selected_no_listing_found'] = true;
			}

			setLoader(true);
			const response = await instanceAxios.post('/listings/list', {
				filter_by: {
					...filterByForRequest,
					...(tab === 'issues' && issuesFilterBy),
				},

				...(Object.values(sortBy).length > 0 &&
				 !Object.values(sortBy).includes('none') && {sort_by: sortBy}),

				metadata: {offset: 0, limit: metadata.limit || 10},

				options: options,
			});

			if (response.status === 200) {
				if (activeTab === 'view all') {
					setLoader(false);

					setTableData(response.data.data);
					setPageCount(
					 Math.ceil(response.data.metadata.total / (metadata.limit || limit))
					);
					setTotal(response.data.metadata.total);
					setPagiInputValue('1');
				}
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
		}
	}

	const handleGetDataPerPage = async (num: number) => {
		if (num !== metadata.limit) {
			getInitialTabTotals(num);
		}
		try {
			setLoader(true);

			const response = await instanceAxios.post('/listings/list', {
				metadata: {offset: metadata.offset || 0, limit: num},

				filter_by: {...filterBy},

				options,
				...(Object.values(sortBy).length > 0 &&
				 !Object.values(sortBy).includes('none') && {sort_by: sortBy}),
			});

			if (response.data) {
				const {offset, limit, total} = response.data.metadata;
				setPageCount(Math.ceil(response.data.metadata.total / limit));

				setTotal(total);
				setMetadata({offset, limit});
				setLoader(false);

				setTableData(response.data.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleToggleGridView = (value: boolean) => {
		if (saveActive && value) {
			dispatch(setSecureModalWhenChangePage(true));
			dispatch(setShowGridMessage(true))
		} else {
			setIsBulkEdit(false)
			setGridView(value);
		}
	};

	const getInitialTabTotals = async (num: number | null = null) => {
		try {
			const newTabCounts2: any = {};
			const localTab = hasSimple || hasBusiness ? simpleBusinessModeTabs : tabs;

			localTab?.forEach((filter) => {
				newTabCounts2[filter.tab] = {
					count: 0,
					loader: true,
					tabName: filter.tab,
				};
			});

			setTabCounts2({...newTabCounts2});

			const filterByParam = queryParams.get('filter_by');
			let decodedFilterBy: any;
			if (filterByParam) {
				decodedFilterBy = JSON.parse(decodeURIComponent(filterByParam));
			}

			propertiesToRemove?.forEach((property) => {
				if (decodedFilterBy?.hasOwnProperty(property)) {
					delete decodedFilterBy[property];
				}
			});

			const stringTabs = [
				'view all',
				'active',
				'draft',
				'unlisted',
				'out_of_stock',
				'sold',
			];

			const filterByForRequest = Object.keys(filterBy).reduce((result, key) => {
				if (!stringTabs.includes(key) && key !== 'search_everything_type') {
					result[key] = filterBy[key as keyof typeof filterBy];
				}
				return result;
			}, {} as typeof filterBy);

			const requestPromises = localTab.slice(0, -1).map(async (filter) => {
				try {
					const filterByForRequestCheckBox: any = {};

					if (checkbox1Checked) {
						filterByForRequestCheckBox['draft'] = false;
					}

					if (checkbox2Checked) {
						filterByForRequestCheckBox['sold'] = false;
					}

					const issuesFilterBy: any = {};

					if (
					 issuesValue.value === 'Empty IDs - Status selected, no ID saved'
					) {
						issuesFilterBy['issues_empty_ids'] = true;
					} else if (
					 issuesValue.value ===
					 'Marketplace status selected, no ACTIVE listing found'
					) {
						issuesFilterBy['issues_status_selected_no_active_listing_found'] =
						 true;
					} else if (
					 issuesValue.value ===
					 'Marketplace status NOT selected, ACTIVE listing found'
					) {
						issuesFilterBy['issues_status_not_selected_active_listing_found'] =
						 true;
					} else if (
					 issuesValue.value === 'Sold status selected, ACTIVE listing found'
					) {
						issuesFilterBy['issues_sold_status_active_listing_found'] = true;
					} else if (
					 issuesValue.value ===
					 'Marketplace status selected, NO listing found'
					) {
						issuesFilterBy['issues_status_selected_no_listing_found'] = true;
					}

					if (searchItem?.length <= 30) {
						const response = await instanceAxios.post('/listings/list', {
							filter_by: {
								...(filter.tab === 'view all' && filterByForRequestCheckBox),
								...(num !== null && {...filterByForRequest}),
								...(filter.tab !== 'view all' &&
								 filter.tab !== 'out of stock' &&
								 filter.tab !== 'issues' && {[filter.tab]: true}),

								...(filter.tab === 'out of stock' && {
									['out_of_stock']: true,
								}),
								// ...(filter.tab === 'issues' && issuesFilterBy),
								...decodedFilterBy,
							},

							options: options,
							metadata: {...metadata, limit: num || metadata.limit},
						});

						const rMetadata = response.data.metadata;

						const count = rMetadata.total; /* COUNT === TOTAL */

						setAllTableData((prevTableData: any) => ({
							...prevTableData,
							...(filter.tab === 'out of stock' && {
								['out_of_stock']: {
									data: response.data.data,
									pageCount: Math.ceil(count / (rMetadata.limit || limit)),
								},
							}),
							...(filter.tab === 'view all' && {
								['all']: {
									data: response.data.data,

									pageCount: Math.ceil(count / (rMetadata.limit || limit)),
								},
							}),
							...(filter.tab !== 'out of stock' &&
							 filter.tab !== 'view all' && {
								 [filter.tab]: {
									 data: response.data.data,

									 pageCount: Math.ceil(count / (rMetadata.limit || limit)),
								 },
							 }),
						}));

						if (newTabCounts2[filter.tab].loader) {
							newTabCounts2[filter.tab] = {
								count,
								loader: false,
								tabName: filter.tab,
							};

							setTabCounts2({...newTabCounts2});
						}
					} else {
						setAllTableData((prevTableData: any) => ({
							...prevTableData,
							...(filter.tab === 'out of stock' && {
								['out_of_stock']: {
									data: [],
									pageCount: 0,
								},
							}),
							...(filter.tab === 'view all' && {
								['all']: {
									data: [],

									pageCount: 0,
								},
							}),
							...(filter.tab !== 'out of stock' &&
							 filter.tab !== 'view all' && {
								 [filter.tab]: {
									 data: [],

									 pageCount: 0,
								 },
							 }),
						}));

						if (newTabCounts2[filter.tab].loader) {
							newTabCounts2[filter.tab] = {
								count: 0,
								loader: false,
								tabName: filter.tab,
							};

							setTabCounts2({...newTabCounts2});
						}

					}
				} catch (error) {
					console.error(error);

					if (newTabCounts2[filter.tab].loader) {
						newTabCounts2[filter.tab] = {
							count: 0,
							loader: false,
							tabName: filter.tab,
						};

						setTabCounts2({...newTabCounts2});
					}
				}
			});

			await Promise.all(requestPromises);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (!showIframe) {
			setIframeUpdate(false)
		}
	}, [showIframe])

	let localChecker = false;
	useEffect(() => {
		const handleMessage = (event: any) => {
			if (
			 event.origin === 'https://apptest.listperfectly.com' ||
			 event.origin === 'https://app.listperfectly.com'
			) {
				const {data: {target = '', data = ''} = {}} = event || {};
				if (target === 'isReloading') {
					setIframeUpdate(true)
					setIsReloadIframe(true);
					dispatch(iframeLoaderChanger(true));
					localChecker = true;
				} else if (target === 'isLoaded' && localChecker) {
					localChecker = false;
					dispatch(setRemoveShowIframe())
					setIsLoadedIframe(true);
					dispatch(iframeLoaderChanger(false));
				}
			}
		};

		// Attach the event listener when the component mounts
		window.addEventListener('message', handleMessage);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	useEffect(() => {
		dispatch(setGridViewChecker(gridView))
	},[gridView])

	useEffect(() => {
		const handleMessage = (event: any) => {
			if (
			 event.origin === 'https://apptest.listperfectly.com' ||
			 event.origin === 'https://app.listperfectly.com'
			) {
				const {data: {target = '', data = ''} = {}} = event || {};
				if (target === 'updatedDataFields') {
					if (data && tableData && allTableData) {
						const newProperties = data;
						const newImage = _get(newProperties, 'newMainImage');
						const getIdOfThatListing = _get(newProperties, 'listingId');
						const getListingTitle = _get(newProperties, 'input_133');
						const getNotes = _get(newProperties, 'input_726');
						const getPrice = _get(newProperties, 'input_22')?.replace('$', '');
						const getSKU = _get(newProperties, 'input_1');
						// const getDraft =  _get(newProperties, 'input_859.1')
						const getFees = _get(newProperties, 'input_844')?.replace('$', '');
						const getCogs = _get(newProperties, 'input_847')?.replace('$', '');
						const shippingCost = _get(newProperties, 'input_852')?.replace('$', '');
						const soldPrice = _get(newProperties, 'input_842')?.replace('$', '');
						const getQuantity = _get(newProperties, 'input_851')?.replace('$', '');
						const getBrand = _get(newProperties, 'input_206')
						const getColor = _get(newProperties, 'input_209')
						const getSize = _get(newProperties, 'input_213')
						const getKeywords = _get(newProperties, 'input_453')
						const getMaterial = _get(newProperties, 'input_82')
						const getCondition = _get(newProperties, 'input_15')
						const getUpc = _get(newProperties, 'input_704')
						const getDraft = _get(newProperties, 'input_859.1')
						const getSold = _get(newProperties, 'input_539.1')
						const getEbay = _get(newProperties, 'input_539.2')
						const getEtsy = _get(newProperties, 'input_539.3')
						const getPoshmark = _get(newProperties, 'input_539.4')
						const getMercari = _get(newProperties, 'input_539.5')
						const getAmazon = _get(newProperties, 'input_539.7')
						const getGrailed = _get(newProperties, 'input_539.8')
						const getFacebook = _get(newProperties, 'input_539.9')
						const getDepop = _get(newProperties, 'input_539.11')
						const getKitidzen = _get(newProperties, 'input_539.12')
						const getShopify = _get(newProperties, 'input_539.13')
						const getListingParty = _get(newProperties, 'input_539.16')
						const getInstagram = _get(newProperties, 'input_539.14')
						const getVestiaire = _get(newProperties, 'input_539.17')
						const getNewPlatformIdByeBay = _get(newProperties, 'input_618')
						const getNewPlatformIdByEtsy = _get(newProperties, 'input_619')
						const getNewPlatformIdByPoshmark = _get(newProperties, 'input_620')
						const getNewPlatformIdByMercari = _get(newProperties, 'input_621')
						const getNewPlatformIdByTradesy = _get(newProperties, 'input_833')
						const getNewPlatformIdByAmazon = _get(newProperties, 'input_836')
						const getNewPlatformIdByGrailed = _get(newProperties, 'input_839')
						const getNewPlatformIdByFacebook = _get(newProperties, 'input_840')
						const getNewPlatformIdByDepop = _get(newProperties, 'input_841')
						const getNewPlatformIdByKidizen = _get(newProperties, 'input_853')
						const getNewPlatformIdByShopify = _get(newProperties, 'input_855')
						const getNewPlatformIdByInstagram = _get(newProperties, 'input_856')
						const getNewPlatformIdByListingParty = _get(newProperties, 'input_539.16')
						const getNewPlatformIdByVestiaire = _get(newProperties, 'input_885')
						//
						const matchingPropertyValue1 = _.find(newProperties, (value: any, key: any) => key.startsWith('custom_1_'));
						const matchingPropertyName1 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('custom_1_'));
						const keyWithoutPrefixName1 = matchingPropertyName1 ? matchingPropertyName1.substring('custom_1_'.length) : undefined;
						const matchingPropertyValue2 = _.find(newProperties, (value: any, key: any) => key.startsWith('custom_2_'));
						const matchingPropertyName2 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('custom_2_'));
						const keyWithoutPrefixName2 = matchingPropertyName2 ? matchingPropertyName2.substring('custom_2_'.length) : undefined;
						const matchingPropertyValue3 = _.find(newProperties, (value: any, key: any) => key.startsWith('custom_3_'));
						const matchingPropertyName3 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('custom_3_'));
						const keyWithoutPrefixName3 = matchingPropertyName3 ? matchingPropertyName3.substring('custom_3_'.length) : undefined;
						const matchingPropertyValue4 = _.find(newProperties, (value: any, key: any) => key.startsWith('custom_4_'));
						const matchingPropertyName4 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('custom_4_'));
						const keyWithoutPrefixName4 = matchingPropertyName4 ? matchingPropertyName4.substring('custom_4_'.length) : undefined;
						const matchingPropertyValue5 = _.find(newProperties, (value: any, key: any) => key.startsWith('custom_5_'));
						const matchingPropertyName5 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('custom_5_'));
						const keyWithoutPrefixName5 = matchingPropertyName5 ? matchingPropertyName5.substring('custom_5_'.length) : undefined;

						const matchingPropertyValueId1 = _.find(newProperties, (value: any, key: any) => key.startsWith('idcustom_1_'));
						const matchingPropertyNameId1 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('idcustom_1_'));
						const keyWithoutPrefixNameId1 = matchingPropertyNameId1 ? matchingPropertyNameId1.substring('idcustom_1_'.length) : undefined;
						const matchingPropertyValueId2 = _.find(newProperties, (value: any, key: any) => key.startsWith('idcustom_2_'));
						const matchingPropertyNameId2 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('idcustom_2_'));
						const keyWithoutPrefixNameId2 = matchingPropertyNameId2 ? matchingPropertyNameId2.substring('idcustom_2_'.length) : undefined;
						const matchingPropertyValueId3 = _.find(newProperties, (value: any, key: any) => key.startsWith('idcustom_3_'));
						const matchingPropertyNameId3 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('idcustom_3_'));
						const keyWithoutPrefixNameId3 = matchingPropertyNameId3 ? matchingPropertyNameId3.substring('idcustom_3_'.length) : undefined;
						const matchingPropertyValueId4 = _.find(newProperties, (value: any, key: any) => key.startsWith('idcustom_4_'));
						const matchingPropertyNameId4 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('idcustom_4_'));
						const keyWithoutPrefixNameId4 = matchingPropertyNameId4 ? matchingPropertyNameId4.substring('idcustom_4_'.length) : undefined;
						const matchingPropertyValueId5 = _.find(newProperties, (value: any, key: any) => key.startsWith('idcustom_5_'));
						const matchingPropertyNameId5 = _.findKey(newProperties, (value: any, key: any) => key.startsWith('idcustom_5_'));
						const keyWithoutPrefixNameId5 = matchingPropertyNameId5 ? matchingPropertyNameId5.substring('idcustom_5_'.length) : undefined;

						const newObject = {
							...(getListingTitle !== undefined ? {133: getListingTitle} : {}),
							...(newImage !== undefined ? {newImage: newImage} : {}),
							...(getListingTitle !== undefined ? {newTitle: getListingTitle} : {}),
							...(getNotes !== undefined ? {726: getNotes} : {}),
							...(getNotes !== undefined ? {newNotes: getNotes} : {}),
							...(getPrice !== undefined ? {newPrice: getPrice} : {}),
							...(getPrice !== undefined ? {22: getPrice} : {}),
							...(getNewPlatformIdByeBay !== undefined ? {newPlatformIdByeBay: getNewPlatformIdByeBay} : {}),
							...(getNewPlatformIdByeBay !== undefined ? {618: getNewPlatformIdByeBay} : {}),
							...(getNewPlatformIdByEtsy !== undefined ? {newPlatformIdByEtsy: getNewPlatformIdByEtsy} : {}),
							...(getNewPlatformIdByEtsy !== undefined ? {619: getNewPlatformIdByEtsy} : {}),
							...(getNewPlatformIdByPoshmark !== undefined ? {newPlatformIdByPoshmark: getNewPlatformIdByPoshmark} : {}),
							...(getNewPlatformIdByPoshmark !== undefined ? {620: getNewPlatformIdByPoshmark} : {}),
							...(getNewPlatformIdByMercari !== undefined ? {newPlatformIdByMercari: getNewPlatformIdByMercari} : {}),
							...(getNewPlatformIdByMercari !== undefined ? {621: getNewPlatformIdByMercari} : {}),
							...(getNewPlatformIdByTradesy !== undefined ? {newPlatformIdByTradesy: getNewPlatformIdByTradesy} : {}),
							...(getNewPlatformIdByTradesy !== undefined ? {833: getNewPlatformIdByTradesy} : {}),
							...(getNewPlatformIdByAmazon !== undefined ? {newPlatformIdByAmazon: getNewPlatformIdByAmazon} : {}),
							...(getNewPlatformIdByAmazon !== undefined ? {836: getNewPlatformIdByAmazon} : {}),
							...(getNewPlatformIdByGrailed !== undefined ? {newPlatformIdByGrailed: getNewPlatformIdByGrailed} : {}),
							...(getNewPlatformIdByGrailed !== undefined ? {839: getNewPlatformIdByGrailed} : {}),
							...(getNewPlatformIdByFacebook !== undefined ? {newPlatformIdByFacebook: getNewPlatformIdByFacebook} : {}),
							...(getNewPlatformIdByFacebook !== undefined ? {840: getNewPlatformIdByFacebook} : {}),
							...(getNewPlatformIdByDepop !== undefined ? {newPlatformIdByDepop: getNewPlatformIdByDepop} : {}),
							...(getNewPlatformIdByDepop !== undefined ? {841: getNewPlatformIdByDepop} : {}),
							...(getNewPlatformIdByKidizen !== undefined ? {newPlatformIdByKidizen: getNewPlatformIdByKidizen} : {}),
							...(getNewPlatformIdByKidizen !== undefined ? {853: getNewPlatformIdByKidizen} : {}),
							...(getNewPlatformIdByShopify !== undefined ? {newPlatformIdByShopify: getNewPlatformIdByShopify} : {}),
							...(getNewPlatformIdByShopify !== undefined ? {855: getNewPlatformIdByShopify} : {}),
							...(getNewPlatformIdByInstagram !== undefined ? {newPlatformIdByInstagram: getNewPlatformIdByInstagram} : {}),
							...(getNewPlatformIdByInstagram !== undefined ? {856: getNewPlatformIdByInstagram} : {}),
							...(getNewPlatformIdByListingParty !== undefined ? {newPlatformIdByListingParty: getNewPlatformIdByListingParty} : {}),
							...(getNewPlatformIdByVestiaire !== undefined ? {newPlatformIdByVestiaire: getNewPlatformIdByVestiaire} : {}),
							...(getNewPlatformIdByVestiaire !== undefined ? {885: getNewPlatformIdByVestiaire} : {}),
							//
							...(keyWithoutPrefixName1 !== undefined ? {[`newCustom${keyWithoutPrefixName1}`]: matchingPropertyValue1 ? matchingPropertyValue1 : null} : {}),
							...(keyWithoutPrefixName2 !== undefined ? {[`newCustom${keyWithoutPrefixName2}`]: matchingPropertyValue2 ? matchingPropertyValue2 : null} : {}),
							...(keyWithoutPrefixName3 !== undefined ? {[`newCustom${keyWithoutPrefixName3}`]: matchingPropertyValue3 ? matchingPropertyValue3 : null} : {}),
							...(keyWithoutPrefixName4 !== undefined ? {[`newCustom${keyWithoutPrefixName4}`]: matchingPropertyValue4 ? matchingPropertyValue4 : null} : {}),
							...(keyWithoutPrefixName5 !== undefined ? {[`newCustom${keyWithoutPrefixName5}`]: matchingPropertyValue5 ? matchingPropertyValue5 : null} : {}),
							//
							...(keyWithoutPrefixNameId1 !== undefined ? {[`newPlatformIdByCustom${keyWithoutPrefixNameId1}`]: matchingPropertyValueId1 ? matchingPropertyValueId1 : null} : {}),
							...(keyWithoutPrefixNameId2 !== undefined ? {[`newPlatformIdByCustom${keyWithoutPrefixNameId2}`]: matchingPropertyValueId2 ? matchingPropertyValueId2 : null} : {}),
							...(keyWithoutPrefixNameId3 !== undefined ? {[`newPlatformIdByCustom${keyWithoutPrefixNameId3}`]: matchingPropertyValueId3 ? matchingPropertyValueId3 : null} : {}),
							...(keyWithoutPrefixNameId4 !== undefined ? {[`newPlatformIdByCustom${keyWithoutPrefixNameId4}`]: matchingPropertyValueId4 ? matchingPropertyValueId4 : null} : {}),
							...(keyWithoutPrefixNameId5 !== undefined ? {[`newPlatformIdByCustom${keyWithoutPrefixNameId5}`]: matchingPropertyValueId5 ? matchingPropertyValueId5 : null} : {}),
							//
							...(getSKU !== undefined ? {newSku: getSKU} : {}),
							...(getSKU !== undefined ? {1: getSKU} : {}),
							...(getKeywords !== undefined ? {newKeywords: getKeywords?.split(', ')} : {}),
							...(getKeywords !== undefined ? {453: getKeywords?.split(', ')} : {}),
							...(getFees !== undefined ? {newFees: getFees} : {}),
							...(getFees !== undefined ? {844: getFees} : {}),
							...(getCogs !== undefined ? {newCogs: getCogs} : {}),
							...(getCogs !== undefined ? {847: getCogs} : {}),
							...(shippingCost !== undefined ? {newShippingCost: shippingCost} : {}),
							...(shippingCost !== undefined ? {852: shippingCost} : {}),
							...(soldPrice !== undefined ? {newSold_price: soldPrice} : {}),
							...(soldPrice !== undefined ? {842: soldPrice} : {}),
							...(getQuantity !== undefined ? {newQuantity: getQuantity} : {}),
							...(getQuantity !== undefined ? {851: getQuantity} : {}),
							...(getBrand !== undefined ? {newBrand: getBrand} : {}),
							...(getBrand !== undefined ? {206: getBrand} : {}),
							...(getColor !== undefined ? {newColor: getColor} : {}),
							...(getColor !== undefined ? {209: getColor} : {}),
							...(getSize !== undefined ? {newSize: getSize} : {}),
							...(getSize !== undefined ? {213: getSize} : {}),
							...(getMaterial !== undefined ? {newMaterial: getMaterial} : {}),
							...(getMaterial !== undefined ? {82: getMaterial} : {}),
							...(getCondition !== undefined ? {newCondition: getCondition} : {}),
							...(getCondition !== undefined ? {15: getCondition} : {}),
							...(getUpc !== undefined ? {newUpc: getUpc} : {}),
							...(getUpc !== undefined ? {704: getUpc} : {}),
							...(getDraft !== undefined ? {newDraft: getDraft ? 'Draft' : null} : {}),
							...(getDraft !== undefined ? {859.1: getDraft ? 'Draft' : null} : {}),
							...(getEbay !== undefined ? {neweBay: getEbay ? 'eBay' : null} : {}),
							...(getEbay !== undefined ? {539.2: getEbay ? 'eBay' : null} : {}),
							...(getEtsy !== undefined ? {newEtsy: getEtsy ? 'Etsy' : null} : {}),
							...(getEtsy !== undefined ? {539.3: getEtsy ? 'Etsy' : null} : {}),
							...(getPoshmark !== undefined ? {newPoshmark: getPoshmark ? 'Poshmark' : null} : {}),
							...(getPoshmark !== undefined ? {539.4: getPoshmark ? 'Poshmark' : null} : {}),
							...(getMercari !== undefined ? {newMercari: getMercari ? 'Mercari' : null} : {}),
							...(getMercari !== undefined ? {539.5: getMercari ? 'Mercari' : null} : {}),
							...(getAmazon !== undefined ? {newAmazon: getAmazon ? 'Amazon' : null} : {}),
							...(getAmazon !== undefined ? {539.7: getAmazon ? 'Amazon' : null} : {}),
							...(getGrailed !== undefined ? {newGrailed: getGrailed ? 'Grailed' : null} : {}),
							...(getGrailed !== undefined ? {539.8: getGrailed ? 'Grailed' : null} : {}),
							...(getFacebook !== undefined ? {newFacebook: getFacebook ? 'Facebook' : null} : {}),
							...(getFacebook !== undefined ? {539.9: getFacebook ? 'Facebook' : null} : {}),
							...(getDepop !== undefined ? {newDepop: getDepop ? 'Depop' : null} : {}),
							...(getDepop !== undefined ? {539.11: getDepop ? 'Depop' : null} : {}),
							...(getKitidzen !== undefined ? {newKidizen: getKitidzen ? 'Kidizen' : null} : {}),
							...(getKitidzen !== undefined ? {539.12: getKitidzen ? 'Kidizen' : null} : {}),
							...(getShopify !== undefined ? {newShopify: getShopify ? 'Shopify' : null} : {}),
							...(getShopify !== undefined ? {539.13: getShopify ? 'Shopify' : null} : {}),
							...(getInstagram !== undefined ? {newInstagram: getInstagram ? 'Instagram' : null} : {}),
							...(getInstagram !== undefined ? {539.14: getInstagram ? 'Instagram' : null} : {}),
							...(getListingParty !== undefined ? {newListing_party: getListingParty ? 'Listing Party' : null} : {}),
							...(getListingParty !== undefined ? {539.16: getListingParty ? 'Listing Party' : null} : {}),
							...(getVestiaire !== undefined ? {newVestiaire: getVestiaire ? 'Vestiaire' : null} : {}),
							...(getVestiaire !== undefined ? {539.17: getVestiaire ? 'Vestiaire' : null} : {}),
							...(getSold !== undefined ? {newSOLD: getSold ? 'SOLD' : null} : {}),
							...(getSold !== undefined ? {539.1: getSold ? 'SOLD' : null} : {}),
						};

						const updatedTableData = tableData.map((obj) => {
							if (obj.id == getIdOfThatListing) {
								return {
									...obj,
									...newObject,
								};
							}
							return obj;
						});
						const updateListingPropertiesInAllTabs = (getIdOfThatListing: any, newObject: any) => {
							setAllTableData((prevData: any) => {
								// Create a copy of the previous state
								const newData = {...prevData};

								// Iterate over all tabs and update the specified properties of the listing if it exists
								Object.keys(newData).forEach((tabName) => {
									newData[tabName].data = newData[tabName].data.map((listing: any) =>
									 listing.id == getIdOfThatListing ? {...listing, ...newObject} : listing
									);
								});

								return newData;
							});
						};

						updateListingPropertiesInAllTabs(getIdOfThatListing, newObject)
						// Update the state with the modified tableData
						setTableData(updatedTableData);
					}
				}
			}
		};

		// Attach the event listener when the component mounts
		window.addEventListener('message', handleMessage);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [tableData, allTableData]);

	useEffect(() => {
		if (isLoadedIframe && isReloadIframe) {
			dispatch(setRemoveShowIframe());
			dispatch(iframeLoaderChanger(false));
			dispatch(setIframeLink(''));
			setIsReloadIframe(false)
			setIsLoadedIframe(false)
		}
	}, [isLoadedIframe, isReloadIframe])

	useEffect(() => {
		const bodyElement = document.body;
		const handleAttributeChange = () => {
			const connectionWizardMarkets = bodyElement.getAttribute('lp_custom_chrome_extension_marketplaces_connected');
			try {
				if (connectionWizardMarkets) {
					// Decode HTML entities using Lodash
					setHasExtensionSupport(true);
					const decodedData = _.unescape(connectionWizardMarkets);
					const parsedArray = JSON.parse(decodedData);
					// Check if the new data is different from the current state
					if (JSON.stringify(parsedArray) !== JSON.stringify(getMarketsFromExtension)) {
						setGetMarketsFromExtension(parsedArray);
					}
				}
				else {
					setHasExtensionSupport(false);
				}
			} catch (e) {
				console.log('error', e);
			}
		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_chrome_extension_marketplaces_connected'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, [getMarketsFromExtension]);

	useEffect(() => {
		const bodyElement = document.body;
		const handleAttributeChange = () => {
			const extensionVersion = bodyElement.getAttribute('lp_custom_chrome_extension_version');
			try {
				if (extensionVersion) {
					setHasExtensionInstall(true)
				}
				else {
					setHasExtensionInstall(false)
				}
			} catch (e) {
				console.log('error', e);
			}
		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_chrome_extension_version'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, []);

	const checkForError = ()=>{
		const hasConnectionError = getMarketsFromExtension?.some((item: any) => item.hasOwnProperty('connection_status_extension_error'));
		if (hasConnectionError) {
			setIncludesError(true)
		}else{
			setIncludesError(false)
		}
	}

	useEffect(() => {
		checkForError();
	}, [getMarketsFromExtension]);

	useEffect(() => {
		getInitialTabTotals();
	}, []);

	useEffect(() => {
		getData();
	}, [checkbox1Checked, checkbox2Checked, issuesValue]);

	useEffect(() => {
		const threshold = 50;
		const handleScroll = () => {
			const elementStatus = document.querySelector('.LPE_status_container .LPE_status');
			const elementIssueFinder = document.querySelector('#syncButton');
			const scrollPosition = window.scrollY;
			if (enabledScroll && gridView) {
				if (scrollPosition > threshold) {
					setHideElement(true);
					if (elementStatus) {
						elementStatus.classList.add('hideElementOnScroll')
					}
					if (elementIssueFinder) {
						elementIssueFinder.classList.add('hideElementOnScroll')
					}
				} else {
					setHideElement(false);
					if (elementStatus) {
						elementStatus.classList.remove('hideElementOnScroll')
					}
					if (elementIssueFinder) {
						elementIssueFinder.classList.remove('hideElementOnScroll')
					}
				}
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [enabledScroll, gridView]);

	useEffect(() => {
		const elementStatus = document.querySelector('.LPE_status_container .LPE_status');
		const elementIssueFinder = document.querySelector('#syncButton');
		const tableElement = document.getElementById('catalogPageTable') as HTMLTableElement | null;
		let elementHidded = true;
		const handleScroll = () => {
			if (tableElement && !gridView) {
				// Check if the scroll position of the table is greater than 20 pixels
				const isScrolled = tableElement.scrollTop > 20;
				const isTableScrollable = tableElement.scrollHeight - tableElement.clientHeight;
				const value = elementHidded ? 250 : 130
				if (isTableScrollable > value) {
					if (isScrolled) {
						setHideElement(true);
						elementHidded = false
						if(screenWidth > 1440) {
							tableElement.style.maxHeight = 'calc(100vh - 50px)'
						} else {
							tableElement.style.maxHeight = 'calc((100vh - 50px) * 1.25)'
						}
						// tableElement.style.maxHeight = 'calc((100vh - 50px) * 1.25)'

						if (elementStatus) {
							elementStatus.classList.add('hideElementOnScroll');
						}
						if (elementIssueFinder) {
							elementIssueFinder.classList.add('hideElementOnScroll');
						}
					} else {
						elementHidded = true
						setHideElement(false);
						if (elementStatus) {
							elementStatus.classList.remove('hideElementOnScroll');
						}
						if (elementIssueFinder) {
							elementIssueFinder.classList.remove('hideElementOnScroll');
						}
					}
				} else {
					setHideElement(false);
				}
			}
		};

		if (tableElement) {
			tableElement.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (tableElement) {
				tableElement.removeEventListener('scroll', handleScroll);
			}
		};
	}, [filterBy, loader, gridView, activeTab]);


	useEffect(() => {
		if (!hideElement && !gridView) {
			const tableElement = document.getElementById('catalogPageTable') as HTMLTableElement | null;
			if (tableElement) {
				if(screenWidth > 1440) {
					tableElement.style.maxHeight = 'calc(100vh - 170px)'
				} else {
					tableElement.style.maxHeight = 'calc((100vh - 146px) * 1.25)'
				}
				// tableElement.style.maxHeight = 'calc((100vh - 146px) * 1.25)'

			}
		}
	}, [hideElement, gridView])

	useEffect(() => {
		const element = document.getElementById('catalogPageTable');
		let distance: any = 0;
		if (element) {
			const params = element.getBoundingClientRect();
			distance = params.top;
		}

		const fullHeight = document.body.clientHeight;
		const visibleHeight = window.innerHeight;
		if (distance) {
			if (fullHeight - visibleHeight > distance) {
				setEnabledScroll(true);
			} else {
				setEnabledScroll(false);
			}
		}
	}, [filterBy, loader, gridView, activeTab]); //

	useEffect(() => {
		localStorage.setItem('gridView', JSON.stringify(gridView));
	}, [gridView]);

	const handleOpenLinkInNewTab = () => {
		if (candidatIframeLink) {
			window.open(candidatIframeLink, '_blank');
			dispatch(setResetCandidatLink());
			dispatch(setResetPureCandidantLink());
		}
	};

	const handleReplaceCurrent = () => {
		if (candidatIframeLink) {
			dispatch(setReplaceCandidat());
			dispatch(setResetCandidatLink());
			dispatch(setResetPureCandidantLink());
		}
	};

	const debounceSearchItem = useDebounce(pagiInputValue, 1000);

	useEffect(() => {
		if (debounceSearchItem !== '1') {
			handleChangeByInput(pagiInputValue);
		}
	}, [debounceSearchItem]);

	useEffect(() => {
		dispatch(setRoles(roles));
	}, [dispatch]);

	useEffect(() => {
		if ((tableData?.length && !cachedTableData) || isSaved || iframeUpdate) {
			setCachedTableData(tableData);
		}
	}, [tableData, cachedTableData, iframeUpdate, isSaved]);

	useEffect(() => {
		if (tableData?.length) {
			setCachedTableData(tableData);
		}
	}, [tab]);

	useEffect(() => {
		if (tableData?.length) {
			dispatch(setFilterBySort(true));
		}
	}, [filterBy, sortBy]);

	useEffect(() => {
		if (tableData?.length && filterSortChange) {
			setCachedTableData(tableData);
		}
	}, [filterBy, sortBy, filterSortChange, tableData]);

	useEffect(() => {
		if (tableData?.length && isInputChanged) {
			setCachedTableData(tableData);
		}
	}, [pagiInputValue, tableData, isBulkEdit, isInputChanged]);

	useEffect(() => {
		if (tableData?.length) {
			setCachedTableData(tableData);
		}
	}, [editModalShow])

	useEffect(() => {
		if ((isCanceled && cachedTableData)) {
			setTableData(cachedTableData);
			setCachedTableData(null);
		}
	}, [isCanceled]);


	let decodedFilterByCreated: any;
	const functionGetValueFromUrl = (columnValue: string) => {
		const filterByParam = queryParams.get('filter_by');
		if (filterByParam) {
			decodedFilterByCreated = JSON.parse(decodeURIComponent(filterByParam));
		}
		if(columnValue === 'createdStart') {
			const start =  _.get(decodedFilterByCreated?.created_at, 'start');
			if(start) return  new Date(start)
			else return null
		}
		else if(columnValue === 'createdEnd') {
			const end =  _.get(decodedFilterByCreated?.created_at, 'end');
			if(end) return  new Date(end)
			else return null
		}
		else {
			return '';
		}
	};

	const [startChangedCreated, setStartChangedCreated] = useState(false);
	const [endChangedCreated, setEndChangedCreated] = useState(false);
	const [createdCloseClicked, setCreatedCloseClicked] = useState(false);
	const [startDateCreated, setStartDateCreated] = useState<any>(inputValues['createdStart'] || (!startChangedCreated && functionGetValueFromUrl('createdStart')) || null);
	const [endDateCreated, setEndDateCreated] = useState<any>(inputValues['createdEnd'] || (!endChangedCreated && functionGetValueFromUrl('createdEnd')) || null);
	const [dateRangeModalShow, setDateRangeModalShow] = useState(false);

	useEffect(() => {
		if(createdCloseClicked && (startChangedCreated || endChangedCreated)) {
			if(startChangedCreated && !endChangedCreated) {
				handleInputChange('createdStart', startDateCreated);
			}
			else if(!startChangedCreated && endChangedCreated) {
				handleInputChange('createdEnd', endDateCreated);
			}
			else if(startChangedCreated && endChangedCreated){
				let fullDate: any = {};
				if (startChangedCreated) {
					fullDate.start = startDateCreated;
				}
				if (endChangedCreated) {
					fullDate.end = endDateCreated;
				}
				handleInputChange('createdFullChanged', fullDate);
			}
		}
	}, [createdCloseClicked, startChangedCreated,  endDateCreated, startDateCreated, endDateCreated]);

	useEffect(() => {
		if(!dateRangeModalShow){
			setCreatedCloseClicked(false);
		}
	}, [dateRangeModalShow]);

	const handleToggleDateRangeModalShow = ()=>{
		if (!dateRangeModalShow) {
			setDateRangeModalShow(true);
		} else {
			setDateRangeModalShow(false); // Close the modal
		}
	}


	const props = useMemo(() => {
		return {
			columns,
			handleChangeCheckbox,
			tableData,
			handleRowSelected,
			isBulkEdit,
			setFilterBy,
			setPayload,
			setTableData,
			handleSortByClick,
			loader,
			tab,
			handleGetColumnSearch,
			handleGetColumnSearchDebounced,
			updator,
			inputValues,
			handleInputChange,
			lpPlatforms,
			setLpPlatforms,
			activePlatforms,
			setActivePlatforms,
			customMarketplaces,
			setCustomMarketplaces,
			filterByArr,
			setPagiInputValue,
			isMobile,
			fetchMoreData,
			hasMore,
			setInputValues,
			setHasMore,
			setFilterByArr,
			filterBy,
			sendReguestForSearchTags,
			setSearchItem,
			setTabCounts2,
			handleFetchForSearch,
			tabCounts2,
			sendReguestColumnSearch,
			handleBulkDelete,
			handleBulkRestore,
			myHelpConditionFunc,
			gridView,
			syncTime,
			hasPro,
			hasBetaTeamRole,
			is_role_allowed,
			searchItem,
			setPlatformChangesMade,
			handleTogglePlatformsModalShow,
			platformsModalShow,
			setRetryActive,
			retryActive,
			saveActive,
			setSaveActive,
			iframeUpdate,
			handleToggleDateRangeModalShow,
			dateRangeModalShow,
			startChangedCreated,
			endChangedCreated,
			startDateCreated,
			endDateCreated,
			setStartChangedCreated,
			setEndChangedCreated,
			setStartDateCreated,
			setEndDateCreated,
			setCreatedCloseClicked,
			createdTippy,
			platformsTippy,
			selectedListings,
			selectedListingsData,
			setSelectedListingsData,
			listingsForDelete,
			hasExtensionInstall
		};
	}, [
		columns,
		tableData,
		handleChangeCheckbox,
		handleRowSelected,
		isBulkEdit,
		setFilterBy,
		setPayload,
		setTableData,
		handleSortByClick,
		loader,
		tab,
		handleGetColumnSearch,
		handleGetColumnSearchDebounced,
		updator,
		inputValues,
		handleInputChange,
		lpPlatforms,
		setLpPlatforms,
		activePlatforms,
		setActivePlatforms,
		customMarketplaces,
		setCustomMarketplaces,
		filterByArr,
		setPagiInputValue,
		isMobile,
		fetchMoreData,
		hasMore,
		hasBetaTeamRole,
	 	is_role_allowed,
		setInputValues,
		setHasMore,
		setFilterByArr,
		filterBy,
		sendReguestForSearchTags,
		setSearchItem,
		setTabCounts2,
		handleFetchForSearch,
		tabCounts2,
		sendReguestColumnSearch,
		handleBulkDelete,
		handleBulkRestore,
		myHelpConditionFunc,
		gridView,
		syncTime,
		hasPro,
		searchItem,
		setPlatformChangesMade,
		handleTogglePlatformsModalShow,
		platformsModalShow,
		setRetryActive,
		retryActive,
		saveActive,
		setSaveActive,
		iframeUpdate,
		handleToggleDateRangeModalShow,
		dateRangeModalShow,
		startChangedCreated,
		endChangedCreated,
		startDateCreated,
		endDateCreated,
		setStartChangedCreated,
		setEndChangedCreated,
		setStartDateCreated,
		setEndDateCreated,
		setCreatedCloseClicked,
		createdTippy,
		platformsTippy,
		selectedListings,
		selectedListingsData,
		setSelectedListingsData,
		listingsForDelete,
		hasExtensionInstall
	]);

	const [topToBtnVisible, setToTopBtnVisible] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			if (isMobile && scrollTop > 100) {
				setToTopBtnVisible(true);
			} else {
				setToTopBtnVisible(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isMobile]);

	useEffect(() => {
		dispatch(setActiveSaveRetain(saveActive))
		return () => {
			dispatch(setActiveSaveRetain(false))
		}
	}, [saveActive]);

	useEffect(() => {
		const handleBeforeUnload = (event: any) => {
			if (saveActive) {
				event.preventDefault();
				event.returnValue = ''; // For older browsers
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [saveActive]);

	return (
	 <>
		 <Helmet>
			 <title>
				 Listings | List Perfectly
			 </title>
		 </Helmet>
		 <div className={styles.catalogPage}>
			 <div id="createdTippy" ref={createdTippy}></div>
			 <div id="platformsTippy" ref={platformsTippy}></div>

			 {showListingViewModal && <ListingViewModal/>}
			 <div
			  className={
				  hideElement && !isMobile ? 'hideElementOnScroll' : styles.heading
			  }
			  id="myElement"
			 >
				 {connectedMarketplacesModal && (
				  <ConnectedMarketplacesModal
				   includesError={includesError}
				   getMarketsFromExtension={getMarketsFromExtension}
				   setConnectedMarketplacesModal={setConnectedMarketplacesModal}
				  />
				 )}
				 {/* <button onClick={handleBulkDelete}>Test it</button> */}

				 <p className={styles.catalogTitle}>Listings</p>
				 {showSecureModal && saveActive && isBulkEdit &&
				  <PaginateMessageModal handleChangeBulkEditCancel={handleChangeBulkEditCancel}
										handleSaveBulkEdit={handleSaveBulkEdit}/>}
				 <div className={`${styles.bulkBlock} bulkBlockForPopover`}>
					 {
					  (hasExtensionInstall && hasExtensionSupport) && <div
					   className={`${styles.marketplacesBtn} lp_custom_chrome_extension_marketplaces_connected_delete_button`}
					   onClick={() => handleShowConnectedMarketsModal()}
					  >
						  <MarketIcon/>
						  <span>Connections</span>
						  <div
						   className={`${styles.marketplacesDot} ${(includesError || !getMarketsFromExtension?.length) ? styles.red : styles.green} lp_custom_marketplaces_connected_button_status`}></div>
					  </div>
					 }
					 {
					  (hasExtensionInstall && !hasExtensionSupport) &&
					  <Tippy
					   interactive={true}
					   zIndex={5}
					   arrow={false}
					   trigger="focus click"
					   content={
						   <p>
							   Activate or <a target="_blank"
											  href={'https://help.listperfectly.com/en/articles/8913965'}>install</a> the
							   List Perfectly extension
						   </p>
					   }
					  >
						  <div className={`${styles.marketplacesBtn} ${styles.marketplacesBtnDisabled}`}>
							  <MarketIcon/>
							  <span>Connections</span>
							  <div
							   className={`${styles.marketplacesDot} ${includesError ? styles.red : styles.green} lp_custom_marketplaces_connected_button_status`}></div>
						  </div>
					  </Tippy>
					 }
					 {
					  (!hasExtensionInstall && !hasExtensionSupport) &&
					  <Tippy
					   interactive={true}
					   zIndex={5}
					   arrow={false}
					   trigger="focus click"
					   content={
						   <p>
							   Activate or <a target="_blank"
											  href={'https://help.listperfectly.com/en/articles/8913965'}>install</a> the
							   List Perfectly extension
						   </p>
					   }
					  >
						  <div className={`${styles.marketplacesBtn} ${styles.marketplacesBtnDisabled}`}>
							  <MarketIcon/>
							  <span>Connections</span>
							  <div
							   className={`${styles.marketplacesDot} ${includesError ? styles.red : styles.green} lp_custom_marketplaces_connected_button_status`}></div>
						  </div>
					  </Tippy>
					 }
					 <div className={styles.HeaderBulkIcons}>
						 {gridView || hasSimple ? (
						  <Tippy
						   interactive={true}
						   zIndex={5}
						   arrow={false}
						   trigger="focus click"
						   content={
							   gridView ? (
								<div
								 className={styles.popoverContentDisabledActions}
								>
									<p className={styles.title}>
										Actions unavailable in grid view.
									</p>
									<p className={styles.desc}>
										Switch to list view to access these features.
									</p>
									<div
									 className={styles.switchToListView}
									 onClick={() => {
										 handleToggleGridView(false);
									 }}
									>
										<ListIcon/>
										<span>Switch to List View</span>
									</div>
								</div>
							   ) : (
								<div className={styles.popoverContentDisabledActions}>
									<p className={styles.title}>feature unavailable</p>
									<p className={styles.desc}>
										CSV download is available for Business, Pro and Pro Plus
										members.{' '}
									</p>
									<a
									 target={'_blank'}
									 href={'https://listperfectly.com/pricing'}
									>
										{' '}
										Upgrade Now{' '}
									</a>
								</div>
							   )
						   }
						  >
							  <div className={styles.csvDisabled}>
								  <CsvNavigationSVG className="settingsIcon"/>
							  </div>
						  </Tippy>
						 ) : (
						  <div
						   className={styles.settings}
						   onClick={() => {
							   dispatch(setShowRedirectModal(true));
							   setTimeout(() => {
								   dispatch(setShowRedirectModal(false));
								   if ((hasBusiness || hasPro) && !is_role_allowed) {
									   window.open(
										'https://app.listperfectly.com/fast-edit-csv/?gv_search=&filter_539[]=not_SOLD',
										'_blank'
									   );
								   }
								   if (is_role_allowed) {
									   window.open(
										'https://app.listperfectly.com/pro-plus-express-editor/',
										'_blank'
									   );
								   }
							   }, 2500);
						   }}
						  >
							  <CsvNavigationSVG className="settingsIcon"/>
						  </div>
						 )}

						 {gridView ? (
						  <Tippy
						   interactive={true}
						   zIndex={5}
						   arrow={false}
						   trigger="focus click"
						   content={
							   gridView ? (
								<div className={styles.popoverContentDisabledActions}>
									<p className={styles.title}>
										Actions unavailable in grid view.
									</p>
									<p className={styles.desc}>
										Switch to list view to access these features.
									</p>
									<div
									 className={styles.switchToListView}
									 onClick={() => {
										 handleToggleGridView(false);
									 }}
									>
										<ListIcon/>
										<span>Switch to List View</span>
									</div>
								</div>
							   ) : (
								<div className={styles.popoverContentDisabledActions}>
									<p className={styles.title}>feature unavailable</p>
									<p className={styles.desc}>
										[Feature] is only available to [ ___ Plan] users.
										Upgrade to access this feature and many others!
									</p>
									<a
									 href="https://app.listperfectly.com/pro-plus-promo/"
									 target="_blank"
									 className={styles.switchToListView}
									>
										<SwitchToListViewIcon/>
										<span>Learn More & Upgrade</span>
									</a>
								</div>
							   )
						   }
						  >
							  <div className="customizeDisabled">
								  <CustomizeIcon/> Customize
							  </div>
						  </Tippy>
						 ) : (
						  <div className="customize" onClick={handleToggleModalShow}>
							  <CustomizeIcon className="settingsIconCustomize"/> Customize
						  </div>
						 )}

						 <div className={styles.statusContainer}>
							 <SVGStatusIcon/>
						 </div>
					 </div>

					 {/* <BulkSelect
            handleToggleBulkDeleteShow={handleToggleBulkDeleteShow}
            handleBulkRestore={handleBulkRestore}
            tab={tab}
            handleBulkDelete={handleBulkDelete}
          /> */}
					 {gridView && (
					  <div>
						  <Tippy
						   interactive={true}
						   zIndex={5}
						   arrow={false}
						   trigger="focus click"
						   content={
							   gridView ? (
								<div className={styles.popoverContentDisabledActions}>
									<p className={styles.title}>
										Actions unavailable in grid view.
									</p>
									<p className={styles.desc}>
										Switch to list view to access these features.
									</p>
									<div
									 className={styles.switchToListView}
									 onClick={() => {
										 handleToggleGridView(false);
									 }}
									>
										<ListIcon/>
										<span>Switch to List View</span>
									</div>
								</div>
							   ) : (
								<div className={styles.popoverContentDisabledActions}>
									<p className={styles.title}>feature unavailable</p>
									<p className={styles.desc}>
										[Feature] is only available to [ ___ Plan] users.
										Upgrade to access this feature and many others!
									</p>
									<a
									 href="https://app.listperfectly.com/pro-plus-promo/"
									 target="_blank"
									 className={styles.switchToListView}
									>
										<SwitchToListViewIcon/>
										<span>Learn More & Upgrade</span>
									</a>
								</div>
							   )
						   }
						  >
							  <button className={`myBtn ${styles.bulkBtnDisable}`}>
								  Bulk Edit
							  </button>
						  </Tippy>
					  </div>
					 )}
					 {!gridView && !isBulkEdit ? (
					  hasSimple ? (
					   <div>
						   <Tippy
							interactive={true}
							zIndex={5}
							arrow={false}
							trigger="focus click"
							content={
								<div className={styles.popoverContentDisabledActions}>
									<p className={styles.desc}>
										Bulk edit is available for Business, Pro and Pro Plus
										users. Upgrade to access this feature and many more.
									</p>
									<a
									 target={'_blank'}
									 href={'https://listperfectly.com/pricing'}
									>
										{' '}
										Upgrade Now{' '}
									</a>
								</div>
							}
						   >
							   <button className={`myBtn ${styles.bulkBtnDisable}`}>
								   Bulk Edit
							   </button>
						   </Tippy>
					   </div>
					  ) : (
					   <div className={styles.bulkBtnWrapper}>
						   <button
							className="myBtn bulkBtn"
							onClick={handleChangeBulkEdit}
						   >
							   Bulk Edit
						   </button>
					   </div>
					  )
					 ) : (
					  !gridView && (
					   <div className={styles.saveAndCancel} id='saveAndCancel'>
						   <button
							className={`myBtn saveBulkBtn ${retryActive || saveActive ? '' : 'saveBtnNotActive'}`}
							onClick={handleSaveBulkEdit}
						   >
							   {retryActive ? 'Retry' : 'Save'}
						   </button>

						   <button
							className="myBtn dangerCancelBtn"
							onClick={handleChangeBulkEditCancel}
						   >
							   Cancel Edits
						   </button>
					   </div>
					  )
					 )}
				 </div>
			 </div>
			 {isMobile ? (
			  <MobileSlider
			   tabs={tabs}
			   tabCounts2={tabCounts2}
			   tab={tab}
			   handleChangeActiveTab={handleChangeActiveTab}
			   handleTabsClick={handleTabsClick}
			   completedRequestsCount={completedRequestsCount}
			  />
			 ) : null}
			 {isMobile
			  ? tab === 'view all' && (
			  <div className={styles.viewAllCheckers}>
				  {hasSimple || hasBusiness ? null : (
				   <label>
					   <p>Hide Draft</p>
					   <input
						className="selectInputDraftSold"
						type="checkbox"
						checked={checkbox1Checked}
						onChange={() => handleCheckboxChange('checkbox1')}
					   />
				   </label>
				  )}
				  <label>
					  Hide Sold
					  <input
					   className="selectInputDraftSold"
					   type="checkbox"
					   checked={checkbox2Checked}
					   onChange={() => handleCheckboxChange('checkbox2')}
					  />
				  </label>
			  </div>
			 )
			  : null}

			 {isMobile
			  ? tab === 'issues' && (
			  <div className="selectorContainer">
				  {' '}
				  <Select
				   className="salesSelector"
				   value={issuesValue}
				   placeholder="All issues"
				   menuPlacement="auto"
				   options={updatedOptions}
				   onChange={handleIssuesDropDownChanged}
				   isSearchable={false}
				  />
			  </div>
			 )
			  : null}

			 {showModalQuestion && (
			  <div className="questionModal">
				  <p>
					  Would you like to open in a new tab or replace the current one ?
				  </p>
				  <div
				   style={{display: 'flex', justifyContent: 'center', gap: '10px'}}
				  >
					  <button
					   className="questionButton"
					   onClick={() => {
						   handleOpenLinkInNewTab();
						   dispatch(setRemoveQuestionModal());
					   }}
					  >
						  New tab
					  </button>
					  <button
					   className="questionButton"
					   onClick={() => {
						   handleReplaceCurrent();
						   dispatch(setRemoveQuestionModal());
					   }}
					  >
						  Replace current
					  </button>
				  </div>
			  </div>
			 )}
			 {showRedirectModal && <RedirectModal/>}
			 <Iframe/>
			 {
				 showEditTemplateModal && <CreateTemplate/>
			 }
			 <div
			  className={hideElement ? 'hideElementOnScroll' : styles.tableUpBlock}
			 >
				 <ul
				  className={styles.tabs}
				  style={{width: hasSimple || hasBusiness ? '50%' : 'auto'}}
				 >
					 {tabs?.map((item: ITab) =>
					   item.tab === 'issues' ? (
						<li
						 key={item.tab}
						 style={{
							 flexDirection: 'row',
							 display: 'flex',
						 }}
						 onClick={() => {
							 if (saveActive) {
								 dispatch(setShowTabsMessage(true));
								 dispatch(setSecureModalWhenChangePage(true));
							 } else {
								 handleChangeActiveTab(item.tab);
								 handleTabsClick(item.tab);
							 }
						 }}
						 className={item.tab === tab ? styles.activeTab : ''}
						>
                <span>
                  <SVGIssuesIcon
				   className={item.tab === tab ? 'issueTabActive' : ''}
				  />
                </span>
							<span className={tab === item.tab ? styles.activeTabSpan : ''}>
                  <p>Issues</p>
                </span>

							<div className="issueTabDot"></div>
						</li>
					   ) : item.tab === 'out of stock' ? (
						hasBusiness || hasSimple ? (
						 <Tippy
						  interactive={true}
						  zIndex={9999}
						  arrow={false}
						  placement="top"
						  trigger="click"
						  appendTo={document.body}
						  content={
							  <div>
								  <p style={{textAlign: 'center', fontWeight: 'bold'}}>
									  That Tab is available at the Pro and up levels
								  </p>
							  </div>
						  }
						 >
							 <li className="disabledTab">
                    <span>
                      Out of Stock{' '}
						<span
						 className={styles.tabListingCounSpan}
						 style={{
							 color: tab === 'out of stock' ? '#fff' : '',
							 marginLeft: 10,
						 }}
						>
                        {tabCounts2[item.tab]?.loader ||
						tabCounts2['out of stock']?.loader ||
						tabCounts2['all']?.loader ? (

						 <MoonLoader
						  color="#1e3166"
						  loading={true}
						  size={screenWidth > 1440 ? 18 : 14}
						 />
						) : (
						 tabCounts2[item.tab]?.count
						)}
                      </span>
                    </span>
							 </li>
						 </Tippy>
						) : (
						 <li
						  onClick={() => {
							  if (saveActive) {
								  dispatch(setShowTabsMessage(true));
								  dispatch(setSecureModalWhenChangePage(true));
							  } else {
								  handleChangeActiveTab(item.tab);
								  handleTabsClick(item.tab);
							  }
						  }}
						  className={item.tab === tab ? styles.activeTab : undefined}
						 >
                  <span
				   className={
					   tab === item.tab
						? `${styles.activeTabSpan} ${styles.removeCapitalize}`
						: styles.removeCapitalize
				   }
				  >
                    Out of Stock
                    <span
					 className={styles.tabListingCounSpan}
					 style={{
						 color: tab === 'out of stock' ? '#fff' : '',
						 marginLeft: 10,
					 }}
					>
                      {tabCounts2[item.tab]?.loader ||
					  tabCounts2['out of stock']?.loader ||
					  tabCounts2['all']?.loader ? (
						 <MoonLoader color="#1e3166" loading={true} size={screenWidth > 1440 ? 18 : 14}/>
						) : (
					   tabCounts2[item.tab]?.count
					  )}
                    </span>
                  </span>
						 </li>
						)
					   ) : (hasBusiness || hasSimple) &&
					   item.tab !== 'view all' &&
					   item.tab !== 'sold' ? (
						<Tippy
						 interactive={true}
						 zIndex={9999}
						 arrow={false}
						 placement="top"
						 trigger="click"
						 appendTo={document.body}
						 content={
							 <div>
								 <p style={{textAlign: 'center', fontWeight: 'bold'}}>
									 That Tab is available at the Pro and up levels
								 </p>
							 </div>
						 }
						>
							<li className="disabledTab">
                  <span>
                    {item.tab}{' '}
					  <span
					   className={styles.tabListingCounSpan}
					   style={{
						   color: tab === 'out of stock' ? '#fff' : '',
						   marginLeft: 10,
					   }}
					  >
                      {tabCounts2[item.tab]?.loader ||
					  tabCounts2['out of stock']?.loader ||
					  tabCounts2['all']?.loader ? (
					   <MoonLoader color="#1e3166" loading={true} size={screenWidth > 1440 ? 18 : 14}/>
					  ) : (
					   tabCounts2[item.tab]?.count
					  )}
                    </span>
                  </span>
							</li>
						</Tippy>
					   ) : (
						<li
						 onClick={() => {
							 if (saveActive) {
								 dispatch(setShowTabsMessage(true));
								 dispatch(setSecureModalWhenChangePage(true));
							 } else {
								 handleChangeActiveTab(item.tab);
								 handleTabsClick(item.tab);
							 }
						 }}
						 className={item.tab === tab ? styles.activeTab : undefined}
						>
                <span
				 className={
					 tab === item.tab ? styles.activeTabSpan : undefined
				 }
				>
                  {item.tab === 'view all' ? 'All' : item.tab}
					<span
					 className={styles.tabListingCounSpan}
					 style={{
						 color: item.tab === tab ? '#fff' : '',
						 marginLeft: 10,
					 }}
					>
                    {tabCounts2[item.tab]?.loader ||
					tabCounts2['out of stock']?.loader ||
					tabCounts2['all']?.loader ? (
					 <MoonLoader
					  color={item.tab === tab ? '#fff' : '#1e3166'}
					  loading={true}
					  size={screenWidth > 1440 ? 18 : 14}
					 />
					) : (
					 tabCounts2[item.tab]?.count
					)}
                  </span>
                </span>
						</li>
					   )
					 )}
				 </ul>

				 <div className={styles.rightTableBlock}>
					 <div className={styles.tableItems}>
						 {/* {!tabLoading && (
              <div className={styles.total}>
                <span>
                  {currentPaginateNumber * metadata?.limit} -
                  {tableData.length || total}
                </span>
              </div>
            )} */}
						 <div className={styles.tableFilterLine}>
							 <div className={styles.searchBox}>
								 {!gridView && (
								  <Search
								   filterBy={filterBy}
								   setFilterBy={setFilterBy}
								   searchItem={searchItem}
								   setSearchItem={setSearchItem}
								   handleFetchForSearch={handleFetchForSearch}
								  />
								 )}
								 {gridView && !isMobile ? (
								  <MobileSearch
								   isMobile={isMobile}
								   searchItem={searchItem}
								   setSearchItem={setSearchItem}
								   filterBy={filterBy}
								   setFilterBy={setFilterBy}
								   handleFetchForSearch={handleFetchForSearch}
								   handleTogglePlatformsModalShow={
									   handleTogglePlatformsModalShow
								   }
								   inputValues={inputValues}
								   handleInputChange={handleInputChange}
								   handleToggleDateRangeModalShow={handleToggleDateRangeModalShow}
								   dateRangeModalShow={dateRangeModalShow}
								   startChangedCreated={startChangedCreated}
								   endChangedCreated={endChangedCreated}
								   startDateCreated={startDateCreated}
								   endDateCreated={endDateCreated}
								   setStartChangedCreated={setStartChangedCreated}
								   setEndChangedCreated={setEndChangedCreated}
								   setStartDateCreated={setStartDateCreated}
								   setEndDateCreated={setEndDateCreated}
								   setCreatedCloseClicked={setCreatedCloseClicked}
								   createdTippy={createdTippy}
								  />
								 ) : null}
								 {tab === 'issues' && (
								  <Select
								   styles={customStyles}
								   value={issuesValue}
								   placeholder="All issues"
								   menuPlacement="auto"
								   options={updatedOptions}
								   onChange={handleIssuesDropDownChanged}
								   isSearchable={false}
								  />
								 )}

								 {tab === 'view all' && (
								  <div className={styles.viewAllCheckers}>
									  {!hasSimple && !hasBusiness && (
									   <label>
										   <p>Hide Draft</p>
										   <input
											className="selectInputDraftSold"
											type="checkbox"
											checked={checkbox1Checked}
											onChange={() => handleCheckboxChange('checkbox1')}
										   />
									   </label>
									  )}
									  <label>
										  Hide Sold
										  <input
										   className="selectInputDraftSold"
										   type="checkbox"
										   checked={checkbox2Checked}
										   onChange={() => handleCheckboxChange('checkbox2')}
										  />
									  </label>
								  </div>
								 )}
							 </div>
						 </div>

						 <div
						  style={{
							  display: 'flex',
							  gap: screenWidth > 1440 ? '10px' : '8px',
							  justifyContent: 'space-between',
						  }}
						 >
							 <div className={styles.perPageSelectBlock}>
								 <span>Per Page</span>
								 <PerPageSelect
								  handleGetDataPerPage={handleGetDataPerPage}
								  metadata={metadata}
								  saveActive={saveActive}
								  type="Catalog"
								 />
							 </div>

							 <div className={styles.paginateBox}>
								 <TablePagination isBulkEdit={isBulkEdit}
												  pageCount={pageCount} saveActive={saveActive}
												  pagiInputValue={pagiInputValue}
												  setPagiInputValue={setPagiInputValue}
								 />
							 </div>
							 <div className={styles.gridViewBtns}>
								 <GridView
								  className={`${
								   gridView ? styles.activeIcon : styles.inactiveIcon
								  }`}
								  onClick={() => handleToggleGridView(true)}
								 />
								 <TableView
								  className={`${
								   !gridView ? styles.activeIcon : styles.inactiveIcon
								  }`}
								  onClick={() => handleToggleGridView(false)}
								 />
							 </div>
						 </div>
					 </div>
					 {showSync && (
					  <div
					   id="syncButton"
					   style={{
						   visibility: !hasBusiness && !hasSimple ? 'visible' : 'hidden',
					   }}
					  >
						  {!syncContent && tab === 'issues' && (
						   <>
							   {bodyAtribute ? (
								<>
									<button
									 className="lp_custom_marketplaces_sync__start"
									 style={{display: 'block'}}
									>
										Run Issue Finder
									</button>
									<button
									 className="lp_custom_marketplaces_sync__cancel"
									 style={{display: 'none'}}
									>
										Cancel
									</button>
									<button
									 className="lp_custom_marketplaces_sync__in_progress"
									 style={{display: 'none'}}
									>
										In Progress
									</button>
								</>
							   ) : (
								<>
									<div>
										<button className="syncButton startButton extenstionNotInstalled">
											Start Sync
										</button>
									</div>
									<button className="syncButton cancelButton hidden">
										Cancel
									</button>
								</>
							   )}
							   <div className="syncTextContainer">
								   <p className="installExtension">
									   Sync requires the List Perfectly extension to be
									   installed.
									   <a
										href="https://app.listperfectly.com/version/alt-beta-m"
										target="_blank"
									   >
										   {' '}
										   Install now.
									   </a>
								   </p>
								   <p className="syncMessage">
                      <span className="syncMessage" id="syncMessage">
                        {syncMessage}
                      </span>
									   <span
										className="syncMessage"
										id="syncErrorMessage"
									   ></span>
								   </p>
							   </div>
						   </>
						  )}
					  </div>
					 )}
					 <div style={{display: 'flex', gap: screenWidth > 1440 ? '20px' : '16px', alignItems: 'center'}}>
						 {
							 selectedListings.length ? <div style={{
								 paddingTop: '10px',
								 color: '#1E3166',
								 fontWeight: 600
							 }}>{selectedListings.length} Selected</div> : null
						 }
						 <SearchResult
						  setHasMore={setHasMore}
						  filterByArr={filterByArr}
						  setFilterByArr={setFilterByArr}
						  filterBy={filterBy}
						  setFilterBy={setFilterBy}
						  sendReguestForSearchTags={sendReguestForSearchTags}
						  inputValues={inputValues}
						  setInputValues={setInputValues}
						  lpPlatforms={lpPlatforms}
						  setLpPlatforms={setLpPlatforms}
						  customMarketplaces={customMarketplaces}
						  setCustomMarketplaces={setCustomMarketplaces}
						  activePlatforms={activePlatforms}
						  setActivePlatforms={setActivePlatforms}
						  setSearchItem={setSearchItem}
						  handleFetchForSearch={handleFetchForSearch}
						  handleGetColumnSearchDebounced={handleGetColumnSearchDebounced}
						  tabCounts2={tabCounts2}
						  setTabCounts2={setTabCounts2}
						  sendReguestColumnSearch={sendReguestColumnSearch}
						  myHelpConditionFunc={myHelpConditionFunc}
						  tab={tab}
						  hasPro={hasPro}
						  is_role_allowed={is_role_allowed}
						  setStartDateCreated={setStartDateCreated}
						  setEndDateCreated={setEndDateCreated}
						 />
					 </div>
				 </div>
			 </div>

			 {hideElement && !isMobile && (
			  <div id="scrollHeader" className={styles.scrollHeader}>
				  <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
					  {
						  selectedListings.length ? <div style={{
							  paddingTop: '10px',
							  color: '#1E3166',
							  fontWeight: 600
						  }}>{selectedListings.length} Selected</div> : null
					  }
					  <SearchResult
					   setHasMore={setHasMore}
					   filterByArr={filterByArr}
					   setFilterByArr={setFilterByArr}
					   filterBy={filterBy}
					   setFilterBy={setFilterBy}
					   sendReguestForSearchTags={sendReguestForSearchTags}
					   inputValues={inputValues}
					   setInputValues={setInputValues}
					   lpPlatforms={lpPlatforms}
					   setLpPlatforms={setLpPlatforms}
					   customMarketplaces={customMarketplaces}
					   setCustomMarketplaces={setCustomMarketplaces}
					   activePlatforms={activePlatforms}
					   setActivePlatforms={setActivePlatforms}
					   setSearchItem={setSearchItem}
					   handleFetchForSearch={handleFetchForSearch}
					   handleGetColumnSearchDebounced={handleGetColumnSearchDebounced}
					   tabCounts2={tabCounts2}
					   setTabCounts2={setTabCounts2}
					   sendReguestColumnSearch={sendReguestColumnSearch}
					   myHelpConditionFunc={myHelpConditionFunc}
					   tab={tab}
					   hasPro={hasPro}
					   is_role_allowed={is_role_allowed}
					   setStartDateCreated={setStartDateCreated}
					   setEndDateCreated={setEndDateCreated}
					  />
				  </div>
				  <div>
					  <div
					   className={styles.paginateBox}
					   style={{display: 'flex', alignItems: 'center', gap: '5px'}}
					  >
						  <TablePagination
						   isBulkEdit={isBulkEdit}
						   pageCount={pageCount}
						   pagiInputValue={pagiInputValue}
						   setPagiInputValue={setPagiInputValue}
						   saveActive={saveActive}
						  />
						  {gridView ? (
						   <Tippy
							interactive={true}
							zIndex={5}
							arrow={false}
							trigger="focus click"
							content={
								gridView ? (
								 <div className={styles.popoverContentDisabledActions}>
									 <p className={styles.title}>
										 Actions unavailable in grid view.
									 </p>
									 <p className={styles.desc}>
										 Switch to list view to access these features.
									 </p>
									 <div
									  className={styles.switchToListView}
									  onClick={() => {
										  handleToggleGridView(false);
									  }}
									 >
										 <ListIcon/>
										 <span>Switch to List View</span>
									 </div>
								 </div>
								) : (
								 <div className={styles.popoverContentDisabledActions}>
									 <p className={styles.title}>feature unavailable</p>
									 <p className={styles.desc}>
										 [Feature] is only available to [ ___ Plan] users.
										 Upgrade to access this feature and many others!
									 </p>
									 <a
									  href="https://app.listperfectly.com/pro-plus-promo/"
									  target="_blank"
									  className={styles.switchToListView}
									 >
										 <SwitchToListViewIcon/>
										 <span>Learn More & Upgrade</span>
									 </a>
								 </div>
								)
							}
						   >
							   <div className="customizeDisabled">
								   <CustomizeIcon/> Customize
							   </div>
						   </Tippy>
						  ) : (
						   <div className="customize" onClick={handleToggleModalShow}>
							   <CustomizeIcon className="settingsIconCustomize"/> Customize
						   </div>
						  )}
						  {gridView && (
						   <div>
							   <Tippy
								interactive={true}
								zIndex={5}
								arrow={false}
								trigger="focus click"
								content={
									gridView ? (
									 <div className={styles.popoverContentDisabledActions}>
										 <p className={styles.title}>
											 Actions unavailable in grid view.
										 </p>
										 <p className={styles.desc}>
											 Switch to list view to access these features.
										 </p>
										 <div
										  className={styles.switchToListView}
										  onClick={() => {
											  handleToggleGridView(false);
										  }}
										 >
											 <ListIcon/>
											 <span>Switch to List View</span>
										 </div>
									 </div>
									) : (
									 <div className={styles.popoverContentDisabledActions}>
										 <p className={styles.title}>feature unavailable</p>
										 <p className={styles.desc}>
											 [Feature] is only available to [ ___ Plan] users.
											 Upgrade to access this feature and many others!
										 </p>
										 <a
										  href="https://app.listperfectly.com/pro-plus-promo/"
										  target="_blank"
										  className={styles.switchToListView}
										 >
											 <SwitchToListViewIcon/>
											 <span>Learn More & Upgrade</span>
										 </a>
									 </div>
									)
								}
							   >
								   <button className={`myBtn ${styles.bulkBtnDisable}`}>
									   Bulk Edit
								   </button>
							   </Tippy>
						   </div>
						  )}
						  {!gridView && !isBulkEdit ? (
						   hasSimple ? (
							<div>
								<Tippy
								 interactive={true}
								 zIndex={5}
								 arrow={false}
								 trigger="focus click"
								 content={
									 <div className={styles.popoverContentDisabledActions}>
										 <p className={styles.desc}>
											 Bulk edit is available for Business, Pro and Pro
											 Plus users. Upgrade to access this feature and many
											 more.
										 </p>
										 <a
										  target={'_blank'}
										  href={'https://listperfectly.com/pricing'}
										 >
											 {' '}
											 Upgrade Now{' '}
										 </a>
									 </div>
								 }
								>
									<button className={`myBtn ${styles.bulkBtnDisable}`}>
										Bulk Edit
									</button>
								</Tippy>
							</div>
						   ) : (
							<div>
								<button
								 className="myBtn bulkBtn"
								 onClick={handleChangeBulkEdit}
								>
									Bulk Edit
								</button>
							</div>
						   )
						  ) : (
						   !gridView && (
							<div
							 className={styles.saveAndCancel}
							 style={{
								 display: 'flex',
								 alignItems: 'center',
								 gap: '5px',
							 }}
							>
								<button
								 className={`myBtn saveBulkBtn ${retryActive || saveActive ? '' : 'saveBtnNotActive'}`}
								 onClick={handleSaveBulkEdit}
								>
									{retryActive ? 'Retry' : 'Save'}
								</button>
								<button
								 className="myBtn dangerCancelBtn"
								 onClick={handleChangeBulkEditCancel}
								>
									Cancel Edits
								</button>
							</div>
						   )
						  )}
					  </div>
				  </div>
			  </div>
			 )}

			 {!isLoading ? (
			  <>
				  <Table {...props} />
			  </>
			 ) : (
			  <Loader/>
			 )}
			 {tableData?.length === 0 && !loader && isMobile && (
			  <div
			   className={` ${styles.noResultsContainer} ${
				gridView ? styles.noResultsContainerGridView : ''
			   }`}
			  >
				  {tableData?.length === 0 && !loader && filterBy.search_everything && (
				   <p className={styles.noResult}>
					   Can't search for word {filterBy.search_everything}. Try using more
					   descriptive words
				   </p>
				  )}

				  {tableData?.length === 0 && !loader && (
				   <p className={styles.noResult}>
					   <p className={styles.noResult}>
						   This search returned no results.
					   </p>
				   </p>
				  )}
			  </div>
			 )}

			 {tableData?.length === 0 && !loader && gridView && (
			  <p
			   className={styles.noResult}
			   style={{
				   fontSize: '20px',
				   fontStyle: 'normal',
				   fontWeight: 600,
				   lineHeight: 'normal',
			   }}
			  >
				  No results
			  </p>
			 )}


			 {editModalShow && (
			  <TableSettings
			   is_role_allowed={is_role_allowed}
			   hasPro={hasPro}
			   hasSimple={hasSimple}
			   hasBusiness={hasBusiness}
			   handleToggleModalShow={handleToggleModalShow}
			   columns={columns}
			   handleEditColumns={handleEditColumns}
			   hasBetaTeamRole={hasBetaTeamRole}
			  />
			 )}
			 {/* {bulkDeleteConfirmModalShow && (
        <ConfirmModal
          handleToggleBulkDeleteShow={handleToggleBulkDeleteShow}
          handleBulkDelete={handleBulkDelete}
          tableData={tableData}
        />
      )} */}
			 {isMobile && <ToTopBtn/>}
		 </div>

	 </>
	);
};

export default CatalogPage;
