import {PopoverIcon} from "../../../../assets/icons";
import Tippy from "@tippyjs/react";
import styles from './KeywordComponent.module.scss';

import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import {TextAreaTagify} from "../../TextAreaTagify";
import {InputCount} from "../../InputCount";

export const KeywordsComponent = ({hasSimple, formInfo, unescapedRowMetadata, handleTagifiesChange, handleStandartInputChange }:any) => {
	const { bdecode } = useBasisFuncs();
	return(
	 <div>
		 <div className={styles.accordionContent}>
			 <div className={styles.accordionContent2}>
				 <div className={styles.upperContainer}>
					 {
						 !hasSimple && (
						   <div className={styles.leftContainer}>
							   <div className={styles.headerContainer}>
								   <p className={styles.heading}>
									   Keywords or Tags
									   <Tippy
										interactive={true}
										zIndex={9991}
										trigger="click"
										arrow={false}
										content={
											<div
											 className={`popover ${styles.popoverContent}`}
											>
												<ul>
													<li>
														Enter your keywords and separate them
														with commas.
													</li>
													<li>
														Example: summer, vintage, classic,
														retro shirt
														<ul>
															<li>
																Example: summer, vintage, classic,
																retro shirt
															</li>
														</ul>
													</li>

													<div className={styles.line}></div>
													<p>
														★ Crossposts to supported 'keywords
														and tags' fields.
													</p>
												</ul>
											</div>
										}
									   >
										   <PopoverIcon
											className={styles.popoverIcon}
											color={'#fff'}
										   ></PopoverIcon>
									   </Tippy>
								   </p>
							   </div>
							   <TextAreaTagify
								placeholder="Keywords or Tags"
								name="input_453"
								isTagify={true}
								value={
									formInfo.newKeywords.changed
									 ? bdecode(formInfo.newKeywords.text)
									 : unescapedRowMetadata[453]
									  ? bdecode(unescapedRowMetadata[453])
									  : ''
								}
								handleTagifyChange={handleTagifiesChange}
								limit={700}
								height={200}
							   />
						   </div>
					  )
					 }
					 <div className={styles.rightContainer}>
						 <div className={styles.inputsItem}>
							 {
							  !hasSimple && <div className={styles.inpLeftItem}>
								  <p className={styles.heading}>
									  COGS
									  <Tippy
									   interactive={true}
									   zIndex={5}
									   trigger="click"
									   arrow={false}
									   content={
										   <div
											className={`popover ${styles.popoverContent}`}
										   >
											   <ul>
												   <li>
													   Cost of Goods - What you paid to buy
													   or create the listing for sale.
												   </li>
											   </ul>
										   </div>
									   }
									  >
										  <PopoverIcon
										   className={styles.popoverIcon}
										   color={'#fff'}
										  ></PopoverIcon>
									  </Tippy>
								  </p>
								  <InputCount
								   placeholder="COGS"
								   name="input_847"
								   type="number"
								   value={
									   formInfo.newCogs.changed
										? bdecode(formInfo.newCogs.text)
										: unescapedRowMetadata[847]
										 ? bdecode(unescapedRowMetadata[847])
										 : ''
								   }
								   handleChange={handleStandartInputChange}
								   limit={500}
								   height={40}
								   hideCount={true}
								  />
							  </div>
							 }
							 <div className={styles.inpRightItem}>
								 <p className={styles.heading}>
									 Price
									 <Tippy
									  interactive={true}
									  zIndex={5}
									  trigger="
                                                click"
									  arrow={false}
									  content={
										  <div
										   className={`popover ${styles.popoverContent}`}
										  >
											  <ul>
												  <li>
													  Enter number only, without currency
													  symbols
												  </li>
												  <div className={styles.line}></div>
												  <p>
													  ★ Crossposts to supported 'price'
													  fields.
												  </p>
											  </ul>
										  </div>
									  }
									 >
										 <PopoverIcon
										  className={styles.popoverIcon}
										  color={'#fff'}
										 ></PopoverIcon>
									 </Tippy>
								 </p>
								 <InputCount
								  placeholder="Price"
								  name="input_22"
								  type="number"
								  value={
									  formInfo.newPrice.changed
									   ? bdecode(formInfo.newPrice.text)
									   : unescapedRowMetadata[22]
										? bdecode(unescapedRowMetadata[22])
										: ''
								  }
								  handleChange={handleStandartInputChange}
								  limit={250}
								  height={40}
								  hideCount={true}
								 />
							 </div>
						 </div>
						 {
						  !hasSimple && <div className={styles.inputsItem}>
							  <div className={styles.inpLeftItem}>
								  <p className={styles.heading}>
									  MSRP
									  <Tippy
									   interactive={true}
									   zIndex={5}
									   trigger="click"
									   arrow={false}
									   content={
										   <div
											className={`popover ${styles.popoverContent}`}
										   >
											   <ul>
												   <li>
													   'Manufacturer's Recommended Sale
													   Price'
												   </li>
												   <li>
													   Original price as displayed on
													   manufacturer tags
												   </li>
												   <div className={styles.line}></div>
												   <p>
													   ★ Crossposts to supported 'MSRP'
													   fields.
												   </p>
											   </ul>
										   </div>
									   }
									  >
										  <PopoverIcon
										   className={styles.popoverIcon}
										   color={'#fff'}
										  ></PopoverIcon>
									  </Tippy>
								  </p>
								  <InputCount
								   placeholder="MSRP"
								   name="input_104"
								   type="number"
								   value={
									   formInfo.newMSRP.changed
										? bdecode(formInfo.newMSRP.text)
										: unescapedRowMetadata[104]
										 ? bdecode(unescapedRowMetadata[104])
										 : ''
								   }
								   handleChange={handleStandartInputChange}
								   limit={250}
								   height={40}
								   hideCount={true}
								  />
							  </div>
							  <div className={styles.inpRightItem}>
								  <p className={styles.heading}>
									  SKU
									  <Tippy
									   interactive={true}
									   zIndex={5}
									   trigger="click"
									   arrow={false}
									   content={
										   <div
											className={`popover ${styles.popoverContent}`}
										   >
											   <ul>
												   <li>'Stock Keeping Unit'</li>
												   <li>
													   Used to track inventory movement and
													   locate inventory.
												   </li>
												   <li>
													   Example: G-Box32 = Located in
													   garage, in box #32.
												   </li>
												   <div className={styles.line}></div>
												   <p>
													   ★ Crossposts to supported 'SKU'
													   fields.
												   </p>
											   </ul>
										   </div>
									   }
									  >
										  <PopoverIcon
										   className={styles.popoverIcon}
										   color={'#fff'}
										  ></PopoverIcon>
									  </Tippy>
								  </p>
								  <InputCount
								   placeholder="SKU"
								   name="input_1"
								   value={
									   formInfo.newSku.changed
										? bdecode(formInfo.newSku.text)
										: unescapedRowMetadata[1]
										 ? bdecode(unescapedRowMetadata[1])
										 : ''
								   }
								   handleChange={handleStandartInputChange}
								   limit={250}
								   height={40}
								   hideCount={true}
								  />
							  </div>
						  </div>
						 }
						 {
						  !hasSimple && <div className={styles.inputsItem}>
							  <div className={styles.inpLeftItem}>
								  <p className={styles.heading}>
									  UPC
									  <Tippy
									   interactive={true}
									   zIndex={5}
									   trigger="click"
									   arrow={false}
									   content={
										   <div
											className={`popover ${styles.popoverContent}`}
										   >
											   <ul>
												   <li>'Universal Product Code'</li>
												   <li>
													   Barcode number located on the
													   scannable tag.
												   </li>
												   <div className={styles.line}></div>
												   <p>
													   ★ Crossposts to supported 'UPC'
													   fields.
												   </p>
											   </ul>
										   </div>
									   }
									  >
										  <PopoverIcon
										   className={styles.popoverIcon}
										   color={'#fff'}
										  ></PopoverIcon>
									  </Tippy>
								  </p>
								  <InputCount
								   placeholder="UPC"
								   name="input_704"
								   value={
									   formInfo.newUpc.changed
										? bdecode(formInfo.newUpc.text)
										: unescapedRowMetadata[704]
										 ? bdecode(unescapedRowMetadata[704])
										 : ''
								   }
								   handleChange={handleStandartInputChange}
								   limit={250}
								   height={40}
								   hideCount={true}
								  />
							  </div>
							  <div className={styles.inpRightItem}>
								  <p className={styles.heading}>
									  Quantity
									  <Tippy
									   interactive={true}
									   zIndex={5}
									   trigger="click"
									   arrow={false}
									   content={
										   <div
											className={`popover ${styles.popoverContent}`}
										   >
											   <ul>
												   <li>Enter the quantity.</li>
												   <div className={styles.line}></div>
												   <p>
													   ★ Crossposts to supported 'Quantity'
													   fields.
												   </p>
											   </ul>
										   </div>
									   }
									  >
										  <PopoverIcon
										   className={styles.popoverIcon}
										   color={'#fff'}
										  ></PopoverIcon>
									  </Tippy>
								  </p>
								  <InputCount
								   placeholder="Quantity"
								   name="input_851"
								   type="number"
								   value={
									   formInfo.newQuantity.changed
										? bdecode(formInfo.newQuantity.text)
										: unescapedRowMetadata[851]
										 ? bdecode(unescapedRowMetadata[851])
										 : ''
								   }
								   handleChange={handleStandartInputChange}
								   limit={250}
								   height={40}
								   hideCount={true}
								  />
							  </div>
						  </div>
						 }
					 </div>
				 </div>
			 </div>
		 </div>
	 </div>
	)
}