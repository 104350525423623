import React, { Fragment, useState} from 'react';
import {useGlobalUploaderState} from "../hooks/hooks";
import {addClass, hasClass, isMobileDevice, moveElementBefore, removeClass, replaceSuffixInFilename} from "../common";
import {UploaderFile} from "../interfaces";
import LoaderIcon from '../images/Loading.svg';
let touchTimeout: any = null;


const UploadedImageList = ({hasPro, isRoleAllowed, onImageRemove,mobileImageActions,setMobileImageActionsVisibility, setMobileImageActionsActiveIndex}: {mobileImageActions: Boolean,setMobileImageActionsActiveIndex: any,setMobileImageActionsVisibility: Function,onImageRemove: Function, hasPro:boolean, isRoleAllowed:boolean}) => {
    const {state, dispatch} = useGlobalUploaderState();
    const [activeIndex, setActiveIndex] = useState(0);
    let [touchStartTime, setTouchStartTime] = useState(0);

    const changeFileOrder = (destination_index: any, index: number) => {
        const {files} = state
        dispatch({type: "SETFILES", data: moveElementBefore([...files],  +destination_index, index)})
    }
    const {files, showImageSelection,selectedImageIndexes,activeStatus} = state
    const onStart = ({target}: any) => {
        document.body.style.overflow = 'hidden'
        target.querySelector('.image-hover-menu').style.display = 'none'
        setActiveIndex(target.getAttribute("data-index"))
    };

    const onEnd = ({target}: any) => {
        document.body.style.overflow = 'auto'
        target.querySelector('.image-hover-menu').style.display = 'flex'
        removeClass(target,  'image-drop-active')
    };

    const onDrop = (e: any) => {
        document.body.style.overflow = 'auto'
        const {target} = e;
        if (!target) return;
        const {files} = state
        let droppedItemIndex: string | null | number = target.getAttribute("data-index");
        if (!droppedItemIndex) return;
        droppedItemIndex = +droppedItemIndex;
        const {
            fileExtension: droppedFileExtension,
            placeholder = false,
            error: droppedFileType,
        } = files[droppedItemIndex];
        const {fileExtension: activeFileExtension, error: activeFileType} = files[activeIndex];

        if (placeholder && droppedItemIndex === 0) return;
        removeClass(target, 'image-drop-active')
        if ((+activeIndex === 0 && droppedFileExtension === '.mp4') || (+droppedItemIndex === 0 && activeFileExtension === '.mp4') || (+activeIndex === 0 && droppedFileType) || (+droppedItemIndex === 0 && activeFileType)) return
        if (activeIndex === droppedItemIndex) return;
        changeFileOrder(activeIndex, droppedItemIndex)
    }

    const dragEnter = (e: any) => {
        e.preventDefault();
        const {target} = e;

        if (hasClass(target, 'image-uploader-item')){
            hasClass(target,  'image-drop-active')
        }else{
            hasClass( target.closest('.image-uploader-item'),  'image-drop-active')
        }
    }

    const dragLeave = (e: any) => {
        e.preventDefault();
        const {target} = e;
        removeClass(target,  'image-drop-active')
    }

    const allowDrop = (e: any) => {
        e.preventDefault();
    }
    const addIndexToSelected = (id: number) => {
        dispatch({type:'UPDATESELECTEDIMAGEINDEX', data: id})
    }

    const viewImageInEditor = (i: number) => {
        dispatch({type:'SETCACHEDFILE', data: files[i]})
        dispatch({type:'SETACTIVEFILE', data: files[i]})
    }

    const removeImage = (target: any, i: number) => {
        // @ts-ignore
        addClass(target.closest('.image-uploader-item'), 'scale-down-center')
        //give some time for animation
        setTimeout(() => {
            dispatch({type:'REMOVEFILE', data: i})
            onImageRemove()
        },200)
    }


    return <Fragment>
        {files.map(({
                        imageSmallPreviewUrl,
                        imagePreviewUrl,
                        filename,
                        fileExtension,
                        filenameWithoutExtension,
                        error,
                        placeholder = false,
                        loading,
                        fileS3Link = null,
                        index
                    }: UploaderFile, i) => {

            let isVideo = fileExtension === '.mp4'
            const googleLensUrl = 'https://lens.google.com/uploadbyurl?url=' + encodeURIComponent('https://media.listperfectly.com/' + replaceSuffixInFilename(filename,'_LP', '_CI'))
            const onClickAction = () => {
                if(isMobileDevice()) return;
                if(window.innerWidth <= 900){
                    setMobileImageActionsVisibility(true);
                    setMobileImageActionsActiveIndex(index)
                    document.body.classList.add('no-scroll');
                }
                showImageSelection && !error && !placeholder && !isVideo && addIndexToSelected(index)
            }
            const onTouchStart = () => {
                if (window.innerWidth <= 900 && !showImageSelection && !mobileImageActions) {
                    setTouchStartTime(new Date().getTime());
                    // @ts-ignore
                    touchTimeout  = setTimeout(function() {
                        //'Long press detected'
                    }, 1000); //
                }
            }
            const onTouchEnd = () => {
                if (window.innerWidth <= 900 && !showImageSelection && !mobileImageActions) {
                    const touchDuration = new Date().getTime() - touchStartTime;
                    clearTimeout(touchTimeout);
                    if (touchDuration < 500) { // Adjust the duration as needed
                        //tap detected
                        setMobileImageActionsVisibility(true);
                        setMobileImageActionsActiveIndex(index)
                        document.body.classList.add('no-scroll');
                    }
                }
            }

            return <div key={index} onDrop={onDrop}
                        onDragOver={allowDrop} onDragStart={onStart}
                        onDragLeave={dragLeave}  onDragEnd={onEnd} onDragEnter={dragEnter}
                        data-image_filename={filename}
                        data-index={i} data-image_index={index}
                        draggable={showImageSelection ? false : !placeholder} id={"uploaded-image-" + index}
                        style={{
                            cursor: (showImageSelection && !error && !placeholder && !isVideo) ? "pointer" : "unset",
                            border: error ? '1px solid #de4546' : (showImageSelection && selectedImageIndexes.includes(index)) ? '3px solid #00B5AD' : "inherit"
                        }}
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                        onClick={onClickAction}
                        className={activeIndex ?"image-uploader-item uploadedImageItem disable-select" : "image-uploader-item animated-display uploadedImageItem disable-select"}>
                {files.length >= 1 && i === 0 && <div className="main-star-icon"></div>}

                <div className="size-warning-modal-button" onClick={() => dispatch({type:'SETACTIVEFILE', data: files[i]})}/>
                <div className="change-image-order" onClick={({target}) => changeFileOrder(target, i)}/>


                <div style={{display: showImageSelection ? "none" : "flex", borderRadius: '8px'}} className="image-hover-menu">
                    <a target="_blank"  href={`${fileS3Link}`}
                       className={`s3-image-button ${fileS3Link ? 's3-image-button-show' : ''}`}/>
                    {(activeStatus === 2 && (hasPro || (hasPro && isRoleAllowed))) && <a target="_blank"
                                              href={googleLensUrl}
                                              className={`google-lens-button ${fileS3Link ? 'google-lens-button-show' : ''}`}/>}
                    {!fileS3Link ? <div className="s3-image-upload-status-loader">
                        <img src={LoaderIcon} alt={""}/>
                    </div> : null}
                    {!error && !loading && !isVideo && !placeholder ?
                        <div className="view-image-modal-button" onClick={() => viewImageInEditor(i)}/> : null}
                    {
                        isVideo && <div className="eye-image-modal-button" onClick={() => dispatch({type:'SETACTIVEFILE', data: files[i]})}/>
                    }
                    {!loading && !placeholder &&
                        <div className="remove-image-button" onClick={({target}) => {
                           removeImage(target, i)
                        }}/>}
                </div>
                {error ? <span className="image-error-tooltip">{error}</span> : null}
                {loading && <span className="image-error-tooltip">Image is loading</span>}
                {isVideo && <video className="video_item_preview">
                    <source src={!imagePreviewUrl ? `${fileS3Link}` : imagePreviewUrl} type="video/mp4"/>
                </video>}
                {loading && <div className="react_lambda_uploader_loader"></div>}
                {
                    error ?
                        <div className="uploadedImageError">
                            <div className="error-image-icon"/>
                            <span className="error-filename">{filename}</span>
                        </div>
                        : (!isVideo && imageSmallPreviewUrl) && <div data-index={i} data-image_index={index} className="uploadedImagePreview" style={{
                        backgroundImage: `url("${imageSmallPreviewUrl}")`,
                        border: (files.length >= 1 && i === 0) ? "2px solid #4DB89E" : 'inherit',
                        borderRadius: '8px'
                    }}/>
                }
            </div>
        })
        }
    </Fragment>
};

export default UploadedImageList;