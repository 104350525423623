import React, { useState, useEffect, useRef } from 'react';
import styles from './TableEditColumn.module.scss';

import { objectToString } from '../../../../utils/objectToString';
import { IColumn } from '../../../../interfaces';
import ModalButton from '../../../../components/UI/ModalButton';
import { SVGArrow } from '../../../../assets/icons';
import formatString from '../../../../utils/formatString';

interface IColumnData {
  name: string;
  value: string;
  isChecked: boolean;
}

const columnData: IColumnData[] = [
  // { name: 'Thumbnail', value: 'thumbnail', isChecked: true },
  // { name: 'Image', value: 'image', isChecked: true },

  // { name: 'Title', value: 'title', isChecked: true },
  // { name: 'Price', value: 'price', isChecked: true },
  { name: 'Total Sold Price', value: 'total_sold_price', isChecked: true },

  { name: 'Date Sold', value: 'date_sold', isChecked: true },
  { name: 'Platforms', value: 'platforms', isChecked: true },
  { name: 'Sold On', value: 'sold_on', isChecked: true },

  { name: 'Shop', value: 'shop_id', isChecked: true },
  { name: 'SKU', value: 'sku', isChecked: true },

  // { name: 'On LP', value: 'on_lp', isChecked: true },
  {
    name: 'Shipping Cost Buyer',
    value: 'shipping_cost_buyer',
    isChecked: true,
  },

  {
    name: 'Shipping Cost Seller',
    value: 'shipping_cost_seller',
    isChecked: true,
  },

  { name: 'COGS', value: 'cogs', isChecked: true },
  { name: 'Fees', value: 'fees', isChecked: true },

  { name: 'Shipping Status', value: 'shipping_status', isChecked: true },

  { name: 'Linked', value: 'linked', isChecked: true },
  { name: 'Quantity Sold', value: 'quantity_sold', isChecked: true },
  { name: 'Quantity Available', value: 'quantity_available', isChecked: true },

  { name: 'Sales Tax', value: 'sales_tax', isChecked: true },
];

interface ITableEditColumn {
  handleChangeModal: () => void;
  columns: IColumn[];
  handleEditColumns: (cols: string[]) => void;
}

const TableEditColumn = (props: ITableEditColumn) => {
  const { handleChangeModal, columns, handleEditColumns } = props;
  const myColumns = [
    'thumbnail',
    'title',
    'total_sold_price',
    'date_sold',
    'sold_on',
    'shop_id',
    'sku',
    'quantity',
    'platforms',
    'fees',
    'sales_tax',
    'quantity_available',

    // 'on_lp',
  ];

  const settingsRef = useRef<HTMLDivElement>(null);

  const [editColumns, setEditColumns] = useState<any>([]);

  const [userEditColumns, setUserEditColumns] = useState<any>([]);

  const [arranges, setArranges] = useState<string[]>([]);

  const [selectedItem, setSelectedItem] = useState<string>('');

  useEffect(() => {
    const top__headers = document.querySelectorAll('.top-headers-wrapper');
    Array.from(top__headers)?.forEach((top_header) => {
      if (top_header instanceof HTMLElement) {
        top_header.style.display = 'none';
      }
    });

    const footers = document.querySelectorAll('.site-footer-copyright-area');
    Array.from(footers)?.forEach((footer) => {
      if (footer instanceof HTMLElement) {
        footer.style.display = 'none';
      }
    });

    const contents = document.querySelectorAll('.site-content');
    Array.from(contents)?.forEach((content) => {
      if (content instanceof HTMLElement) {
        content.style.width = '100%';
      }
    });

    const questions = document.querySelectorAll(
      '.lp_custom_help_bubble_button_container'
    );
    Array.from(questions)?.forEach((question) => {
      if (question instanceof HTMLElement) {
        question.style.display = 'none';
      }
    });

    setEditColumns(columnData.map((item: any) => (item.isChecked = false)));

    columnData?.forEach((item: any) => {
      columns?.forEach((itemItem: any) => {
        if (item.value === itemItem.value) {
          item.isChecked = true;
        }
      });
    });

    setEditColumns(columnData);
    setUserEditColumns(objectToString(columns));
    //
    setSelectedItem(objectToString(columns)[0]);

    //
    setArranges(objectToString(columns));

    return () => {
      const top__headers = document.querySelectorAll('.top-headers-wrapper');
      Array.from(top__headers)?.forEach((top_header) => {
        if (top_header instanceof HTMLElement) {
          top_header.style.display = 'block';
        }
      });

      const footers = document.querySelectorAll('.site-footer-copyright-area');
      Array.from(footers)?.forEach((footer) => {
        if (footer instanceof HTMLElement) {
          footer.style.display = 'block';
        }
      });

      const contents = document.querySelectorAll('.site-content');
      Array.from(contents)?.forEach((content) => {
        if (content instanceof HTMLElement) {
          content.style.width = 'calc(100% - 40px)';
        }
      });

      const questions = document.querySelectorAll(
        '.lp_custom_help_bubble_button_container'
      );
      Array.from(questions)?.forEach((question) => {
        if (question instanceof HTMLElement) {
          question.style.display = 'flex';
        }
      });
    };
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, placeholder } = e.target;
    if (name === 'allSelect') {
      setUserEditColumns(myColumns);

      let tempColumns = editColumns.map((user: any) => {
        return { ...user, isChecked: checked };
      });
      setEditColumns(tempColumns);
    } else {
      if (checked) {
        setUserEditColumns(
          [...userEditColumns, placeholder].sort((a, b) => {
            return myColumns.indexOf(a) - myColumns.indexOf(b);
          })
        );

        setArranges([...arranges, placeholder]);

        setSelectedItem(placeholder);
      } else {
        setUserEditColumns(
          userEditColumns.filter((item: any) => item !== placeholder)
        );

        setArranges(arranges.filter((item) => item !== placeholder));
        setSelectedItem('');
      }
      let tempColumns = editColumns.map((column: any) =>
        column.name === name ? { ...column, isChecked: checked } : column
      );
      setEditColumns(tempColumns);
    }
    if (!checked && name === 'allSelect') {
      setUserEditColumns([]);
      setArranges([]);
    }
  };

  const moveItemDown = () => {
    if (arranges.indexOf(selectedItem) < arranges.length - 1) {
      const updatedArray = [...arranges];

      const item = updatedArray.splice(arranges.indexOf(selectedItem), 1)[0];
      updatedArray.splice(arranges.indexOf(selectedItem) + 1, 0, item);
      localStorage.removeItem('salesSize');
      setArranges(updatedArray);
    }
  };

  const moveItemUp = () => {
    if (arranges.indexOf(selectedItem) > 0) {
      const updatedArray = [...arranges];

      const item = updatedArray.splice(arranges.indexOf(selectedItem), 1)[0];
      updatedArray.splice(arranges.indexOf(selectedItem) - 1, 0, item);
      localStorage.removeItem('salesSize');
      setArranges(updatedArray);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        settingsRef.current &&
        !settingsRef.current.contains(event.target as Node)
      ) {
        handleChangeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [settingsRef, handleChangeModal]);

  return (
    <>
      <div className={styles.tableSettings__overlay}>
        <div ref={settingsRef} className={styles.modal}>
          <h1 className={styles.modal__title}>Columns</h1>
          <div className={styles.modal__wrapper}>
            <div className={styles.modal__viewColumns}>
              <h3 className={styles.columns__title}>Available Columns</h3>
              <div className={styles.modal__viewColumns_wrapper}>
                {editColumns?.map((column: IColumnData, index: number) => (
                  <div className={styles.viewColumnItem}>
                    <input
                      type="checkbox"
                      // className="myCheckbox"
                      className={styles.myCheckbox}
                      id={column.value}
                      name={column.name}
                      checked={column?.isChecked || false}
                      onChange={handleChange}
                      placeholder={column.value}
                    />
                    <label htmlFor={column.value}>
                      {column.name === 'Cogs' ? 'COGS' : column.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {arranges.length > 0 && (
              <div className={styles.arrange__block}>
                <h3 className={styles.columns__title}>Arrange</h3>
                <div className={styles.arrange__items}>
                  <div className={styles.arrange__wrapper}>
                    {arranges?.map((item, index) => (
                      <span
                        key={index}
                        className={
                          arranges.indexOf(selectedItem) === index
                            ? styles.activeSpan
                            : undefined
                        }
                        onClick={() => setSelectedItem(item)}
                      >
                        {item === 'cogs'
                          ? 'COGS'
                          : item === 'sku'
                          ? 'SKU'
                          : formatString(item)}
                      </span>
                    ))}
                  </div>
                  <div className={styles.arrange__directions}>
                    <span
                      style={{
                        opacity:
                          arranges.indexOf(selectedItem) === 0
                            ? '0.4'
                            : undefined,
                        cursor:
                          arranges.indexOf(selectedItem) === 0
                            ? 'not-allowed'
                            : undefined,
                      }}
                      onClick={moveItemUp}
                    >
                      <SVGArrow />
                    </span>
                    <span
                      style={{
                        opacity:
                          arranges.indexOf(selectedItem) === arranges.length - 1
                            ? '0.4'
                            : undefined,
                        cursor:
                          arranges.indexOf(selectedItem) === arranges.length - 1
                            ? 'not-allowed'
                            : undefined,
                      }}
                      onClick={moveItemDown}
                    >
                      <SVGArrow />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.tableSettingFooter}>
            <div className={styles.modal__btn__block}>
              {/* <div>
              <span>Save View</span>
              <span>My Saved Views</span>
            </div> */}
              <div>
                <ModalButton
                  cb={() => handleEditColumns(arranges)}
                  feature="primary"
                >
                  Save
                </ModalButton>
                <ModalButton cb={handleChangeModal} feature="light">
                  Cancel
                </ModalButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="site-footer-copyright-area"></footer>
    </>
  );
};

export default TableEditColumn;
