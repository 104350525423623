import styles from "./FooterComponent.module.scss";
import React from "react";
import {Dashicon} from "../../../../assets/icons";
import {useNavigate} from "react-router-dom";
import {setShowEditTemplateModal} from "../../../../redux/slices/NavigationSlice";
import {useDispatch, useSelector} from "react-redux";

export const FooterComponent = ({isDuplicateTemplate, id, listingId, handleSaveTemplate, forTabletSize, enabledButtons,handleCreateTemplate} :any)=>{
	let allowRequest = true;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const showEditTemplateModal = useSelector((state: any) => state.navigation.showEditTemplateModal);
	return (
	 <div className={`${styles.footerButtonsMobile} ${forTabletSize ? styles.footerButtonsTablet : ''}`}>
		 <div className={styles.editSubmitButtons}>
			 {
				 (showEditTemplateModal && !isDuplicateTemplate && id && !listingId) ? (
				  <button
				   className={styles.saveAndClose}
				   disabled={!enabledButtons}
				   style={!enabledButtons ? {opacity: 0.7} : {opacity: 1}}
				   onClick={() => {
					   if (enabledButtons && allowRequest) {
						   allowRequest = false;
						   handleSaveTemplate()
						   .then(() => {
							   dispatch(setShowEditTemplateModal(false))
						   })
						   .catch((error: any) => {
							   console.log("Failed to save template:", error.message);
							   // Handle failure case here
						   })
						   .finally(() => {
							   allowRequest = true;
						   });
					   }
				   }}
				  >
					  <Dashicon/>
					  Update & Exit
				  </button>
				 ) : isDuplicateTemplate ? (
				  <button
				   className={styles.saveAndClose}
				   disabled={!enabledButtons}
				   style={!enabledButtons ? {opacity: 0.7} : {opacity: 1}}
				   onClick={() => {
					   if (enabledButtons && allowRequest) {
						   allowRequest = false;
						   handleCreateTemplate()
						   .then(() => {
							   dispatch(setShowEditTemplateModal(false));
						   })
						   .catch((error: any) => {
							   console.log("Failed to duplicate template:", error.message);
							   // Handle failure case here
						   })
						   .finally(() => {
							   allowRequest = true;
						   });
					   }
				   }}
				  >
					  <Dashicon/>
					  Save & Exit
				  </button>
				  ) : (
				  <button
				   className={styles.saveAndClose}
				   disabled={!enabledButtons}
				   style={!enabledButtons ? {opacity: 0.7} : {opacity: 1}}
				   onClick={() => {
					   if (enabledButtons && allowRequest) {
						   allowRequest = false;
						   handleCreateTemplate()
						   .then(() => {
							   navigate('/templates')
						   })
						   .catch((error: any) => {
							   console.log("Failed to create template:", error.message);
							   // Handle failure case here
						   })
						   .finally(() => {
							   allowRequest = true;
						   });
					   }
				   }}
				  >
					  <Dashicon/>
					  Save & Exit
				  </button>
				 )
			 }
		 </div>
	 </div>
	)
}