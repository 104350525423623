import React, { useEffect, useRef } from 'react';
import styles from './PaginateMessageModal.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    setMenuClickedWhileSaveActive,
    setSecureModalWhenChangePage,
    setShowGridMessage,
    setShowPerPageMessage, setShowTabsMessage
} from "../../../redux/slices/NavigationSlice";
import { CloseWizard } from "../../../assets/icons";
const PaginateMessageModal = ({handleChangeBulkEditCancel, handleSaveBulkEdit} : any) => {
    const confirmModalRef = useRef<HTMLDivElement>(null);
    const showGridMessageActive = useSelector(
        (state: any) => state.navigation.showGridMessage
    );
    const PerPageMessageActive = useSelector(
        (state: any) => state.navigation.showPerPageMessage
    );
    const TabMessageActive = useSelector(
        (state: any) => state.navigation.showTabsMessage
    );
    const pageMessage =  useSelector(
        (state: any) => state.navigation.menuClickedWhenSaveActive
    );
    const dispatch = useDispatch();
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                confirmModalRef.current &&
                !confirmModalRef.current.contains(event.target as Node)
            ) {
                dispatch(setMenuClickedWhileSaveActive(false));
                dispatch(setShowTabsMessage(false));
                dispatch(setSecureModalWhenChangePage(false));
                dispatch(setShowGridMessage(false));
                dispatch(setShowPerPageMessage(false));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [confirmModalRef]);

    const handleCancel = () => {
        handleChangeBulkEditCancel();
        dispatch(setMenuClickedWhileSaveActive(false));
        dispatch(setShowTabsMessage(false));
        dispatch(setSecureModalWhenChangePage(false));
        dispatch(setShowGridMessage(false));
        dispatch(setShowPerPageMessage(false));
    }
    const handleSave = () => {
        handleSaveBulkEdit();
        dispatch(setMenuClickedWhileSaveActive(false));
        dispatch(setShowTabsMessage(false));
        dispatch(setSecureModalWhenChangePage(false));
        dispatch(setShowGridMessage(false));
        dispatch(setShowPerPageMessage(false));
    }

    return (
        <div className={styles.confirmModalOverlay}>
            <div ref={confirmModalRef} className={styles.modal}>
                <div className={styles.close__block}>
                    <button
                        onClick={() => {
                            dispatch(setMenuClickedWhileSaveActive(false));
                            dispatch(setShowTabsMessage(false));
                            dispatch(setSecureModalWhenChangePage(false))
                            dispatch(setShowGridMessage(false))
                            dispatch(setShowPerPageMessage(false))
                        }}
                    >
                        <CloseWizard />
                    </button>
                </div>
                <div style={{maxWidth:'90%'}}>
                    <span>
                     Unsaved Edits Detected
                    </span>
                    {
                        showGridMessageActive ?
                            <p>You have made edits that have not yet been saved. If you navigate to another view at this point, your modifications will be lost.</p>
                                       : PerPageMessageActive ?
                                        <p>
                                        You have made edits that have not yet been saved. If you  change view at this point, your modifications will be lost.
                                        </p> : TabMessageActive ?
                                        <p>
                                        You have made edits that have not yet been saved. If you navigate to another tab at this point, your modifications will be lost.
                                        </p> : pageMessage ?
                                        <p>
                                            You have made edits that have not yet been saved. If you navigate to another page at this point, your modifications will be lost.
                                        </p>
                                         :
                                        <p>
                                        You have made edits that have not yet been saved. If you navigate to another page at this point, your modifications will be lost.
                                        </p>
                    }

                    <ul>
                        <div style={{display:'flex', alignItems:'center', gap:10}}>
                            <button className="myBtn saveBulkBtn" onClick={handleSave}>
                                Save
                            </button>
                            <button className="myBtn dangerCancelBtn" onClick={handleCancel}>
                                Cancel Edits
                            </button>
                        </div>
                    </ul>
                </div>

            </div>
        </div>
    );
};

export default PaginateMessageModal;
