import React, {useState} from 'react';
import styles from './CustomMarketPlaces.module.scss';


import {
	CirclePlusSvg,
	CircleQuestionSvg, CloseSvg, EditPencilSvg,
	PlatformIconShop,
} from '../../../../assets/icons';

import CustomMarketplaceItem from '../CustomMarketItem';
import Tippy from "@tippyjs/react";
import "../ExtensionContent/tippy.css"
import { v4 as uuidv4 } from 'uuid';
import MarketModalMobile from "../CustomMarketItem/MarketModalMobile";
import {useSelector} from "react-redux";
import ColorPicker from "../ColorPicker";
import {instanceAxios} from "../../../../services";
import {toast} from "react-toastify";
interface ICustomMarketplacesProps {
	customMarketplaces: any;
	setCustomMarketplaces:any;
	isMobile: boolean;
	configID:any;
}

const CustomMarketplaces = (props: ICustomMarketplacesProps) => {
	const { customMarketplaces, setCustomMarketplaces, isMobile, configID } = props;
	const randomUUID = uuidv4();
	const [customMarketplacesLocal, setCustomMarketPlacesLocal] = useState(customMarketplaces || [])
	const [addActive, setAddActive] = useState(false);
	const [allowAdd, setAllowAdd] = useState(true)
	const [openMarketModalMobile, setOpenMarketModalMobile] = useState(false)
	const hasBusiness = useSelector(
	 (state: any) => state.roles.hasBusiness
	);
	const [charCountNewCustom, setCharCountNewCustom] = useState(0);
	const [customMarketNameValueNew, setCustomMarketNameValueNew] = useState('');
	const [maxCustomMarkets, setMaxCustomMarkets] = useState<any>(hasBusiness ? 5 : 10)
	const [newObjectCustom, setNewObjectCustom] = useState({})
	const [colorPickerColorNew, setColorPickerColorNew] = useState('#000');
	const [showCustomObject, setShowCustomObject] = useState(false);
	const [preventDuplicateMarkets, setPreventDuplicateMarkets] = useState(false);
	const handleToggleAddNew = () => {
		setAllowAdd(false)
		if (customMarketplacesLocal.length < maxCustomMarkets ) {
			const id =  randomUUID;
			const code = Number((customMarketplacesLocal?.length || 0) + 1)
			const platformId = Number(2 + (customMarketplacesLocal?.length || 0))
			setShowCustomObject(true);
			const newObject = {
				id,
				code: [`891.${code}`],
				customColor: null,
				isChecked: false,
				platformId: `89${2 + customMarketplacesLocal?.length || 0 }`,
				platformName: '',
				isEdit:true,
				isNew:true
			};
			setNewObjectCustom(newObject)
		}
	};

	const handleEditChange = (index:any) => {
		setCustomMarketPlacesLocal((prevState:any) => {
			return prevState.map((item:any, i:any) => {
				if (i === index) {
					return { ...item, isEdit: true };
				} else {
					return { ...item, isEdit: false };
				}
			});
		});
	};
	const handleChangeCustomMarketValueNew = (value: string) => {
		if (value.length <= 11) {
			setCustomMarketNameValueNew(value);
			setCharCountNewCustom(value.length)
		}
	};
	const handleChangeColorPickerColorNew = (color: string) => {
		setColorPickerColorNew(color);
	};
	const handleSaveNewCustom = async() => {
		setPreventDuplicateMarkets(true)
		if(preventDuplicateMarkets) return;
		if(customMarketNameValueNew.length > 2){
			try {
				const dynamicPlatformName = `custom_marketplace_${customMarketplacesLocal?.length + 1}`;
				const dynamicPlatformColor = `custom_marketplace_${customMarketplacesLocal?.length + 1}_color`;
				const payload = {
					filter_by: { id: configID },
					data: {
						config: {
							[dynamicPlatformName]: customMarketNameValueNew,
							[dynamicPlatformColor]: colorPickerColorNew,
						},
					},
				};
				const response = await instanceAxios.put(
				 '/user-configs/website/update',
				 payload
				);
				setCustomMarketPlacesLocal((prevMarketplaces:any) => [
					...prevMarketplaces,
					{
						...prevMarketplaces[0],
						platformName: customMarketNameValueNew,
						platformColor: colorPickerColorNew ? colorPickerColorNew : '#000'
					}
				]);
				setShowCustomObject(false);
				setCustomMarketNameValueNew('');
				setColorPickerColorNew('#000');
				setAllowAdd(true);
			} catch (error) {
				toast.error(`Oops! We encountered an error while attempting to save your changes.  Please try again.`, {
					position: 'bottom-right', // You can customize the notification position
				});
				console.error(error);
			}
			finally {
				setPreventDuplicateMarkets(false);
			}
		}
	}
	const handleDeleteNewCustom = ()=>{
		setShowCustomObject(false);
		setCustomMarketNameValueNew('');
		setColorPickerColorNew('#000');
		setAllowAdd(true)
	}

	return (
	 <div className={styles.cstmMarketplacesWrapper} id="custom-marketplaces">
		 <div className={styles.customMarketplaces}>
			 <div className={styles.customMarketTItle}>
				 <div style={{fontSize: '20px', fontStyle: 'normal', fontWeight: 700}}>Custom Marketplaces</div>
				 <span>{customMarketplacesLocal ? customMarketplacesLocal?.length : 0 } of { maxCustomMarkets }</span>
				 {customMarketplacesLocal?.length === maxCustomMarkets &&
				  <Tippy
				   interactive={true}
				   zIndex={5}
				   arrow={false}
				   trigger="focus click"
				   content={
					   <div>Max limit of custom markets</div>
				   } >
					  <div className={styles.csvDisabled}>
						  <CircleQuestionSvg />
					  </div>
				  </Tippy>
				 }
			 </div>
			 <table>
				 <thead>
				 <tr>
					 <th>Image</th>
					 <th>Marketplace Name</th>
					 <th>Color</th>
					 <th>Actions</th>
				 </tr>
				 </thead>
				 <tbody>
				 {customMarketplacesLocal?.map((item: any, index:number) => (
				  <CustomMarketplaceItem
				   key={`item?.id${Math.random()}`}
				   id={item?.id}
				   configID={configID}
				   indexCustom={index}
				   setAllowAdd={setAllowAdd}
				   customMarketplacesLocal={customMarketplacesLocal}
				   setCustomMarketPlacesLocal={setCustomMarketPlacesLocal}
				   platformColor={item?.platformColor}
				   platformName={item?.platformName}
				   platformIcon={item?.platformIcon}
				   platformIndex={item?.platformIndex}
				   edit={item?.isEdit}
				   isMobile={isMobile}
				   setOpenMarketModalMobile={setOpenMarketModalMobile}
				   openMarketModalMobile={openMarketModalMobile}
				   handleEditChangeParent={() => handleEditChange(index)}


				  />
				 ))}
				 {
					 newObjectCustom && showCustomObject && !isMobile ? (
					  <tr>
						  <td className={styles.platformIconTd}>
							  <div>
								  <PlatformIconShop width={40} height={40} fill={colorPickerColorNew} />
							  </div>
						  </td>
						  <td className={styles.platformNameTd}>
							  <div className={styles.platformInputWrapper}>
								  <input
								   type="text"
								   className="myInput"
								   value={customMarketNameValueNew}
								   onChange={(e) => handleChangeCustomMarketValueNew(e.target.value)}
								  />
								  <span>{customMarketNameValueNew?.length ? customMarketNameValueNew?.length : 0}/11</span>
							  </div>
						  </td>
						  <td className={styles.platformColorTd}>
							  <ColorPicker
							   defaultColor={colorPickerColorNew}
							   handleChangeColorPickerColor={handleChangeColorPickerColorNew}
							  />
						  </td>
						  <td className={styles.actionsTd}>
							  <div>
								  <button onClick={handleSaveNewCustom} className="myBtn saveBulkBtn">
									  Save
								  </button>
								  <button onClick={handleDeleteNewCustom} className={`cancelBtn2 ${styles.cancelBtn2}`} >
									  <span>Delete</span>
								  </button>
							  </div>
						  </td>
					  </tr>
					 ) : newObjectCustom && showCustomObject && isMobile ? (
					  <MarketModalMobile
					   addNewCustom={true}
					   handleDeleteNewCustom={handleDeleteNewCustom}
					   setShowCustomObject={setShowCustomObject}
					   setCustomMarketNameValueNew={setCustomMarketNameValueNew}
					   setColorPickerColorNew={setColorPickerColorNew}
					   setAllowAdd={setAllowAdd}
					   handleSaveNewCustom={handleSaveNewCustom}
					   colorPickerColorNew={colorPickerColorNew}
					   setCharCountNewCustom={setCharCountNewCustom}
					   charCountNewCustom={charCountNewCustom}
					   customMarketNameValueNew={customMarketNameValueNew}
					   setOpenMarketModalMobile={setOpenMarketModalMobile}
					  />
					 ) : (<></>)
				 }
				 </tbody>
			 </table>
		 </div>

		 {
		  customMarketplacesLocal?.length < maxCustomMarkets && allowAdd &&
		  <div className={`${styles.addNewBtn} myBtn bulkBtn`} onClick={handleToggleAddNew}>
			  <CirclePlusSvg />
			  <span>Add New</span>
		  </div>
		 }

	 </div>
	);
};

export default CustomMarketplaces;