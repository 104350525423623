import { createSlice } from '@reduxjs/toolkit';

interface NavigationState {
  active: boolean;
  editModalShow: boolean;
  zIndex: number;
  isBulkEdit: boolean;
  saved: boolean;
  zIndexGenerator: number[];
  iframeLink: string;
  showIframe: boolean;
  iframeLoad: boolean;
  showModalQuestion: boolean;
  candidatIframeLink: string;
  candidatIframeLinkPure: string;
  showNotificationModal: boolean;
  showRedirectModal: boolean;
  isDeleted: number;
  isMobile: boolean | null;
  iframeTitle: string;
  cancelToken: boolean;
  dataForPrint: any;
  showSecureModalWhenChangePage:boolean;
  showGridMessage:boolean;
  showPerPageMessage:boolean;
  showTabsMessage:boolean;
  menuClickedWhenSaveActive: boolean,
  activeSaveRetain:boolean,
  editModalActive:boolean,
  editModalId:any,
  editTableData:any,
  editIndex: number,
  expressLister:boolean,
  editPageName: any,
  expressListerNewActive: boolean;
  expressListerNewClicked: boolean;
  isUploaderOpen: boolean;
  removeActionFromURL: boolean;
  listingViewId: any;
  listingViewModalShow:boolean;
  allowAllTemplatesAccess: any;
  showEditTemplateModal: boolean;
  editTemplateId: any;
  listingTemplateId: any;
  isDuplicateTemplate: boolean;
  expressListingId: any;
}

let initialActive : any;

try {
  const storedNavigationState = localStorage.getItem('leftMenuExpanded');
  if (storedNavigationState) {
    initialActive = JSON.parse(storedNavigationState)
  }
  else {
    initialActive = false;
  }
}
catch (e) {
  initialActive = false;
}

  const initialState: NavigationState = {
  active: initialActive,
  editModalShow: false,
  zIndexGenerator: [],
  isBulkEdit: false,
  saved: false,
  zIndex: 9991,
    showSecureModalWhenChangePage:false,
  iframeLink: '',
  showIframe: false,
  iframeLoad: true,
  showModalQuestion: false,
  candidatIframeLink: '',
  candidatIframeLinkPure: '',
  showNotificationModal: false,
  showRedirectModal: false,
  isDeleted: 0,
  isMobile: null,
  iframeTitle: '',
  cancelToken: false,
  dataForPrint: [],
  showGridMessage: false,
  showPerPageMessage: false,
  showTabsMessage: false,
  menuClickedWhenSaveActive: false,
  activeSaveRetain: false,
  editModalActive:false,
  editModalId: null,
  editTableData: [],
  editIndex: 0,
  expressLister: false,
  editPageName: null,
  expressListerNewActive: true,
  expressListerNewClicked: false,
  isUploaderOpen: false,
  removeActionFromURL:false,
  listingViewId: null,
  listingViewModalShow:false,
  allowAllTemplatesAccess:null,
  showEditTemplateModal: false,
  editTemplateId: null,
  listingTemplateId: null,
  isDuplicateTemplate: false,
  expressListingId:null
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleActive(state) {
      state.active = !state.active;
      localStorage.setItem('navigationState', JSON.stringify(state.active));
    },

    setActiveLeftMenu(state, action) {
      state.active = action.payload

    },
    setActiveSaveRetain(state, action) {
      state.activeSaveRetain = action.payload;
    },
    setToggleEditModal(state) {
      state.editModalShow = !state.editModalShow;
    },
    setEditModalId(state, action) {
      state.editModalId = action.payload;
    },
    setEditTableData(state, action) {
      state.editTableData = action.payload;
    },
    setEditIndex(state, action) {
      state.editIndex = action.payload;
    },
    setEditModalActive(state, action) {
      state.editModalActive = action.payload;
    },
    setMenuClickedWhileSaveActive(state, action) {
      state.menuClickedWhenSaveActive = action.payload;
    },
    setDeletedNotification(state, action) {
      state.isDeleted = action.payload;
    },
    handleChangeBulkEdit(state) {
      state.isBulkEdit = !state.isBulkEdit;
    },
    setBulkEdit(state, action) {
      state.isBulkEdit = action.payload;
    },
    setExpressLister(state, action) {
      state.expressLister = action.payload;
    },
    setShowGridMessage(state, action) {
      state.showGridMessage = action.payload;
    },
    setSaveToggle(state) {
      state.saved = !state.saved;
    },
    setIframeLink(state, action) {
      state.iframeLink = action.payload;
    },
    setShowIframe(state) {
      state.showIframe = true;
    },
    setSecureModalWhenChangePage(state, action) {
     state.showSecureModalWhenChangePage = action.payload;
    },
    setShowTabsMessage(state, action) {
      state.showTabsMessage = action.payload;
    },
    setShowQuestionModal(state, action) {
      state.showModalQuestion = true;
      state.candidatIframeLink = action.payload;
    },
    setEditPageName(state, action) {
      state.editPageName = action.payload;
    },
    setRemoveQuestionModal(state) {
      state.showModalQuestion = false;
    },
    setRemoveShowIframe(state) {
      state.showIframe = false;
    },
    iframeLoaderChanger(state, action) {
      state.iframeLoad = action.payload;
    },
    setReplaceCandidat(state) {
      state.iframeLink = state.candidatIframeLinkPure;
    },
    setResetCandidatLink(state) {
      state.candidatIframeLink = '';
    },
    setResetPureCandidantLink(state) {
      state.candidatIframeLinkPure = '';
    },
    setCandidantLinkPure(state, action) {
      state.candidatIframeLinkPure = action.payload;
    },
    setActivateNotificationModal(state, action) {
      state.showNotificationModal = action.payload;
    },
    setCloseNotificationModal(state, action) {
      state.showNotificationModal = action.payload;
    },
    setShowRedirectModal(state, action) {
      state.showRedirectModal = action.payload;
    },
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    },
    setIframeName(state, action) {
      state.iframeTitle = action.payload;
    },
    setCancelToken(state, action) {
      state.cancelToken = action.payload;
    },
    setDataForPrint(state, action) {
      state.dataForPrint = action.payload;
    },
    setHandleStopPrint(state) {
      state.cancelToken = true;
      state.dataForPrint = [];
    },
    setShowPerPageMessage(state, action) {
      state.showPerPageMessage = action.payload;
    },
    setExpressListerNewActive(state, action) {
      state.expressListerNewActive = action.payload;
    },
    setExpressListerNewClicked(state, action) {
      state.expressListerNewClicked = action.payload;
    },
    setIsUploaderOpenStatus(state, action) {
      state.isUploaderOpen = action.payload;
    },
    setRemoveActionFromURL(state, action) {
      state.removeActionFromURL = action.payload;
    },
    setAllowAllTemplatesAccess(state, action) {
      state.allowAllTemplatesAccess = action.payload;
    },
    setShowEditTemplateModal(state, action) {
      state.showEditTemplateModal = action.payload;
    },
    setEditTemplateId(state, action) {
      state.editTemplateId = action.payload;
    },
    setListingTemplateId(state, action) {
      state.listingTemplateId = action.payload;
    },
    setIsDuplicateTemplate(state, action) {
      state.isDuplicateTemplate = action.payload;
    },
    setListingViewId(state, action) {
      state.listingViewId = action.payload;
    },
    setShowListingViewModal(state, action) {
      state.listingViewModalShow = action.payload;
    },
    setExpressListingId(state, action) {
      state.expressListingId = action.payload;
    },

  },
});

export const {
  toggleActive,
  setActiveLeftMenu,
  setToggleEditModal,
  setEditModalActive,
  setEditModalId,
  handleChangeBulkEdit,
  setBulkEdit,
  setSaveToggle,
  setIframeLink,
  setShowIframe,
  setRemoveShowIframe,
  iframeLoaderChanger,
  setShowQuestionModal,
  setRemoveQuestionModal,
  setResetCandidatLink,
  setReplaceCandidat,
  setCandidantLinkPure,
  setResetPureCandidantLink,
  setActivateNotificationModal,
  setCloseNotificationModal,
  setDeletedNotification,
  setShowRedirectModal,
  setIsMobile,
  setIframeName,
  setCancelToken,
  setDataForPrint,
  setHandleStopPrint,
  setSecureModalWhenChangePage,
  setShowGridMessage,
  setShowPerPageMessage,
  setShowTabsMessage,
  setMenuClickedWhileSaveActive,
  setActiveSaveRetain,
  setEditTableData,
  setEditIndex,
  setExpressLister,
  setEditPageName,
  setExpressListerNewActive,
  setExpressListerNewClicked,
  setIsUploaderOpenStatus,
  setRemoveActionFromURL,
  setListingViewId,
  setShowListingViewModal,
  setAllowAllTemplatesAccess,
  setShowEditTemplateModal,
  setEditTemplateId,
  setListingTemplateId,
  setIsDuplicateTemplate,
  setExpressListingId
} = navigationSlice.actions;
export default navigationSlice.reducer;
