import React, { useEffect, useRef, useState } from 'react';

import styles from './TableSettings.module.scss';

import {
  CircleQuestionGraySVG,
  SVGArrow,
} from '../../../../../../assets/icons';
import ModalButton from '../../../../../../components/UI/ModalButton';

import { objectToString } from '../../../../../../utils/objectToString';
import { ICatalogColumn, IMetadata } from '../../../../../../interfaces';
import { useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';

interface ITableSettingsProps {
  handleToggleModalShow: () => void;
  columns: ICatalogColumn[];
  handleEditColumns: (updatedColumns: string[]) => void;
  is_role_allowed: boolean;
  hasPro: boolean;
  hasSimple: boolean;
  hasBusiness: boolean;
  hasBetaTeamRole:boolean;
}

interface IColumnData {
  name: string;
  value: string;
  isChecked: boolean;
}

interface Sections {
  [key: string]: { value: string; allowed: boolean }[];
}

const columnData: IColumnData[] = [
  { name: 'Image', value: 'image', isChecked: true },
  { name: 'Title', value: 'title', isChecked: true },
  { name: 'SKU', value: 'SKU', isChecked: true },
  { name: 'Price', value: 'price', isChecked: true },
  { name: 'Platforms', value: 'platforms', isChecked: true },
  { name: 'Quantity', value: 'quantity', isChecked: true },
  { name: 'Sales', value: 'sales', isChecked: false },
  { name: 'Updated', value: 'updated', isChecked: true },
  { name: 'Created', value: 'created', isChecked: true },
  { name: 'Notes', value: 'notes', isChecked: true },
  { name: 'Keywords', value: 'keywords', isChecked: false },
  { name: 'Drafts', value: 'drafts', isChecked: false },
  { name: 'Issues', value: 'issues', isChecked: false },
  // { name: 'Description', value: 'description', isChecked: false },
  { name: 'Sold Price', value: 'sold price', isChecked: false },
  { name: 'Shipping Cost', value: 'shipping cost', isChecked: false },
  { name: 'Profit', value: 'profit', isChecked: false },
  { name: 'COGS', value: 'cogs', isChecked: false },
  { name: 'Date Sold', value: 'date sold', isChecked: false },
  { name: 'Fees', value: 'fees', isChecked: false },
  { name: 'UPC', value: 'upc', isChecked: false },
  { name: 'Brand', value: 'brand', isChecked: false },
  { name: 'Color', value: 'color', isChecked: false },
  { name: 'Size', value: 'size', isChecked: false },
  { name: 'Material', value: 'material', isChecked: false },
  { name: 'Condition', value: 'condition', isChecked: false },
  { name: 'Actions', value: 'actions', isChecked: true },
  { name: 'Shipping Weight lb/kg', value: 'shipping weight lb/kg', isChecked: true },
  { name: 'Shipping Weight oz/g', value: 'shipping weight oz/g', isChecked: true },
  { name: 'Shipping Package Length', value: 'shipping package length', isChecked: true },
  { name: 'Shipping Package Width', value: 'shipping package width', isChecked: true },
  { name: 'Shipping Package Height', value: 'shipping package height', isChecked: true },
  { name: 'Zip', value: 'zip', isChecked: true },
];

const TableSettings = (props: ITableSettingsProps) => {
  const {
    handleToggleModalShow,
    columns,
    handleEditColumns,
    is_role_allowed,
    hasPro,
    hasSimple,
    hasBusiness,
    hasBetaTeamRole
  } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const myColumns: string[] = [
    'image',
    'title',
    'SKU',
    'price',
    'platforms',
    'drafts',
    'quantity',
    'sales',
    'updated',
    'created',
    'notes',
    'issues',
    'keywords',
    'description',
    'sold price',
    'shipping cost',
    'date sold',
    'profit',
    'cogs',
    'fees',
    'upc',
    'brand',
    'color',
    'size',
    'material',
    'condition',
    'actions',
    'assignee',
    'shipping_weight_lb/kg',
    'shipping weight oz/g ',
   'shipping package length',
   'shipping package width',
   'shipping package height',
   'zip'
  ];

  const [editColumns, setEditColumns] = useState<IColumnData[]>([]);
  const [userEditColumns, setUserEditColumns] = useState<string[]>([]);

  const [arranges, setArranges] = useState<string[]>([]);

  const [selectedItem, setSelectedItem] = useState<string>('');

  const settingsRef = useRef<HTMLDivElement>(null);
  const getTab = queryParams.get('tab');

  useEffect(() => {
    let updatedColumnData;
    // if (is_role_allowed || !hasPro) {
    const newColumn: IColumnData = {
      name: 'Assignee',
      value: 'assignee',
      isChecked: false,
    };

    updatedColumnData = [...columnData, newColumn];

    const mapper: any = updatedColumnData;

    const initialColumns = mapper?.map((item: any) => ({
      ...item,
      isChecked: false,
    }));

    let updatedColumns = initialColumns?.map((item: any) => {
      const foundColumn = columns?.find(
        (column) => column.value === item.value
      );
      if (foundColumn) {
        return { ...item, isChecked: true };
      }
      return item;
    });

    if (getTab === 'draft') {
      updatedColumns = updatedColumns.filter(
        (item: any) => item.name !== 'Drafts'
      );
    }
    if (getTab === 'issues') {
      updatedColumns = updatedColumns.filter(
        (item: any) => item.name !== 'Issues'
      );
    }
    const columnsToRemove = [
      'Date Sold',
      'COGS',
      'Sold Price',
      'Fees',
      'Shipping Cost',
      'Profit',
    ];

    if (getTab === 'sold') {
      updatedColumns = updatedColumns.filter(
        (item: any) => !columnsToRemove.includes(item.name)
      );
    }
    const columnsToRemove2 = ['Image', 'Title', 'Actions'];

    updatedColumns = updatedColumns.filter(
      (item: any) => !columnsToRemove2.includes(item.name)
    );

    setEditColumns(updatedColumns);
    setArranges(objectToString(columns));

    setUserEditColumns(objectToString(columns));
    setSelectedItem(objectToString(columns)[0]);
  }, []);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, placeholder } = e.target;
    if (
      name === 'Assignee' &&
      (hasPro || hasBusiness || hasSimple) &&
      !is_role_allowed
    )
      return;
    if (
      (name === 'Drafts' || name === 'Condition') &&
      (hasBusiness || hasSimple) &&
      !is_role_allowed
    )
      return;
    if (name === 'allSelect') {
      setUserEditColumns(myColumns);

      let tempColumns = editColumns?.map((user) => {
        return { ...user, isChecked: checked };
      });
      setEditColumns(tempColumns);
    } else {
      if (checked) {
        setUserEditColumns(
          [...userEditColumns, placeholder].sort((a, b) => {
            return myColumns.indexOf(a) - myColumns.indexOf(b);
          })
        );

        setArranges([...arranges, placeholder]);

        setSelectedItem(placeholder);
      } else {
        setUserEditColumns(
          userEditColumns.filter((item) => item !== placeholder)
        );
        setArranges(arranges.filter((item) => item !== placeholder));

        setSelectedItem('');
      }
      let tempColumns = editColumns?.map((column) =>
        column.name === name ? { ...column, isChecked: checked } : column
      );
      setEditColumns(tempColumns);
    }
    if (!checked && name === 'allSelect') {
      setUserEditColumns([]);
      setArranges([]);
    }
  };

  const moveItemDown = () => {
    if (arranges.indexOf(selectedItem) < arranges.length - 1) {
      const updatedArray = [...arranges];

      const item = updatedArray.splice(arranges.indexOf(selectedItem), 1)[0];
      updatedArray.splice(arranges.indexOf(selectedItem) + 1, 0, item);

      setArranges(updatedArray);
    }
  };

  const sections = {
    'Item Specifics': [
      'Image',
      'Keywords',
      'UPC',
      'Size',
      'Title',
      'Brand',
      'Material',
      'Quantity',
      'Sales',
      'Color',
      'Condition',
      'Shipping Weight lb/kg',
     'Shipping Weight oz/g',
     'Shipping Package Width',
     'Shipping Package Height',
      'Shipping Package Length',
     'Zip',
    ],
    'Pricing and Costs': [
      'Price',
      'Sold Price',
      'COGS',
      'Shipping Cost',
      'Profit',
      'Fees',
    ],
    Management: [
      'Updated',
      'Actions',
      'Platforms',
      'Created',
      'Drafts',
      'Date Sold',
      'Assignee',
      'SKU',
      'Notes',
      'Issues',
    ],
  };

  const moveItemUp = () => {
    if (arranges.indexOf(selectedItem) > 0) {
      const updatedArray = [...arranges];

      const item = updatedArray.splice(arranges.indexOf(selectedItem), 1)[0];
      updatedArray.splice(arranges.indexOf(selectedItem) - 1, 0, item);

      setArranges(updatedArray);
    }
  };


  const sectionsCheker: Sections = {
    'Item Specifics': [
      {
        value: 'Image',
        allowed: true,
      },
      {
        value: 'Keywords',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'UPC',
        allowed: hasSimple || hasBusiness ? false : true,
      },
      {
        value: 'Size',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'Title',
        allowed: true,
      },
      {
        value: 'Brand',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'Material',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'Quantity',
        allowed: hasSimple  ? false : true,
      },
      {
        value: 'Sales',
        allowed:  !hasPro && !is_role_allowed ?  false : true,
      },
      {
        value: 'Color',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'Condition',
        allowed: hasSimple || hasBusiness ? false : true,
      },
      {
        value: 'Shipping Weight lb/kg',
        allowed: hasPro || (hasPro && is_role_allowed)
      },
      {
        value: 'Shipping Weight oz/g',
        allowed: hasPro || (hasPro && is_role_allowed)
      },
      {
        value: 'Shipping Package Length',
        allowed: hasPro || (hasPro && is_role_allowed)
      },
      {
        value: 'Shipping Package Width',
        allowed: hasPro || (hasPro && is_role_allowed)
      },
      {
        value: 'Shipping Package Height',
        allowed: hasPro || (hasPro && is_role_allowed)
      },
      {
        value: 'Zip',
        allowed: hasPro || (hasPro && is_role_allowed)
      },
    ],
    'Pricing and Costs': [
      {
        value: 'Price',
        allowed: true,
      },
      {
        value: 'Sold Price',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'COGS',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'Shipping Cost',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'Profit',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'Fees',
        allowed: hasSimple ? false : true,
      },
    ],
    Management: [
      {
        value: 'Updated',
        allowed: true,
      },
      {
        value: 'Actions',
        allowed: true,
      },
      {
        value: 'Platforms',
        allowed: true,
      },
      {
        value: 'Created',
        allowed: true,
      },
      {
        value: 'Drafts',
        allowed: hasSimple || hasBusiness ? false : true,
      },
      {
        value: 'Date Sold',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'Assignee',
        allowed: hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? false : true,
      },
      {
        value: 'SKU',
        allowed: hasSimple ? false : true,
      },
      {
        value: 'Notes',
        allowed: true,
      },
      {
        value: 'Issues',
        allowed: true,
      },
    ],
  };



  function isValueAllowed(value: string): boolean {
    for (const sectionKey in sections) {

      const section = sectionsCheker[sectionKey];
      const item = section.find((item) => item.value === value);
      if (item) {
        return item.allowed;
      }
    }
    return false;
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        settingsRef.current &&
        !settingsRef.current.contains(event.target as Node)
      ) {
        handleToggleModalShow();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [settingsRef, handleToggleModalShow]);

  return (
    <div className={styles.tableSettings__overlay}>
      <div ref={settingsRef} className={styles.modal}>
        <h1 className={styles.modal__title}>Columns</h1>
        <div className={styles.modal__wrapper}>
          <div className={styles.modal__viewColumns}>
            <div className={styles.modal__viewColumns_wrapper}>
              {Object.entries(sections).map(([sectionName, sectionColumns]) => (
                <div key={sectionName}>
                  <h3 className={styles.columns__title}>{sectionName}</h3>
                  <div style={{ display: 'grid', gap: '7px' }}>
                    {editColumns
                      .filter((column) => sectionColumns.includes(column.name))
                      .map((column) => (
                        <div
                          className={styles.viewColumnItem}
                          key={column.value}
                        >
                          {isValueAllowed(column.name) ? (
                            <>
                              {column.value === 'sales' && (!hasPro && !is_role_allowed) ?
                              null :  <>
                                 <input
                                  type="checkbox"
                                  className={styles.myCheckbox}
                                  id={column.value}
                                  name={column.name}
                                  checked={
                                    column?.isChecked ? column?.isChecked : false
                                  }
                                  onChange={handleChange}
                                  placeholder={column.value}
                                  disabled={
                                   sectionName === 'Sub columns' &&
                                   column.name === 'Image'
                                  }
                                 />

                                 <label htmlFor={column.value}>
                                   {column.name}
                                 </label>
                               </>
                              }
                            </>
                          ) : (
                           column.value === 'sales' && (!hasPro && !is_role_allowed) ? null : <>
                             <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5,
                                pointerEvents: 'initial',
                              }}
                             >
                               <input
                                type="checkbox"
                                className={styles.myCheckbox}
                                id={column.value}
                                name={column.name}
                                checked={true}
                                onChange={handleChange}
                                placeholder={column.value}
                                disabled={true}
                               />
                               <Tippy
                                interactive={true}
                                zIndex={5}
                                arrow={false}
                                trigger="focus click"
                                content={
                                  <div>
                                    {column.name === 'Assignee' ||
                                    column.name === 'Drafts' ||
                                    column.name === 'UPC' ||
                                    column.name === 'Quantity' ||
                                    column.name === 'Condition' ? (
                                     <p>
                                       {column.name === 'Assignee'
                                        ? ` Product ${column.value} is available
                                          only in the  Pro Plus plans.`
                                        : ` Product ${column.value} is available
                                          only in the Pro and Pro Plus plans.`}
                                       <br/>
                                       <a
                                        target={'_blank'}
                                        href={
                                          'https://listperfectly.com/pricing'
                                        }
                                       >
                                         {' '}
                                         Upgrade Now{' '}
                                       </a>
                                     </p>
                                    ) : (
                                     <p>
                                       {
                                        ( column.value === 'shipping weight lb/kg' || column.value === 'shipping weight oz/g' || column.value === 'shipping package length' || column.value === 'shipping package width' || column.value === 'shipping package height' || column.value === 'zip') ?'Available on the Pro and Pro Plus plans.' :
                                         `Product ${column.value} is available
                                       only in the Pro, Business and Pro Plus
                                       plans.`
                                       }
                                       <br/>
                                       <a
                                        target={'_blank'}
                                        href={
                                          'https://listperfectly.com/pricing'
                                        }
                                       >
                                         {' '}
                                         Upgrade Now{' '}
                                       </a>
                                     </p>
                                    )}
                                  </div>
                                }
                               >
                                 <label
                                  htmlFor={column.value}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 5,
                                    opacity: '0.7',
                                  }}
                                 >
                                   {column.name}

                                   <CircleQuestionGraySVG
                                    style={{
                                      cursor: 'pointer',
                                      width: 25,
                                      height: 25,
                                    }}
                                   />
                                 </label>
                               </Tippy>
                             </div>
                           </>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {arranges.length > 0 && (
           <div className={styles.arrange__block}>
             <h3 className={styles.columns__title}>Arrange</h3>
             <div className={styles.arrange__items}>
               <div className={styles.arrange__wrapper}>
                 {arranges?.map((item, index) => (
                  <span
                   style={{
                     textTransform:
                      item === 'upc' ? 'uppercase' : 'capitalize',
                   }}
                   key={index}
                   className={
                     arranges.indexOf(selectedItem) === index
                      ? styles.activeSpan
                      : undefined
                   }
                   onClick={() => setSelectedItem(item)}
                  >
                      {item}
                    </span>
                 ))}
               </div>
               <div className={styles.arrange__directions}>
                  <span
                   style={{
                     opacity:
                      arranges.indexOf(selectedItem) === 0
                       ? '0.4'
                       : undefined,
                     cursor:
                      arranges.indexOf(selectedItem) === 0
                       ? 'not-allowed'
                       : undefined,
                   }}
                   onClick={moveItemUp}
                  >
                    <SVGArrow/>
                  </span>
                 <span
                  style={{
                    opacity:
                     arranges.indexOf(selectedItem) === arranges.length - 1
                      ? '0.4'
                      : undefined,
                    cursor:
                     arranges.indexOf(selectedItem) === arranges.length - 1
                      ? 'not-allowed'
                      : undefined,
                  }}
                  onClick={moveItemDown}
                 >
                    <SVGArrow/>
                  </span>
               </div>
             </div>
           </div>
          )}
        </div>
        <div className={styles.tableSettingFooter}>
          <div className={styles.modal__btn__block}>
            <div>
              <ModalButton
               cb={() => handleEditColumns(arranges)}
               feature="primary"
              >
                Save
              </ModalButton>
              <ModalButton cb={handleToggleModalShow} feature="light">
                Cancel
              </ModalButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableSettings;
