import React, {useState} from 'react' ;
import styles from './EditFavoriteLinksModal.module.scss' ;
import {
	AddListingQuickLink,
	AddTemplateQuickLink, CommunityQuickLink2,
	ContactUsQuickLink,
	CurrentIssuesQuickLink,
	ExpressListerQuickLink, ExtensionQuickLink,
	FaqQuickLink,
	HeaderStarIcon,
	HeaderStartIconNotActive,
	HelpCenterQuickLink,
	LatestVersionQuickLink,
	ListingPartyEventsQuickLink,
	ListingPartyQuickLink, LogOutQuickLink,
	ModalCloseSvg, MyAccountQuickLink,
	OldCatalogQuickLink, ReferralQuickLink,
	SalesAnalyticsQuickLink,
	ViewListingQuickLink,
	ViewTemplatesQuickLink, WebsiteQuickLink
} from "../../../../assets/icons";
import toSnakeToTitle from "../../../../utils/toSnakeToTitle";
import {instanceAxios} from "../../../../services";

interface IEditFavoriteLiksModalProps {
	quickLinks:any;
	setQuickLinks:(quickLinks:any) => void;
	handleToggleEditFavoriteModal:() => void;
	configID:string;
	hasPro:boolean;
	isRoleAllowed:boolean;
}

const links = [
	{
		id:1,
		value:'express_lister',
		title:'Express Lister',
		href:'/listings/express',
		svg:ExpressListerQuickLink,

	},
	{
		id:2,
		value:'add_listing',
		title:'Add Listing',
		href:'',
		svg:AddListingQuickLink
	},
	{
		id:3,
		value:'view_listings',
		title:'View Listings',
		href:'/listings',
		svg:ViewListingQuickLink,
	},
	{
		id:4,
		value:'old_catalog',
		title:'Old Catalog',
		href:'https://app.listperfectly.com/listed-items',
		svg:OldCatalogQuickLink
	},
	{
		id:5,
		value:'add_template',
		title:'Add Template',
		href:'',
		svg:AddTemplateQuickLink
	},
	{
		id:6,
		value:'view_templates',
		title:'View Templates',
		href:'',
		svg:ViewTemplatesQuickLink
	},
	{
		id:7,
		value:'sales__analytics',
		title:'Sales & Analytics',
		href:'/sales',
		svg:SalesAnalyticsQuickLink
	},
	{
		id:8,
		value:'contact_us',
		title:'Contact Us',
		href:'',
		svg:ContactUsQuickLink
	},
	{
		id:9,
		value:'help_center',
		title:'Help Center',
		href:'https://help.listperfectly.com/en',
		svg:HelpCenterQuickLink
	},
	{
		id:10,
		value:'latest_version',
		title:'Latest Version',
		href:'https://help.listperfectly.com/en/articles/8913965-install-list-perfectly',
		svg:LatestVersionQuickLink
	},
	{
		id:11,
		value:'current_issues',
		title:'Current Issues',
		href:'https://help.listperfectly.com/en/articles/9039723-current-technical-issue',
		svg:CurrentIssuesQuickLink
	},
	{
		id:12,
		value:'faq',
		title:'FAQ',
		href:'https://listperfectly.com/faq',
		svg:FaqQuickLink
	},
	{
		id:13,
		value:'listing_party',
		title:'Listing Party',
		href:'https://listingparty.com',
		svg:ListingPartyQuickLink
	},
	{
		id:14,
		value:'events',
		title:'Events',
		href:'https://listingparty.com/events',
		svg:ListingPartyEventsQuickLink
	},
	{
		id:15,
		value:'referral',
		title:'Referral',
		href:'https://app.listperfectly.com/referral-area/?tab=resources',
		svg:ReferralQuickLink
	},
	{
		id:16,
		value:'community',
		title:'Community',
		href:'https://listperfectly.com/community',
		svg:CommunityQuickLink2
	},
	{
		id:17,
		value:'my_account',
		title:'My Account',
		href:'https://app.listperfectly.com/account/?action=subscriptions',
		svg:MyAccountQuickLink
	},
	{
		id:177,
		value:'help_requests',
		title:'Help Requests',
		href:'https://app.listperfectly.com/my-requests',
		svg:HelpCenterQuickLink
	},
	{
		id:18,
		value:'log_out',
		title:'Log Out',
		href:'https://app.listperfectly.com/wp-login.php?action=logout&redirect_to=%2Flogin&_wpnonce=09317016fc',
		svg:LogOutQuickLink
	},
	{
		id:19,
		value:'extension',
		title:'Extension',
		href:'/settings',
		svg:ExtensionQuickLink
	},
	{
		id:20,
		value:'website',
		title:'Website',
		href:'/settings',
		svg:WebsiteQuickLink
	},
]

const EditFavoriteLinksModal = (props:IEditFavoriteLiksModalProps) => {
	const {quickLinks,setQuickLinks,handleToggleEditFavoriteModal,configID,hasPro,isRoleAllowed} = props ;

	const [editQuickLinks,setEditQuickLinks] = useState(quickLinks)


	const handleChangeQuickLinks = (linkObj:any) => {
		const quickLink = {
			id: Date.now(),
			title: linkObj.title,
			href: linkObj.href,
			svg: linkObj.svg,
			value:linkObj.value
		}

		if(checkLinkInQuickLinks(linkObj.value)) {
			const filteredQuickLinks = editQuickLinks.filter((link:any) => link.value !== linkObj.value)
			return   setEditQuickLinks(filteredQuickLinks)
		}

		setEditQuickLinks((prevQuickLinks:any) => {
			const isDuplicate = prevQuickLinks.some((item: any) => item.value === quickLink.value);

			if (!isDuplicate) {
				return [...prevQuickLinks, quickLink];
			} else {
				return prevQuickLinks;
			}

		});



	}

	const sendQuickLinks = async () => {
		try {
			const payload:any = {
				filter_by:{
					id:configID
				},
				data:{
					config:{
						quick_links:[]
					}
				}
			}
			editQuickLinks.forEach((link:any) => {
				payload.data.config.quick_links.push(toSnakeToTitle(link.title,'to_snake'))
			})

			const response = await instanceAxios.put('user-configs/website/update', payload)

			if(response.status === 200) {
				setQuickLinks(editQuickLinks)
				handleToggleEditFavoriteModal()
			}

		}catch(error) {
			console.error(error)
		}
	}


	const checkLinkInQuickLinks =(link:string) => {
		let includedLink = false ;


		editQuickLinks?.forEach((quickLink:any) => {
			if(quickLink.value === link) {
				includedLink = true
			}
		})
		return includedLink
	}
	return (
	 <div className={styles.editFavoriteLinksModal}>
		 <div className={styles.headerBlock}>
			 <div className={styles.header}>
				 <div>Edit Favorites</div>
				 <ModalCloseSvg
				  onClick={handleToggleEditFavoriteModal}
				 />
			 </div>
			 <p>Select the menu items that you would like to pin to the Quick Links section for easier access.</p>
		 </div>
		 <div className={styles.linksWrapper}>
			 {links.map((link:any) => {
				 if(link.value === 'express_lister' && !hasPro && !isRoleAllowed) return
				 return (
				  <div
				   key={link.id}
				   className={`${styles.linkItem} ${checkLinkInQuickLinks(link.value) && styles.active}`}
				   onClick={() => {
					   handleChangeQuickLinks({
						   title:link.title,
						   href:link.href,
						   svg:link.svg,
						   value:link.value
					   })
				   }}
				  >
					  <div>{link.title}</div>
					  <div
					   className={styles.headerStartIcon}>
						  {checkLinkInQuickLinks(link.value) ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
					  </div>
				  </div>
				 )
			 })}
		 </div>

		 <div className={styles.linkBtns}>
			 <button
			  className={styles.save}
			  onClick={sendQuickLinks}
			 >Save Changes</button>
			 <button
			  className={styles.cancel}
			  onClick={handleToggleEditFavoriteModal}
			 >Cancel</button>
		 </div>
	 </div>
	)
}

export default EditFavoriteLinksModal