import styles from './ShortDescriptionContainer.module.scss';
import Tippy from "@tippyjs/react";
import {PopoverIcon, SuggestDesc} from "../../../../assets/icons";
import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import {TextareaWithCount} from "../../TextAreaCount";
import {InputCount} from "../../InputCount";
import {TextAreaTagify} from "../../TextAreaTagify";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import {MoonLoader} from "react-spinners";
import SuggestDescModal from "../../../UI/SuggestDescModal";


const ShortDescriptionContainer = ({hasBusiness, hasSimple, formInfo, unescapedRowMetadata, handleStandartInputChange, handleTagifiesChange }:any) => {
	const { bdecode } = useBasisFuncs();
	return (
	 <div className={styles.shortDescriptionContainer}>
		 <div className={styles.brandInfoForm}>
			 <div className={styles.leftSide}>
				 <div className={styles.headerBrandInfo}>
					 <div className={styles.title}>
						 Short Description
						 <Tippy
						  interactive={true}
						  zIndex={9991}
						  trigger="click"
						  arrow={false}
						  content={
							  <div className={`popover ${styles.popoverContent}`}>
								  <p className={styles.heading}>
									  Description Character Count Limits:
								  </p>
								  <ul>
									  <li>Etsy: 10000</li>
									  <li>eBay: 5000</li>
									  <li>Poshmark: 1500</li>
									  <li>Mercari: 1000</li>

									  <div className={styles.line}></div>
									  <p>
										  ★ Crossposts to supported 'description' fields.
									  </p>
									  <p>
										  ♦ Pro{' '}
										  <a
										   href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
										   target="_blank"
										  >
											  Description Builder
										  </a>{' '}
										  section
									  </p>
								  </ul>
							  </div>
						  }
						 >
							 <PopoverIcon
							  className={styles.popoverIcon}
							  color={'#fff'}
							 ></PopoverIcon>
						 </Tippy>
					 </div>
				 </div>
				 <TextareaWithCount
				  placeholder=""
				  name="input_505"
				  value={
					  formInfo.newDescription.changed
					   ? bdecode(formInfo.newDescription.text)
					   : unescapedRowMetadata[505]
						? bdecode(unescapedRowMetadata[505])
						: ''
				  }
				  handleChange={handleStandartInputChange}
				  showCount={true}
				  limit={5000}
				  height={322}
				 />
			 </div>
			 {
			  !hasSimple && <div className={styles.rightSide}>
				  <div>
					  <div className={styles.title}>
						  Brand / Maker / Label / Artist
						  <Tippy
						   interactive={true}
						   zIndex={9991}
						   trigger="click"
						   arrow={false}
						   content={
							   <div className={`popover ${styles.popoverContent}`}>
								   <ul>
									   <li>
										   Add one primary brand, maker, label, artist.
									   </li>

									   <div className={styles.line}></div>
									   <p>★ Crossposts to supported 'brand' fields.</p>
								   </ul>
							   </div>
						   }
						  >
							  <PopoverIcon
							   className={styles.popoverIcon}
							   color={'#fff'}
							  ></PopoverIcon>
						  </Tippy>
					  </div>
					  <InputCount
					   placeholder=""
					   name="input_206"
					   value={
						   formInfo.newBrand.changed
							? bdecode(formInfo.newBrand.text)
							: unescapedRowMetadata[206]
							 ? bdecode(unescapedRowMetadata[206])
							 : ''
					   }
					   handleChange={handleStandartInputChange}
					   limit={250}
					   height={40}
					   hideCount={true}
					  />
				  </div>
				  <div>
					  <div className={styles.title}>
						  Color Shade
						  <Tippy
						   interactive={true}
						   zIndex={9991}
						   trigger="click"
						   arrow={false}
						   content={
							   <div className={`popover ${styles.popoverContent}`}>
								   <ul>
									   <li>
										   Add up to two colors separated by a comma.
										   <ul>
											   <li>(ex: Blue, White)</li>
										   </ul>
									   </li>

									   <div className={styles.line}></div>
									   <p>★ Crossposts to supported 'brand' fields.</p>
								   </ul>
							   </div>
						   }
						  >
							  <PopoverIcon
							   className={styles.popoverIcon}
							   color={'#fff'}
							  ></PopoverIcon>
						  </Tippy>
					  </div>
					  <TextAreaTagify
					   placeholder=""
					   isTagify={true}
					   name="input_209"
					   value={
						   formInfo.newColor.changed
							? bdecode(formInfo.newColor.text)
							: unescapedRowMetadata[209]
							 ? bdecode(unescapedRowMetadata[209])
							 : ''
					   }
					   handleTagifyChange={handleTagifiesChange}
					   limit={250}
					   height={40}
					  />
				  </div>
				  <div>
					  <div className={styles.title}>
						  Material / Ingredient Composition
						  <Tippy
						   interactive={true}
						   zIndex={9991}
						   trigger="click"
						   arrow={false}
						   content={
							   <div className={`popover ${styles.popoverContent}`}>
								   <ul>
									   <li>Add one primary material</li>
									   <div className={styles.line}></div>
									   <p>★ Crossposts to supported 'material' fields.</p>
								   </ul>
							   </div>
						   }
						  >
							  <PopoverIcon
							   className={styles.popoverIcon}
							   color={'#fff'}
							  ></PopoverIcon>
						  </Tippy>
					  </div>
					  <InputCount
					   placeholder=""
					   name="input_82"
					   value={
						   formInfo.newMaterial.changed
							? bdecode(formInfo.newMaterial.text)
							: unescapedRowMetadata[82]
							 ? bdecode(unescapedRowMetadata[82])
							 : ''
					   }
					   handleChange={handleStandartInputChange}
					   limit={250}
					   height={40}
					   hideCount={true}
					  />
				  </div>
				  {
				   !hasBusiness && <div>
					   <div className={styles.title}>
						   Style / Features
						   <Tippy
							interactive={true}
							zIndex={9991}
							trigger="click"
							arrow={false}
							content={
								<div className={`popover ${styles.popoverContent}`}>
									<ul>
										<li>
											Add primary style details.
											<ul>
												<li>
													ex. When your buyer would use your item, or
													the overall style like Western if clothing or
													Soft Grip if a tool.
												</li>
											</ul>
										</li>

										<div className={styles.line}></div>
										<p>
											♦ Added to Pro{' '}
											<a
											 href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
											 target="_blank"
											>
												{' '}
												Description Builder.
											</a>
										</p>
									</ul>
								</div>
							}
						   >
							   <PopoverIcon
								className={styles.popoverIcon}
								color={'#fff'}
							   ></PopoverIcon>
						   </Tippy>
					   </div>
					   <InputCount
						placeholder=""
						name="input_205"
						value={
							formInfo.newStyle.changed
							 ? bdecode(formInfo.newStyle.text)
							 : unescapedRowMetadata[205]
							  ? bdecode(unescapedRowMetadata[205])
							  : ''
						}
						handleChange={handleStandartInputChange}
						limit={250}
						height={40}
						hideCount={true}
					   />
				   </div>
				  }
				  <div>
					  <div className={styles.title}>
						  Size or Fit
						  <Tippy
						   interactive={true}
						   zIndex={9991}
						   trigger="click"
						   arrow={false}
						   content={
							   <div className={`popover ${styles.popoverContent}`}>
								   <ul>
									   <li>
										   Add primary size.
										   <ul>
											   <li>
												   ex. Standard letter sizing recommending (S, M,
												   L, etc)
											   </li>
										   </ul>
									   </li>

									   <div className={styles.line}></div>
									   <p>★ Crossposts to supported 'size' fields.</p>
									   <p>
										   ♦ Added to Pro{' '}
										   <a
											href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
											target="_blank"
										   >
											   {' '}
											   Description Builder.
										   </a>
									   </p>
								   </ul>
							   </div>
						   }
						  >
							  <PopoverIcon
							   className={styles.popoverIcon}
							   color={'#fff'}
							  ></PopoverIcon>
						  </Tippy>
					  </div>
					  <InputCount
					   placeholder=""
					   name="input_213"
					   value={
						   formInfo.newSize.changed
							? bdecode(formInfo.newSize.text)
							: unescapedRowMetadata[213]
							 ? bdecode(unescapedRowMetadata[213])
							 : ''
					   }
					   handleChange={handleStandartInputChange}
					   limit={250}
					   height={40}
					   hideCount={true}
					  />
				  </div>
			  </div>
			 }

		 </div>
	 </div>
	);
};

export default ShortDescriptionContainer;